var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"3","align-self":"center"}},[_c('div',{staticClass:"d-flex"},[(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('AgregaResiduo',{attrs:{"tipoResiduo":_vm.tipoResiduo,"anioBusqueda":_vm.anioConsulta,"origen":_vm.tipoResiduo},on:{"reload":_vm.getData}}):_vm._e(),_c('div',{staticClass:"pl-2"},[_c('Justififcacion',{attrs:{"titulo":"Justificar Ausencia de Datos"}})],1)],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.residuos,"calculate-widths":"","item-key":"id","loading":_vm.loading,"no-data-text":"No hay Residuos para este periodo","footer-props":{
                          showFirstLastPage: true,
                          firstIcon:'mdi-page-first',
                          lastIcon: 'mdi-page-last',
                          prevIcon: 'mdi-chevron-left',
                          nextIcon: 'mdi-chevron-right',
                          itemsPerPageText: 'Registros por página',
                          itemsPerPageAllText: 'Todos',
                          showCurrentPage: true,
                          showFirstLastPage: true
                      }},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
                      var item = ref.item;
return [(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('ModificarResiduo',{attrs:{"id":item.id},on:{"reload":_vm.getData}}):_vm._e(),(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('AgregarEvidencia',{attrs:{"origen":_vm.origenE,"idActividad":item.id,"tooltipText":"Agregar Evidencias","titulo":_vm.titulo},on:{"reload":_vm.getData}}):_vm._e(),_c('eliminar-residuo',{attrs:{"evidencia":item,"origen":_vm.tipoResiduo},on:{"reload":_vm.getData}})]}},{key:"item.evidencia",fn:function(ref){
                      var item = ref.item;
return [_c('ListadosDocs',{attrs:{"idActividad":item.id,"origen":"residuosEspeciales","titulo":_vm.titulo}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }