<template>
  <v-dialog
    v-model="dialog"
    scrollable 
    persistent 
    max-width="500px"
    transition="dialog-transition">
    <template v-slot:activator="{ on:dialog }">        
        <v-tooltip top>
            <template v-slot:activator="{ on:tooltip }">                    
                <v-btn large dark icon v-on="{ ...tooltip, ...dialog }"><v-icon color="primary">mdi-square-edit-outline</v-icon></v-btn>
            </template>
            Modificar Medidor
        </v-tooltip>  
    </template>
    <v-card>
      <v-card-title class="pa-0">
        <ModalTitle titulo="Modificar Medidor" @cancelar="cancelar"/>
      </v-card-title>           
      <v-divider></v-divider>  
      <v-card-text>
        <v-text-field         
          v-if="!indicCombustible && !indicadorResiduos"                                
          label="Medidor"
          :disabled="disabled"
          v-model="medidor.medidor"
          hint="Código del medidor"
          :error-messages="medidorErrors"
          @change="$v.medidor.medidor.$touch()"
          @blur="$v.medidor.medidor.$touch()"
          required               
        ></v-text-field>
        <v-select
          v-if="indicCombustible"
          :items="combustible"
          v-model="medidor.medidor"
          label="Combustible"
          :error-messages="medidorErrors"             
          @change="getDataModMedidor(); $v.medidor.medidor.$touch()"
          @blur="$v.medidor.medidor.$touch()"
          required
        ></v-select> 
        <v-text-field
          v-if="!medidor.consumoHumano && !indicCombustible && !indicadorResiduos"
          label="Nis"
          v-model="medidor.nis"
          hint="Número de nis"
          :error-messages="niseErrors"
          @change="$v.medidor.nis.$touch()"
          @blur="$v.medidor.nis.$touch()"
          required
        ></v-text-field> 
        <v-select
          :items="unidadMedida"
          item-text="nombre"
          item-value="valor"
          v-model="medidor.unidadConsumo"
          label="Unidad de Consumo"
          :error-messages="unidadErrors"             
          @change="$v.medidor.unidadConsumo.$touch()"
          @blur="$v.medidor.unidadConsumo.$touch()"
          required
        ></v-select>
        <v-select
          v-if="!indicadorResiduos"
          :items="unidadesCosto"
          item-text="nombre"
          item-value="valor"
          v-model="medidor.unidadCosto"
          label="Unidad de Costo"
          :error-messages="unidadCostoErrors"             
          @change="$v.medidor.unidadCosto.$touch()"
          @blur="$v.medidor.unidadCosto.$touch()"
          required
        ></v-select>
        <v-select
          v-if="!medidor.consumoHumano && !indicCombustible && !indicadorResiduos"
          :items="proveedores"
          v-model="medidor.proveedor"
          label="Proveedor del servicio"
          :error-messages="proveedorErrors"             
          @change="$v.medidor.proveedor.$touch(), verificarOtroProv()"
          @blur="$v.medidor.proveedor.$touch()"
          required
        ></v-select>
        <v-text-field v-if="otroProveedor"
            label="Nombre Proveedor"
            v-model="medidor.generico"
            :error-messages="genericoErrors"
            @change="$v.medidor.generico.$touch()"
            @blur="$v.medidor.generico.$touch()"
            required
        ></v-text-field>
        <v-text-field v-if="indicadorElectricidad"
          label="Localización"
          v-model="medidor.localizacion"
          :error-messages="localizacionErrors"
          @change="$v.medidor.localizacion.$touch()"
          @blur="$v.medidor.localizacion.$touch()"
          required
        ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancelar" text color="error">Cancelar</v-btn>
        <v-btn @click="modificarMedidor" text color="#0B3F67">Modificar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from '@/axios.js'
import { required, requiredIf, integer } from "vuelidate/lib/validators";
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
  mounted() {
    this.getDataModMedidor();
  },
  props: [
    'medidor',
    'unidadMedida'
  ],
  components: {
    ModalTitle
  },
  computed: {
    indicadorAgua() {
      return this.$store.getters.indicadorActual == this.$store.getters.constantes.indicadorAgua ? true : false;
    },
    indicadorActual() {
      return parseInt(this.$store.getters.indicadorActual);
    },
    indicCombustible() {
      return this.indicadorActual === this.constantes.indicadorCombustibles ? true : false;
    },
    indicadorResiduos(){
      return this.indicadorActual ===this.constantes.indicadorResiduos ? true : false;
    },
    indicadorElectricidad() {
      return this.indicadorActual ===this.constantes.indicadorEnergia ? true : false;
    },
    usuario() {
      return this.$store.getters.usuario;
    },
    constantes() {
      return this.$store.getters.constantes;
    },
    medidorErrors () {
      const errors = []
      if (!this.$v.medidor.medidor.$dirty) return errors            
      !this.$v.medidor.medidor.required && errors.push('El código de medidor es requerido.')                   
      return errors
    },
    niseErrors () {
      const errors = []
      if (!this.$v.medidor.nis.$dirty) return errors                         
      !this.$v.medidor.nis.required && errors.push('El nis del medidor es requerido.')                   
      return errors
    },
    localizacionErrors() {
      const errors = []
      if (!this.$v.medidor.localizacion.$dirty) return errors                         
      !this.$v.medidor.localizacion.required && errors.push('La localización del medidor es requerida.')        
      !this.$v.medidor.localizacion.integer && errors.push('Solo debe digitar números.')            
      return errors
    },
    proveedorErrors () {
      const errors = []
      if (!this.$v.medidor.proveedor.$dirty) return errors                 
      !this.$v.medidor.proveedor.required && errors.push('Por favor agregue un proveedor.')                 
      return errors
    },
    unidadErrors () {
      const errors = []
      if (!this.$v.medidor.unidadConsumo.$dirty) return errors
      !this.$v.medidor.unidadConsumo.required && errors.push('La unidad de consumo es requerida')
      return errors
    },
    unidadCostoErrors () {
      const errors = []
      if (!this.$v.medidor.unidadCosto.$dirty) return errors
      !this.$v.medidor.unidadCosto.required && errors.push('La unidad de costo es requerida')
      return errors
    },
    genericoErrors () {
        const errors = []
        if (!this.$v.medidor.generico.$dirty) return errors
        !this.$v.medidor.generico.required && errors.push('Ingrese el nombre del proveedor.')
        return errors
    }
  },
  data: () => ({
    med: null,
    dialog: false,
    otroProveedor: false,
    proveedores: [],
    combustible: [],
    unidadesCosto: [
      {nombre: 'COLONES', valor: 1}, 
      {nombre: 'DOLARES', valor: 2}
    ],
    unidadCostoPapel:[
      {nombre: 'COLONES', valor: 1}
    ],
    disabled: false,
  }),
  validations: {
    medidor: {
      medidor: {
        required: requiredIf(function(){
          return !this.indicadorResiduos
        })
      },
      unidadConsumo: {
        required
      },
      unidadCosto: {
        required
      },
      nis: { required: requiredIf(function(){
          return !this.indicCombustible && !this.indicadorResiduos
        }) 
      },
      proveedor: { required: requiredIf(function(){
          return !this.indicCombustible && !this.indicadorResiduos
        }) 
      },
      localizacion: { required: requiredIf(function() {
            return this.indicadorElectricidad
        }),
        integer
      },
      generico: {
          required: requiredIf(function(){
            return (this.medidor.proveedor === 'Otro') && (!this.indicCombustible && !this.indicadorResiduos)
          })
      }
    },
  },
  methods: {
    modificarMedidor() {
      this.$v.$touch()
      if(!this.$v.$invalid) {
        this.loadingModal(true);        
        delete this.medidor.historial
        axios.post('/instalaciones/modificarMedidor', this.medidor, {params: {
          indicador: this.indicadorActual,
          idInstalacion: this.$store.getters.sucursal.id,
          idUsuario: this.usuario.id
        }}).then(response => {
          this.$store.dispatch('noti',{mensaje: 'Medidor modificado correctamente', color:'success'});
          this.$emit('reload')
          this.loadingModal(false);
          this.cancelar()
        }).catch(error => {
          if (error.request && error.request.status === 401) {
            this.$store.commit('showSessionDialog', true);
          } else {
            console.error(error);
            this.$store.dispatch('noti',{mensaje: 'Ocurrió un error no se modificó el medidor', color:'error'});
            this.loadingModal(false);
            this.cancelar()
          }
        })
      }
    },
    getDataModMedidor() {
      this.loadingModal(true);
      let idInstalacion = this.$store.getters.sucursal.id;
      axios.get(`/instalaciones/getDataModMedidor/${idInstalacion}/${this.medidor.id}`
      ).then(response => {
          this.proveedores = response.data.proveedores;
          this.combustible = response.data.combustible;
          this.verificarOtroProv();
          this.loadingModal(false);
      }).catch(error => {
        if (error.request && error.request.status === 401) {
          this.$store.commit('showSessionDialog', true);
        } else {
          console.log(error);  
          this.loadingModal(false);    
        }     
      })
    },
    verificarOtroProv() {
      this.otroProveedor = this.medidor.proveedor === 'Otro'
    },
    cancelar() {
      this.$v.medidor.$reset      
      this.dialog = !this.dialog;
    },
    loadingModal(modal) {                        
      this.$store.dispatch('loadingPage', modal);
    },
  }
}
</script>