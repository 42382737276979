import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: "green-loop-store",
  storage: window.localStorage,
  reducer: (state) => ({
    usuario: state.usuario,
    organizacion: state.organizacion,
    sucursal: state.sucursal,
    token: state.token,
    indicadorActual: state.indicadorActual,
    inscripcion: state.inscripcion,
    fechaInscripcion: state.fechaInscripcion,
    historialEstrella: state.historialEstrella,
    logo: state.logo,
    aniosConsulta: state.aniosConsulta, 
    categoriaName: state.categoriaName,
    parametroActual: state.parametroActual,
    navParams: state.navParams
  })
});

const store = new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],
  state: {
    token: null,
    logo: null,
    notiData: {
      mensaje: "",
      color: "",
      show: false
    },
    sucursal: {},
    usuario: {
      nombre: "",
      apellido: "",
      cedula: "",
      rol: "",            
    },
    loadingPage: false,
    modalPuntaje: false,
    dataPuntaje: {
      idPuntaje: 0,
      parametro: '',
      puntaje: 0,
      anio: 0,
      cantidadMinima: 0,
      indicador: {
        id: 0,
        nombre: ''
      },
      puntosSubParametro: 0,
      puntosTotales: 0, 
      tipo: {
        id: 0,
        descripcion: ''
      }
    },
    constantes: {
      indicadorEnergia: 1,
      indicadorAgua: 2,
      indicadorCombustibles: 3,
	    indicadorCumpliLegal: 4,
      indicadorAguasResiduales: 5,
      indicadorResiduos: 6,
      indicadorContamiAdmosf: 7,
      indicadorCompSostenibles: 8, 
      indicadorAdaptacion: 9,
      indicadorCompensacion:10,
      indicadorManejoSuelos:12,
      indicadorInsumosAgropecuarios:13,
      indicadorProyeccionEmpresarial:14,
      kwh: 1,
      litros: 2,
      galones: 3,
      metrosCubicos: 4,
      metrosCuadrados: 5,
      porInstalacion: 6,
      porOrganizacion: 7,
      aniosActuales: 8,
      porComite: 9,

      //residuos
      residuosEspeciales: 0,
      residuoosPeligrosos: 1,

      //tipoDoc
      EVIDENCIA: 0,
      PERMISO_VERTIDO: 1,
      REPORTE_OPERACIONAL: 2,
      REPORTE_DATOS: 3,
      COMPROBANTE_PAGO: 4,
      PERMISO_PROVEEDOR: 5,
      PERMISO_SANITARIO_PROV: 6,
      CONSTANCIA_PROVEEDOR: 7,
      CONSTANCIA_DESCARGA: 8,
      CONSTANCIA_TRAT_AGUA: 9,
      PROGRAMA_COMPRAS: 10,
      JUSTIF_FLOTA_VEHICULAR: 14,
      JUSTIF_CONSUMO_COMB: 15,
      JUSTIF_REDUCCION_COMB: 16,
      JUSTIF_CONSUMO_ELECT: 17,
      JUSTIF_REDUCCION_ELECT: 18,
      JUSTIF_CALIDAD_AGUA: 19,
      JUSTIF_CONSUMO_AGUA: 20,
      JUSTIF_REDUCCION_AGUA: 21,
      JUSTIF_REDUCCION_PAPEL: 22,
      JUSTIF_CONSUMO_PAPEL: 23,
      JUSTIF_REDUCCION_VALOR: 24,
      JUSTIF_REDUCCION_NO_VALOR: 25,
      JUSTIF_CONSUMO_VALOR: 26,
      JUSTIF_CONSUMO_NO_VALOR: 27,
      JUSTIF_SISTEMA_TRATAMIENTO: 28,

      //maxima cant de archivos
      CANTIDAD_MAXIMA_ARCHIVOS: 3,

      //planta tratamiento
      PLANTA_TRATAMIENTO: 1,
      TANQUE_SEPTICO: 2,
      ALCANTARILLADO_SANITARIO: 3,

      TIPO_ACTIVIDAD: {
        ACTIVIDAD_CASO_UNO: 0,
        ACTIVIDAD_CASO_DOS: 1,
        BUENAS_PRACTICAS: 2,
        GESTIONA_COMUNIDAD: 3,//Proyección socio-empresarial
        PARTICIPACION_COLABORADORES: 4,//Proyección socio-empresarial
        BIENESTAR_ANIMAL: 5,
		    PROGRAMA_REPRODUCTIVO: 6
      }
    },
    rolName: "",
    organizacion: {},
    adminRules: [
       {subject: 'dashboard', actions: 'read'},
       {subject: 'admin', actions: 'manage'},
       {subject: 'info', actions: 'read'},
       {subject: 'coordi', actions: 'update'},
    ],
    ceoRules: [
      {subject: 'ceo', actions: 'manage'},
      {subject: 'dashboard', actions: 'read'},
      {subject: 'info', actions: 'read'},
    ],
    gerenteRules: [
      {subject: 'dashboard', actions: 'read'},
      {subject: 'info', actions: 'read'},
      {subject: 'coordi', actions: 'update'},
    ],
    coordiRules: [
      {subject: 'dashboard', actions: 'read'},
      {subject: 'coordi', actions: 'manage'},
      {subject: 'info', actions: 'read'},
    ],
    comiteRules: [
      {subject: 'dashboard', actions: 'read'},
      {subject: 'info', actions: 'read'},
    ],
    indicadorActual: null,
    anioConsulta: new Date().getFullYear(),
    inscripcion: {},
    fechaInscripcion: {},
    historialEstrella: {},
    confirmaData: {
        mensaje: '',
        show: false,
        resolveData: null,
    },
    aniosConsulta: [],
    sessionDialog: false,
    categoriaName: "",
    parametroActual: 0,
    navParams: [],
    version: '2.6.8'
  },
  mutations: {
    modificarToken(state, token) {
      state.token = token;
    },
    showNoti(state, notiData) {
      state.notiData = notiData;
    },
    sucursal(state, sucursal) {
      state.sucursal = sucursal;
    },
    usuario(state, usuario) {
      state.usuario = usuario;
    },
    organizacion(state, organizacion) {
      state.organizacion = organizacion;
    },
    rolName(state, rolName) {
      state.rolName = rolName;
    },
    indicadorActual(state, indicadorActual) {
      state.indicadorActual = indicadorActual;
    },
    loadingScreen(state, dialog) {
      state.loadingPage = dialog;
    },
    modalPuntaje(state, dialog) {
      state.modalPuntaje = dialog;
    },
    dataPuntaje(state, dataPuntaje) {
      state.dataPuntaje = dataPuntaje;
    },
    anioConsulta(state, anio) {
      state.anioConsulta = anio;
    },
    inscripcion(state, inscripcion) {
      state.inscripcion = inscripcion;
    },
    fechaInscripcion(state, fechaInscripcion) {
      state.fechaInscripcion = fechaInscripcion;
    },
    historialEstrella(state, historialEstrella) {
      state.historialEstrella = historialEstrella;
    },
    showConfirm(state, confirmaData) {
      state.confirmaData = confirmaData;
    },
    resolveData(state, resolveData) {
      state.confirmaData.resolveData = state.confirmaData.resolveData(resolveData);
    },
    logo(state, logo) {
      state.logo = logo;
    },
    aniosConsulta(state, anios) {
      state.aniosConsulta = anios;
    },
    showSessionDialog(state, showSessionDialog) {
      state.sessionDialog = showSessionDialog
    },
    changeCategoria(state, categoria) {
      state.categoriaName = categoria
    },
    parametroActual(state, parametroActual) {
      state.parametroActual = parametroActual
    },
    navParams(state, navParams) {
      state.navParams = navParams;
    }
  },
  getters: {
    token: state => state.token,
    notiData: state => state.notiData,
    sucursal: state => state.sucursal,
    usuario: state => state.usuario,
    organizacion: state => state.organizacion,
    adminRules: state => state.adminRules,
    ceoRules: state => state.ceoRules,
    coordiRules: state => state.coordiRules,
    rolName: state => state.rolName,
    constantes: state => state.constantes,
    indicadorActual: state => state.indicadorActual,
    cargandoPagina: state => state.loadingPage,
    modalPuntaje: state => state.modalPuntaje,
    dataPuntaje: state => state.dataPuntaje,
    anioConsulta: state => state.anioConsulta,
    inscripcion: state => state.inscripcion,
    fechaInscripcion: state => state.fechaInscripcion,
    historialEstrella: state => state.historialEstrella,
    confirmaData: state => state.confirmaData,
    logo: state => state.logo,
    aniosConsulta: state => state.aniosConsulta,
    getSessionDIalog: state => state.sessionDialog,
    version: state => state.version,
    categoriaName: state => state.categoriaName,
    parametroActual: state => state.parametroActual,
    navParams: state => state.navParams,
    comiteRules: state => state.comiteRules,
  },
  actions: {
    noti(context, { mensaje, color }) {
      let icon = "";
      switch (color) {
        case "success":
          mensaje = mensaje + ' con éxito';
          icon = "mdi-check-bold";
          break;
        case "error":
          icon = "mdi-close-octagon";
          break;
      }
      let noti = {
        mensaje: mensaje + '.',
        color: color,
        show: true,
        icono: icon
      };
      context.commit("showNoti", noti);
    },
    loadingPage(context, dialog) {
      context.commit('loadingScreen', dialog);
    },
    confirma(context, {mensaje, show}) {
        if (show) {
            return new Promise((resolve, reject) => {
                context.state.confirmaData.show = show;
                let conf = {
                    mensaje: mensaje,
                    show: show,
                    resolveData: resolve,
                };
                context.commit('showConfirm', conf);
            })
        } else {
            context.commit('showConfirm', {mensaje: mensaje, show: false, resolveData: null});
        }
    }
  }
});
export default store;
