<template>    
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        max-width="400px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">        
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">                      
                    <v-btn class="white--text" color="#0B3F67" v-on="{ ...tooltip, ...dialog }">{{ tituloBtn }}</v-btn>
                </template>
                {{ tituloBtn }}
            </v-tooltip>  
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle :titulo="tituloBtn" @cancelar="cancelar"/>
            </v-card-title>          
            <v-divider></v-divider>
            <v-card-text >
                <v-text-field         
                    v-if="!indicCombustible && !indicadorResiduos"                                
                    label="Medidor"
                    :disabled="disabled"
                    v-model="medidor.medidor"
                    hint="Código del medidor"
                    :error-messages="medidorErrors"
                    @change="$v.medidor.medidor.$touch()"
                    @blur="$v.medidor.medidor.$touch()"
                    required               
                ></v-text-field>
                <v-select
                    v-if="indicCombustible && !indicadorResiduos"
                    :items="combustible"
                    v-model="medidor.medidor"
                    label="Combustible"
                    :error-messages="medidorErrors"             
                    @change="$v.medidor.medidor.$touch()"
                    @blur="$v.medidor.medidor.$touch()"
                    required
                ></v-select><v-text-field
                    v-if="indicCombustible && medidor.medidor === 'Otro'"
                    label="Nombre Combustible"
                    v-model="otroCombustible"
                    hint="Nombre del combustible a ingresar"
                    :error-messages="otroErrors"
                    @change="$v.otroCombustible.$touch()"
                    @blur="$v.otroCombustible.$touch()"
                    required
                ></v-text-field>  
                <v-text-field
                    v-if="!medidor.consumoHumano && !indicCombustible && !indicadorResiduos"
                    label="Nis"
                    v-model="medidor.nis"
                    hint="Número de nis"
                    :error-messages="niseErrors"
                    @change="$v.medidor.nis.$touch()"
                    @blur="$v.medidor.nis.$touch()"
                    required
                ></v-text-field>   
                <v-text-field v-if="indicadorElectricidad"
                    label="Localización"
                    v-model="medidor.localizacion"
                    :error-messages="localizacionErrors"
                    @change="$v.medidor.localizacion.$touch()"
                    @blur="$v.medidor.localizacion.$touch()"
                    required
                ></v-text-field>
                <v-select
                    v-if="!medidor.consumoHumano && !indicCombustible && !indicadorResiduos"
                    :items="proveedores"
                    v-model="medidor.proveedor"
                    label="Proveedor del servicio"
                    :error-messages="proveedorErrors"             
                    @change="$v.medidor.proveedor.$touch()"
                    @blur="$v.medidor.proveedor.$touch()"
                    required
                ></v-select>
                <v-text-field v-if="otroProveedor"
                    label="Nombre Proveedor"
                    v-model="medidor.generico"
                    :error-messages="genericoErrors"
                    @change="$v.medidor.generico.$touch()"
                    @blur="$v.medidor.generico.$touch()"
                    required
                ></v-text-field>
                <v-select
                    :items="unidadMedida"
                    item-text="nombre"
                    item-value="valor"
                    v-model="medidor.unidadConsumo"
                    label="Unidad de Consumo"
                    :error-messages="unidadErrors"             
                    @change="$v.medidor.unidadConsumo.$touch()"
                    @blur="$v.medidor.unidadConsumo.$touch()"
                    required
                ></v-select>
                <v-select
                    v-if="!indicadorResiduos"
                    :items="unidadesCosto"
                    item-text="nombre"
                    item-value="valor"
                    v-model="medidor.unidadCosto"
                    label="Unidad de Costo"
                    :error-messages="unidadCostoErrors"             
                    @change="$v.medidor.unidadCosto.$touch()"
                    @blur="$v.medidor.unidadCosto.$touch()"
                    required
                ></v-select>
                <v-select
                    v-if="indicadorResiduos"
                    :items="unidadCostoPapel"
                    item-text="nombre"
                    item-value="valor"
                    v-model="medidor.unidadCosto"
                    label="Unidad de Costo"
                ></v-select>
                <v-checkbox v-if="!consumoHum && indicadorAgua && !indicadorResiduos"                    
                    label="Consumo Humano"                     
                    v-model="medidor.consumoHumano"
                    color="#0B3F67"
                    @click.native="aguaConsumoHumano(medidor.consumoHumano)">                    
                </v-checkbox>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="agregarMedidor">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>    
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, requiredIf, integer } from "vuelidate/lib/validators";
import axios from "@/axios.js";

export default {
    props: [
        'indicador',
        'consumoHum',
        'unidadMedida'
    ],
    components: {
      ModalTitle
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },     
    mounted() {
        this.getDataAgregarMedidor();
    },
    computed: {
        indicadorAgua() {
            return this.$store.getters.indicadorActual == this.$store.getters.constantes.indicadorAgua ? true : false;
        },
        indicadorActual() {
            return parseInt(this.$store.getters.indicadorActual);
        },
        indicCombustible() {
            return this.indicadorActual === this.constantes.indicadorCombustibles ? true : false;
        },
        indicadorResiduos(){
            return this.indicadorActual ===this.constantes.indicadorResiduos ? true : false;
        },
        indicadorElectricidad() {
            return this.indicadorActual ===this.constantes.indicadorEnergia ? true : false;
        },
        usuario() {
            return this.$store.getters.usuario;
        },
        constantes() {
            return this.$store.getters.constantes;
        },
        otroProveedor() {
            return (this.medidor.proveedor === 'Otro') && (!this.indicCombustible && !this.indicadorResiduos)
        },
        tituloBtn() {
            switch(this.indicadorActual) {
                case this.constantes.indicadorCombustibles:
                    return "Nuevo Combustible";
                    break;
                case this.constantes.indicadorResiduos:
                    return "Agregar Registro"
                    break;
                default:
                    return "Agregar Medidor";
                    break;
                
            }
        },
        medidorErrors () {
            const errors = []
            if (!this.$v.medidor.medidor.$dirty) return errors            
            !this.$v.medidor.medidor.required && errors.push('El código de medidor es requerido.')                   
            return errors
        },
        niseErrors () {
            const errors = []
            if (!this.$v.medidor.nis.$dirty) return errors                         
            !this.$v.medidor.nis.required && errors.push('El nis del medidor es requerido.')                   
            return errors
        },
        localizacionErrors() {
            const errors = []
            if (!this.$v.medidor.localizacion.$dirty) return errors                         
            !this.$v.medidor.localizacion.required && errors.push('La localización del medidor es requerida.')        
            !this.$v.medidor.localizacion.integer && errors.push('Solo debe digitar números.')            
            return errors
        },
        proveedorErrors () {
            const errors = []
            if (!this.$v.medidor.proveedor.$dirty) return errors                 
            !this.$v.medidor.proveedor.required && errors.push('Por favor agregue un proveedor.')                 
            return errors
        },
        unidadErrors () {
            const errors = []
            if (!this.$v.medidor.unidadConsumo.$dirty) return errors
            !this.$v.medidor.unidadConsumo.required && errors.push('La unidad de consumo es requerida')
            return errors
        },
        unidadCostoErrors () {
            const errors = []
            if (!this.$v.medidor.unidadCosto.$dirty) return errors
            !this.$v.medidor.unidadCosto.required && errors.push('La unidad de costo es requerida')
            return errors
        },
        otroErrors () {
            const errors = []
            if (!this.$v.otroCombustible.$dirty) return errors
            !this.$v.otroCombustible.uniqueName&& errors.push('Tipo de combustible ya existe.')
            !this.$v.otroCombustible.required && errors.push('Ingrese el nombre del combustible.')
            return errors
        },
        genericoErrors () {
            const errors = []
            if (!this.$v.medidor.generico.$dirty) return errors
            !this.$v.medidor.generico.required && errors.push('Ingrese el nombre del proveedor.')
            return errors
        }
    },
    data: () => ({
        medidor: {
            medidor: '',            
            nis: '',
            proveedor: '',
            abonado: '',            
            unidadConsumo: '',
            unidadCosto: '',
            consumoHumano: '',
            localizacion: '',
            generico: ''
        },
        proveedores: [],
        unidadesCosto: [
            {nombre: 'COLONES', valor: 1}, 
            {nombre: 'DOLARES', valor: 2}
        ],
        unidadCostoPapel:[
            {nombre: 'COLONES', valor: 1}
        ],
        combustible: null,
        dialog: false,
        disabled: false,
        loading: false,
        otroCombustible: null
    }),
    validations: {
        medidor: {
            medidor: {
                required: requiredIf(function(){
                    return !this.indicadorResiduos
                })
            },
            unidadConsumo: {
                required
            },
            unidadCosto: {
                required
            },
            nis: { required: requiredIf(function(){
        	        return !this.indicCombustible && !this.indicadorResiduos
                }) 
            },
            proveedor: { required: requiredIf(function(){
        	        return !this.indicCombustible && !this.indicadorResiduos
                }) 
            },
            localizacion: { required: requiredIf(function() {
                    return this.indicadorElectricidad
                }),
                integer
            },
            generico: {
                required: requiredIf(function(){
        	        return (this.medidor.proveedor === 'Otro') && (!this.indicCombustible && !this.indicadorResiduos)
                })
            }
        },
        otroCombustible: {
            required: requiredIf(function(){
                return this.indicCombustible && this.medidor.medidor === 'Otro'
            }),
            uniqueName(combustible) {
                if (combustible === '') return true
                return new Promise((resolve, reject) => {
                    axios.get("/combustibles/verificaNombreCombUnico/" + combustible).then(response => {
                        resolve(response.data);
                    }).catch(error => {
                        if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                        } else {
                        reject(error);
                        }
                    })
                })
            }
        }
    },
    methods: {
        getDataAgregarMedidor() {
            this.loading = true;
            let idInstalacion = this.$store.getters.sucursal.id;
            axios.get("/instalaciones/getDataAgregarMedidor/" + idInstalacion
            ).then(response => {
                this.proveedores = response.data.proveedores;
                this.combustible = response.data.combustible;
                this.loading = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);  
                this.loading = false;  
              }            
            })
        },
        agregarMedidor() {
            this.medidor
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.medidor.medidor = (this.indicCombustible && this.medidor.medidor === 'Otro') ? this.otroCombustible : this.medidor.medidor;
                this.loading = true;
                axios.post("/instalaciones/agregarMedidor", this.medidor, {params: {
                    idInstalacion: this.$store.getters.sucursal.id,
                    indicador: this.indicador,
                    user: this.usuario.id
                }})
                .then(response => {
                    this.$store.dispatch('noti',{
                        mensaje: 'Medidor agregado', 
                        color:'success'
                    });
                    this.getDataAgregarMedidor();
                    this.cancelar();
                    this.$emit('agregarMedidor');
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log("Error al agreagar el medidor " + error);
                    this.cancelar();
                    this.$store.dispatch('noti',{mensaje: 'Ocurrió un error no se agregó el medidor', color:'error'});
                  }
                })
            }
        },
        aguaConsumoHumano(consumoHumano) {
            if(consumoHumano) {
                this.medidor.medidor = "Consumo Humano";
                this.disabled = true;
                this.medidor.proveedor = 'Proveedor Generico';
                this.medidor.nis = 'No Aplica';
            } else {
                this.disabled = false;
                this.medidor.medidor = '';
                this.medidor.proveedor = '';
                this.medidor.nis = '';
            }
        },
        cancelar() {
            this.medidor = {};
            this.$v.medidor.$reset();  
            this.disabled = false;    
            this.dialog = false;
            this.loading = false;
        }
    }
}
</script>