<template>
  <v-dialog
    v-model="dialog"
    scrollable fullscreen 
    persistent :overlay="false"
    transition="dialog-transition">
    <v-card>
      <v-card-title class="pa-0">
        <ModalTitle titulo="Modificar Puntaje año" @cancelar="cancelar"/>
      </v-card-title>
      <v-card-text>
        <v-row justify-content="center" class="fill-height">
          <v-col cols="12" md="5">
            <v-col cols="12">
              <TituloPrincipal titulo="Datos del Puntaje"/>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-textarea
                rows="2"
                outlined
                readonly
                label="Parámetro"
                :value="puntaje.parametro">
              </v-textarea>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0">
              <v-textarea
                rows="1"
                outlined
                readonly
                label="Año"
                :value="puntaje.anio">
              </v-textarea>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0">
              <v-textarea
                rows="1"
                outlined
                readonly
                label="Indicador"
                :value="puntaje.indicador.nombre">
              </v-textarea>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0">
               <v-text-field v-if="puntaje.tipo.id != 0"
                type="number"
                label="Puntaje"
                outlined
                v-model="puntaje.puntosSubParametro">
                <template v-slot:append>
                  <Info :mensaje="puntajeMsg"/>
                </template>
              </v-text-field>
              <v-text-field v-else
                type="number"
                label="Puntaje"
                outlined
                v-model="puntaje.puntaje">
                <template v-slot:append>
                  <Info :mensaje="puntajeMsg"/>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0">
               <v-text-field
                type="number"
                label="Cantidad minima"
                outlined
                v-model="puntaje.cantidadMinima">
                <template v-slot:append>
                  <Info :mensaje="cantidadMinimaMsg"/>
                </template>
              </v-text-field>
            </v-col>
          </v-col>
          <v-col cols="1" class="d-flex justify-center">
            <v-divider class="divisor" vertical></v-divider>
          </v-col>
          <v-col cols="12" md="6">
            <v-col cols="12">
              <TituloPrincipal titulo="Demo del funcionamiento"/>
            </v-col>
            <v-col cols="12">
              <Demo :datos="puntaje" :anio="anio"/>
            </v-col>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancelar" color="error">Cerrar</v-btn>
        <v-btn text @click="modificar" color="#0B3F67">Modificar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import ModalTitle from '@/components/utils/ModalTitleComponent'
import Demo from '@/components/puntaje/DemoPuntajeComponent'
import Info from '@/components/utils/InfoComponent'
import axios from '@/axios'
export default {
  components: {
    TituloPrincipal,
    ModalTitle,
    Info,
    Demo
  }, 
  props: [
    'anio',
    'categoria'
  ],
  computed: {
    dialog() {
      return this.$store.getters.modalPuntaje;
    },
    puntaje() {
      return this.$store.getters.dataPuntaje;
    },
    usuario(){
      return this.$store.getters.usuario;
    },
  },
  data: () => ({
    cantidadMinimaMsg: 'Cantidad minima de registros requeridos para completar el puntaje del parámetro',
    puntajeMsg: 'Puntaje total del parámetro, al llegar a este valor se completa el 100%'
  }),
  methods: {
    cancelar() {
      this.$store.commit('modalPuntaje', false);
    },
    modificar() {
      this.$store.dispatch('confirma', {mensaje: '¿Desea modificar los valores del parámetro? Esto afectará la asignación de puntos a este parametro. Los puntos ya asignados no se verán afectados.', show: true})
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch('loadingPage', true);
            let puntuacion = Object.assign({},this.puntaje);
            puntuacion.tipo = puntuacion.tipo.id;
            puntuacion.indicador = this.puntaje.indicador.id;
            axios.put('/manejoAnios/modificarPuntaje', null, {params: {
              puntuacion: JSON.stringify(puntuacion),
              usuario: this.usuario.id,
              categoria: this.categoria
            }}).then(response => {
              this.$store.dispatch('loadingPage', false);
              this.$store.dispatch('noti',{mensaje: 'Puntaje modificado', color:'success'});
              this.$emit('puntajeModificado')
            }).catch(error => {
              this.$store.dispatch('loadingPage', false);
              this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó el puntaje', color:'error'});              
            })
          }
        })
    }
  },
}
</script>
<style scoped>
  .divisor {
    border-width: 2px !important;
    border-color: #919191 !important;
    height: 100%;
  }
</style>