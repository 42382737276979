<template>
    <div height="100%">
        <v-simple-table fixed-header height="550">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left subtitle-1">Instalación</th>
                        <th class="text-center subtitle-1">Parámetros Mandatorios</th>
                        <th class="text-center subtitle-1">Categoria</th>
                        <th class="text-center subtitle-1">Gradación de Estrellas</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in data" :key="item.id" v-on:click="dashboardInstalacion(item)">                        
                        <td class="subtitle-2" >{{ item.nombre }}</td>
                        <td class="text-center"><semaforo :avance="item.porcentajeAvance"/></td>
                        <td class="text-center"><logo-categoria :instalacion="item"/></td>
                        <td class="text-center"><certificacion v-bind:certificacion="item.estrellas"/></td>
                        <!-- <td class="text-center"><semaforo :avance="item.porcentajeAvance"/></td>
                        <td class="text-center"><certificacion v-bind:certificacion="item.estrellas "/></td> -->
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
import Certificacion from '@/components/utils/CertificacionComponent'
import Semaforo from '@/components/utils/SemaforoComponent'
import LogoCategoria from '@/components/dashboard/LogoCategoriaComponent';

export default {
    components: {
        Certificacion,
        Semaforo,
        LogoCategoria
    },
    props: [
        'data',
    ],
    methods: {
        dashboardInstalacion(instalacion) {
          this.$store.commit('changeCategoria', `, ${instalacion.nombreCategoria}`);
          this.$store.commit('sucursal', instalacion);
          if(instalacion.tipoCategoria === 1) {
            this.$router.push({ name: 'BanderaAzulDashboard' })
          } else {
            this.$router.push({ name: 'DashboardIndicadores' })
          }
        }
    },
}
</script>