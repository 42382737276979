<template>
  <v-card>
    <v-card-text>
      <v-expansion-panels popout focusable>
        <v-expansion-panel
          v-for="(punto,i) in puntos"
          :key="i"
        >
          <v-expansion-panel-header>
            {{ punto.indicador }}
            <template v-slot:actions>
              <v-icon color="#0B3F67">
                $expand
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table>
              <thead>
                <tr>
                  <th class="text-md-subtitle-1 font-weight-black text-left">Parámetro</th>
                  <th class="text-md-subtitle-1 font-weight-black text-center">Puntos</th>
                  <th class="text-md-subtitle-1 font-weight-black text-right">Cantidad Minima</th>
                </tr>
              </thead>
              <tbody>
                <tr class="my-pointer" v-for="(puntaje, ind) in punto.puntajes" :key="ind" v-on:click="cargPagina(puntaje)">
                  <td class="detail">{{ puntaje.parametro }}</td>                                        
                  <td class="text-center" v-if="puntaje.tipo.id != 0">{{ puntaje.puntosSubParametro }}</td> 
                  <td class="text-center" v-else>{{ puntaje.puntaje }}</td> 
                  <td class="text-md-right"> {{ puntaje.cantidadMinima }} </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <Modificar-puntaje @puntajeModificado="modificado" :categoria="categoria" :anio="anio"/>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>
<script>
import ModificarPuntaje from '@/components/puntaje/ModificarPuntajeComponent'
export default {
  components: {
    ModificarPuntaje
  },
  props: [
    'anio',
    'puntos',
    'categoria'
  ],
  data: () => ({
    
  }),
  methods: {
    cargPagina(puntaje) {
      this.$store.commit('modalPuntaje', true);
      this.$store.commit('dataPuntaje', puntaje);
    },
    modificado() {
      this.$store.commit('modalPuntaje', false);
      this.$emit('modificado')
    }
  },
}
</script>
<style scoped>
.my-pointer {
  cursor: pointer;
}
</style>