<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div>Puntos actuales del parámetro: <strong>{{ datos.puntaje }}</strong></div>
        <div>Cantidad minima actual: <strong>{{ datos.cantidadMinima }}</strong></div>
        <div>Se obtienen: <strong>{{ catidadRegistros }} pts</strong> por cada registro agregado al parámetro.<Info :mensaje="info"/></div>
      </v-col>
      <v-col class="text-md-h6 text-sm-body-2">
        Comprobar resultado
      </v-col>
      <v-col cols="12">
        <Progreso :avance="porcentaje"/>
      </v-col>
      <v-col cols="12">
        <v-btn text outlined @click="reset" color="error">Reset</v-btn>
        <v-btn text outlined @click="avance" color="#0B3F67">Calcular Avance</v-btn>
      </v-col>
      <v-col cols="12">
        <div>Puntos ganados: <strong>{{ resultado }}</strong></div>
        <div>Registros ingresados: <strong>{{ registros }}</strong></div>
        <div>Puntos m&aacute;ximos: <strong>{{ datos.puntaje }} pts</strong> <Info :mensaje="msg"/></div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Progreso from '@/components/utils/BarraProgresoComponent'
import Info from '@/components/utils/InfoComponent'
export default {
  props: [
    'datos',
    'anio'
  ],
  components: {
    Info,
    Progreso
  },
  computed: {
    catidadRegistros() {
      let resultado = this.datos.tipo.id != 0 ? Math.round(this.datos.puntosSubParametro / this.datos.cantidadMinima) : Math.round(this.datos.puntaje / this.datos.cantidadMinima) ;
      return resultado.toString();
    },
    resultado: {
      get() {
        return this.resultadoAux;
      },
      set(valor) {
        this.resultadoAux = valor;
      }
    },
    porcentaje: {
      get() {
        return this.porcentajeAux;
      },
      set(valor) {
        this.porcentajeAux = valor;
      }
    }
  },
  data: () => ({
    info: 'No se manejan decimales por lo que se redondea al entero mas cercano.',
    msg: 'indica la cantidad máxima de puntos que se obtienen, sin importar la cantidad de registros agregados.',
    resultadoAux: 0,
    porcentajeAux: 0,
    registros: 0
  }),
  methods: {
    avance() {
      let resultado;
      if(this.anio < 2024) {
        resultado = this.datos.tipo.id != 0 ? Math.round(this.datos.puntosSubParametro / this.datos.cantidadMinima) : Math.round(this.datos.puntaje / this.datos.cantidadMinima);
      } else {
        resultado = this.datos.puntaje / this.datos.cantidadMinima;
      }
      this.resultado += resultado;
      this.resultado = this.resultado > this.datos.puntaje ? this.datos.puntaje : this.resultado;
      this.porcentaje = ((this.resultado  / this.datos.puntaje) * 100)
      this.registros = this.registros + 1;
    },
    reset() {
      this.porcentaje = 0;
      this.resultado = 0;
      this.registros = 0;
    }
  },
}
</script>