<template>
    <div>     
        <logo @cambiaAnio="cambiaAnio" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <v-row  align="stretch" align-content="stretch" v-if="rolUsuario === 'ADMIN'">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6" align-self="stretch">
                <v-card class="pa-1" color="#0B3F67" hover>
                    <listOrganizacionesActivas 
                        @getListaInstlaciones="listarInstalacionesActivas" 
                        v-bind:data="organizaciones"
                        @organizacionesMap="organizacionesMapa"/>
                </v-card> 
            </v-col>
            <v-col cols="12" md="6">
                <v-card class="pa-1" color="#0B3F67" hover>
                    <v-card hover>
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title class="headline mb-1">Instalaciones</v-list-item-title>
                                <v-list-item-subtitle>Por Organización</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-card-text>
                            <OrgChart v-if="loadedOrgas" :height="500" :chart-data="chartDataOrganizacionesInstalaciones" v-bind:options="optionsOrg"/>
                        </v-card-text>
                    </v-card> 
                </v-card>
            </v-col>
            <v-row v-if="showMapaOrga" align="stretch" align-content="stretch">
                <v-col cols="12" md="6" offset-md="3">
                    <v-card class="pa-1" color="#0B3F67" hover>
                        <v-card>
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title class="center headline mb-1">Organizaciones</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-card-text>
                                <mapa 
                                    v-if="showMapaOrga" 
                                    :dataMarkers="organizaciones" 
                                    :tipo="1" 
                                    @organizacionInfo="listarInstalacionesActivas"/>
                            </v-card-text>                    
                        </v-card>        
                    </v-card>         
                </v-col>
            </v-row>
        </v-row> 
        <v-row  align="stretch" align-content="stretch" v-if="organizacion !== null">
            <v-col cols="12" sm="12" md="6">
                <v-card class="pa-1" color="#0B3F67" hover>
                    <v-card>
                        <div class="d-flex justify-space-between">
                            <div>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title class="headline mb-1">Estatus Implementación</v-list-item-title>
                                        <v-list-item-subtitle>PBAE</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                            <div class="pr-3">
                                <v-text-field 
                                    label="Filtrar"
                                    append-icon="mdi-magnify"
                                    clearable
                                    v-model="filtroInstalaciones"
                                    @input="filterInstalacion"
                                    @click:clear="clearFilter"
                                />
                            </div>
                        </div>
                        <v-divider></v-divider>
                        <v-card-text>
                            <estatus v-bind:data="instalFiltradas"/>
                        </v-card-text>                    
                    </v-card>        
                </v-card>         
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <v-card class="pa-1" color="#0B3F67" hover>
                    <v-card>
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title class="headline mb-1">{{organizacion.nombre}}</v-list-item-title>
                                <v-list-item-subtitle>Instalaciones</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-card-text>
                            <mapa v-if="showMapa" :dataMarkers="instalaciones" :tipo="2"/>
                        </v-card-text>                    
                    </v-card>        
                </v-card>         
            </v-col>
        </v-row>     
        <v-row align="stretch" align-content="stretch" v-if="organizacion !== null && rolUsuario !== 'ADMIN'">
            <v-col cols="12">
                <v-card class="pa-1" color="#0B3F67" hover>
                    <v-card>
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title class="headline mb-1">Indicadores Ambientales</v-list-item-title>   
                                <v-list-item-subtitle>Consumos y Eficiencia</v-list-item-subtitle>                         
                            </v-list-item-content>                            
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-row>
                                <v-col cols="6" sm="4" md="3" align-self="center" class="pt-0 pb-0">
                                    <v-select
                                        :items="items"        
                                        v-model="indicadorLocal"
                                        item-text="nombre"
                                        item-value="id"                                
                                        label="Indicador"
                                        @change="cambiaIndicador"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-card class="pa-1" color="#0B3F67" hover>
                                        <v-card>
                                            <v-card-title primary-title>
                                                Consumo {{ indicadorNombre }} ({{unidadConsumo}})
                                            </v-card-title>
                                            <v-card-text>
                                                <barChart v-if="loaded" :height="500" :chart-data="chartDataConsumo" v-bind:options="options"/>
                                            </v-card-text>
                                        </v-card>         
                                    </v-card>                       
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-card class="pa-1" color="#0B3F67" hover>
                                        <v-card>
                                            <v-card-title primary-title>
                                                Eficiencia {{ indicadorNombre }} ({{unidadConsumo}})
                                            </v-card-title>
                                            <v-card-text>
                                                <BarrasChart v-if="loadedEfic" :height="500" :chart-data="chartDataEficiencia" v-bind:options="options"/>
                                            </v-card-text>
                                        </v-card> 
                                    </v-card> 
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-card>
            </v-col>
        </v-row>  
    </div>
</template>
<script>

import ListOrganizacionesActivas from '@/components/dashboard/OrganizacionesStatusComponent'
import Estatus from '@/components/dashboard/InstalacionesStatusComponent'
import BarChart from '@/components/graficos/BarsChartComponent'
import OrgChart from '@/components/graficos/HorizontalBarChartComponent'
import Mapa from '@/components/graficos/MapaComponent'
import Logo from '@/components/utils/CompanyComponent'
import VueSticky from 'vue-sticky'
import axios from "@/axios.js";
import BarrasChart from '@/components/graficos/BarrasComponent'

export default {
    directives: {
        'sticky': VueSticky,
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },    
    computed: {
        notAdmin() {
            return this.$store.getters.usuario.rol !== 'ADMIN' ? true : false;
        },
        organizacion() {
            return this.$store.getters.organizacion;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
        origen() {
            let rol = this.usuario.rol;
            let constantes = this.$store.getters.constantes;
            switch(rol) {
                case 'ADMIN':
                    return constantes.porOrganizacion;
                    break;
                case 'CEO':
                    return constantes.porOrganizacion;
                    break;
                case 'COMITE_AMBIENTAL':
                    return constantes.porComite;
                    break;
                default:
                    if(this.sucursal != null || undefined) {
                        return constantes.porInstalacion;
                    } else {
                        return constantes.porOrganizacion;
                    }                    
                    break;
            }
        },
        anioConsulta() {
            return this.$store.getters.anioConsulta;
        },
        indicadorLocal: {
            get() {
                return this.indicador;
            },
            set(newData) {
                this.indicador = newData;
            }
        },
        indicadorNombre() {
            let ind = this.items.find(obj => obj.id == this.indicador);
            return ind.nombre;
        },
        tipoConsulta() {
            switch(this.rolUsuario) {
                case 'ADMIN':
                    return this.organizacion != null ? this.organizacion.id : null; 
                    break;
                case 'CEO':
                    return this.organizacion.id;
                    break;
                default:
                    if(this.sucursal != null || undefined) {
                        return this.sucursal.id;
                    } else {
                        return this.organizacion.id;
                    }                    
                    break;
            }
        }
    },
    components: {
        BarChart,
        Estatus,
        ListOrganizacionesActivas,
        Mapa,
        OrgChart,
        Logo,
        BarrasChart
    },
    mounted() {
        this.listarOrganizacionesActivas();
        this.listarInstalacionesActivas();
        this.getDataConsumo(this.tipoConsulta, this.indicadorLocal, this.anioConsulta, this.origen);
        this.instalFiltradas = this.instalaciones
    },
    data: () => ({
        organizaciones: [], 
        instalaciones: [],
        instalFiltradas: [],
        filtroInstalaciones: null,
        chartDataConsumo: null,
        chartDataEficiencia: null,
        items: [
            { id: 1, nombre: 'Electricidad' },
            { id: 2, nombre: 'Agua' },
            { id: 3, nombre: 'Combustibles' },
            { id: 11, nombre: 'Papel' },
            { id: 6, nombre: 'Residuos' },
        ],
        chartDataOrganizacionesInstalaciones: {
            labels: [],
            datasets: []
        },        
        dataConsumo: [],
        showMapa: false,       
        options: {
            legend: {
                display: true,
                position: 'top'
            },
            responsive: true,
            aspectRatio: 2,
            responsiveAnimationDuration: 2000,
            maintainAspectRatio: false,
            animation: {
                duration: 2000
            },
            scales: {
                xAxes: [{
                    stacked: true,
                    ticks: {
                      beginAtZero:true,
                    }
                }],
                yAxes: [{
                    stacked: true,
                    ticks: {
                      beginAtZero:true,
                    }
                }]
            }
        },
        loaded: false,
        loadedEfic: false,
        loadedOrgas: false,
        indicador: 1,
        optionsOrg: {
            legend: {
                display: true,
                position: 'top'
            },
            responsive: true,
            aspectRatio: 2,
            responsiveAnimationDuration: 2000,
            maintainAspectRatio: false,
            animation: {
                duration: 2000
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        color: "rgba(0, 0, 0, 0)",
                    },
                    stacked: true,
                }],
                yAxes: [{
                    stacked: true,                    
                    ticks: {
                        mirror: true,
                        scaleShowLabels:false,
                        padding: -5,
                    },
                    
                }]
            },
        },
        showMapaOrga: false,
        unidadConsumo: "N/A",
    }),
    methods: {
        getDataChartConsumo(actual, anterior) {            
            this.loaded = false;
            let datasets = [];
            delete actual.detalle;
            //dataset para grafico de barras
            actual.forEach((sucursal) => {         
                delete sucursal.id;                     
                delete sucursal.detalle;
                let labelName = sucursal.medidor;
                delete sucursal.medidor;
                delete sucursal.consumoAnterior;
                let color = this.randomColor();
                let datos = Object.values(sucursal);
                datos.shift();//extrae posicion 0 del array
                let dataSet = {
                    label: labelName,
                    data: datos,
                    type: 'bar',
                    backgroundColor: color,
                    borderColor: color,
                    borderWidth: 1,
                    order: 2
                };
                datasets.push(dataSet);
            })
            //dataset para grafico de linea 
            let labelTot = anterior.medidor + ' ' + anterior.detalle;
            delete anterior.medidor;
            delete anterior.detalle;
            let dataAnterior = Object.values(anterior);
            dataAnterior.shift();//extrae posicion 0 del array
            dataAnterior.shift();//extrae posicion 0 del array
            let dataSetLinea = {
                label: labelTot,
                data: dataAnterior,
                type: 'line',
                borderColor: this.randomColor(),
                borderWidth: 2,
                order: 1
             };
             datasets.push(dataSetLinea);
            this.chartDataConsumo = {
                labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Setiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                datasets: datasets
            }       
            this.loaded = true;     
        },
        getDataChartEficiencia(dataChart) {          
            let dataSets = [];
            dataChart.forEach((medidor) => {
                let color = this.randomColor();
                let datos = Object.values(medidor);
                datos.shift();//extrae el id del array
                let labelData = datos.shift(); //extrae el nombre del medidor
                datos.shift();//extrae el nis del array
                datos.shift();//extrae el detalle del array
                datos.shift();//extrae posicion 0 del array
                let dataSet = {
                    label: labelData,                
                    data: datos,
                    backgroundColor: color,
                    borderColor: color,
                    borderWidth: 1,
                    type: 'bar'
                };
                dataSets.push(dataSet);
            })            

            this.chartDataEficiencia = {
                labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Setiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                datasets: dataSets
            }
            this.loadedEfic = true;
        },
        randomColor() {
            let r = Math.floor(Math.random() * 255);
            let g = Math.floor(Math.random() * 255);
            let b = Math.floor(Math.random() * 255);
            return "rgb(" + r + "," + g + "," + b + ")";
        },
        listarOrganizacionesActivas() {
            this.cargando = true;
            this.showMapa = false;
            axios.get("/organizacion/listarOrganizacionesActivas")
            .then(response => {
                this.organizaciones = response.data.organizaciones;   
                this.showMapaOrga = true;
                this.getDataInstalacionesOrganizacion(this.organizaciones);
                this.cargando = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.cargando = false;
              }
            })
        },
        organizacionesMapa() {
            this.$store.commit('organizacion', null);
            this.showMapa = false;
            this.showMapaOrga = true;
        },
        listarInstalacionesActivas() {
            this.cargando = true;
            this.showMapa = false;
            this.showMapaOrga = false;
            let anio = this.anioConsulta;
            let idUsuario = this.$store.getters.usuario.id;
            if(this.organizacion!=null){
                axios.get("/organizacion/listarInstalacionesActivas/"+this.organizacion.id + "/" +anio+"/"+idUsuario)
                .then(response => {
                    this.filtroInstalaciones = null
                    this.instalaciones = response.data.instalaciones;
                    this.instalFiltradas = response.data.instalaciones;
                    this.cargando = false;
                    this.showMapa = true;
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                    this.cargando = false;
                  }
                })
            }
        },
        getDataInstalacionesOrganizacion(orgs) {
            let datasets = [];
            let labels = [];
            let color = this.randomColor();
            let dataOrg = [];
            this.loadedOrgas = false;
            //dataset para grafico de barras
            orgs.forEach((orga) => {
                labels.push(orga.nombre);
                dataOrg.push(orga.cantInstalaciones);
            })
            let dataSet = {
                label: "Instalaciones",
                maxBarThickness: 20,
                maintainAspectRatios: false,
                data: dataOrg,
                type: 'horizontalBar',
                borderColor: color,
                borderWidth: 1,
            };
            datasets.push(dataSet);
            this.chartDataOrganizacionesInstalaciones = {
                labels: labels,
                datasets: datasets
            };
            this.loadedOrgas = true;
        },
        getDataConsumo(id, indicador, anio, origen) {
            this.loaded = false;
            this.loadedEfic = false;
            if(this.organizacion != null || this.organizacion != undefined){
                axios.get(`/instalaciones/getDataConsuGrafico/${id}/${indicador}/${this.anioConsulta}/${origen}`, 
                { params: {
                    idUsuario: this.$store.getters.usuario.id
                }})
                .then(response => {
                    this.getDataChartConsumo(response.data.data, response.data.consumoTotalAnt);  
                    this.getDataChartEficiencia(response.data.eficiencia); 
                    this.unidadConsumo = response.data.unidadConsumo;   
                    this.loadingModal(false);
                }).catch(error => {     
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {           
                    console.log("Error al cargar datos de consumo, " + error);    
                    this.loadingModal(false);    
                  }        
                })   
            } 
        },
        cambiaAnio() {
            this.listarInstalacionesActivas();
            this.getDataConsumo(this.tipoConsulta, this.indicadorLocal, this.anioConsulta, this.origen);        
        },
        cambiaIndicador() {            
            this.getDataConsumo(this.tipoConsulta, this.indicadorLocal, this.anioConsulta, this.origen);
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        filterInstalacion() {
            if(this.filtroInstalaciones) {
                this.filtroInstalaciones = this.filtroInstalaciones.toLowerCase()
                this.instalFiltradas = this.instalaciones.filter(instal => instal.nombre.toLowerCase().includes(this.filtroInstalaciones));
            } else {
                this.instalFiltradas =  this.instalaciones
            }
        },
        clearFilter() {
            this.filtroInstalaciones = null
            this.filterInstalacion()
        }
    },
}
</script>
<style scoped>
    .mainTitle {
        position: fixed;
    }
</style>