<template>
    <v-dialog v-model="dialog"
        persistent 
        scrollable
        max-width="650px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon
                        small
                        class="mr-2"
                        v-on="{...tooltip, ...on}"
                        @click="getCategorias()">
                        mdi-apps
                    </v-icon>
                </template>
                <span>Instalaciones por Categoría</span>
            </v-tooltip>
        </template>
        <v-card>
            <v-card-title class="pa-0">
              <ModalTitle titulo="Instalaciones por Categoría" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-container>
                    <v-list class="pt-2">
                        <template>
                            <v-subheader
                                v-text="`Cantidad de Instalaciones por Categoría`"
                            ></v-subheader>

                            <v-divider
                                inset
                            ></v-divider>

                            <v-list-item class="pt-1" v-for="(item, index) in categorias" :key="index" >
                                <v-list-item-avatar>
                                    <img :src="logoSrc(item.logo)" contain alt="zurqui"/>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title v-html="item.nombre"></v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-text-field
                                        name="cantidad"
                                        label="Cantidad"
                                        :id="item.nombre"
                                        v-model="item.cantidad"
                                    ></v-text-field>
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" text @click="agregar">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import ModalTitle from '@/components/utils/ModalTitleComponent'
    import { mask } from "vue-the-mask";
    import axios from "@/axios.js";

    export default {
        components: {
            ModalTitle
        },
        directives: {
            mask,
        },
        props: [
            'id'
        ],
        computed: {
            
        },
        data: () => ({
            dialog: false,
            categorias: [],    
        }),
        methods: {
            getCategorias() {
                this.loadingModal(true);
                axios.get(`/categoria/listarCategoriasOrganizacion/${this.id}`).then(response => {
                    this.categorias = response.data;   
                    this.loadingModal(false);
                }).catch(error => {
                    if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                    } else {
                        console.log(error);
                        this.loadingModal(false);
                    }
                })
            },     
            agregar() {
                this.loadingModal(true);
                axios.post(`/organizacion/agregarCantCategoria/${this.id}`, this.categorias)
                .then(response => {
                    this.cancelar();
                    this.$store.dispatch('noti',{mensaje: 'Organización agregada', color:'success'});   
                    this.$emit('reload');
                }).catch(error => {
                    if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                    } else {
                        if (error.request && error.request.status === 415) {
                            this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                            this.loadingModal(true);
                        } else {
                            this.cancelar();
                            this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó la organización', color:'error'});              
                        }
                    }
                })
                
            },
            logoSrc(logo) {
                return require("@/assets/" + logo);
            },

            loadingModal(modal) {                        
                this.$store.dispatch('loadingPage', modal);
            },

            cancelar() {
                this.categorias = [];
                this.loadingModal(false);
                this.dialog = false;
            },
        }
    }
</script>