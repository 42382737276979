<template>
    <v-dialog
        v-model="dialog" 
        persistent
        scrollable
        max-width="600px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">
                    <v-btn v-on="{ ...tooltip, ...dialog }" @click="getData" class="white--text"  color="#0B3F67">Identificar Amenazas<v-icon right>mdi-plus-box-outline</v-icon></v-btn>
                </template>
                Identificar Amenazas
            </v-tooltip>  
        </template>
        <v-card>
            <v-card-title class="pa-0">
              <ModalTitle titulo="Identificar Amenazas" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-row v-for="(item, index) in dataAmenaza" :key="index">
                    <v-col cols="6">
                      <div class="d-flex">
                        <v-checkbox 
                          v-if="item.amenazaNombre == `Otro`"
                          v-model="otro"
                        />
                        <v-text-field
                          v-if="item.amenazaNombre == `Otro`"
                          outlined
                          :disabled="!otro"
                          label="Otro"
                          counter="100"
                          v-model="otroAmenaza"
                          :error-messages="otroErrors"             
                          @change="$v.otroAmenaza.$touch()"
                          @blur="$v.otroAmenaza.$touch()"
                        />
                        <v-text-field
                          v-else
                          outlined
                          disabled
                          v-model="item.amenazaNombre"
                        />
                      </div>
                    </v-col>
                    <v-col cols="6">
                        <v-select
                            outlined
                            :items="escala"
                            item-value="id"
                            item-text="nombre"
                            label="Nivel de Exposición"
                            v-model="item.idnivelExposicion"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" v-if="item.amenazaNombre == `Otro` && otro">
                      <v-textarea
                        label="Descripción Amenaza Climática"
                        v-model="descrAmenaza"
                        outlined
                        counter="500"
                        :error-messages="descripcionErrors"             
                        @change="$v.descrAmenaza.$touch()"
                        @blur="$v.descrAmenaza.$touch()"
                      />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="cancelar" text>Cancelar</v-btn>
                <v-btn color="#0B3F67" @click="agregar" text>Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from '@/axios.js'
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { requiredIf, maxLength } from "vuelidate/lib/validators"

export default {
    props: [
      'amenazas',
      'escala'
    ],
    components: {
      ModalTitle
    },
    computed: {
      anioConsulta() {
        return this. $store.getters.anioConsulta;
      },
      instalacion() {
        return this.$store.getters.sucursal;
      },
      usuario() {
        return this.$store.getters.usuario;
      },
      otroErrors() {
        const errors = []
        if (!this.$v.otroAmenaza.$dirty) return errors            
        !this.$v.otroAmenaza.required && errors.push('Nombre de la amenaza climática.')
        !this.$v.otroAmenaza.maxLength && errors.push('No debe exceder el tamaño permitido.')
        return errors
      },
      descripcionErrors() {
        const errors = []
        if (!this.$v.descrAmenaza.$dirty) return errors            
        !this.$v.descrAmenaza.required && errors.push('Agregue descripción de la amenaza climática.')
        !this.$v.descrAmenaza.maxLength && errors.push('No debe exceder el tamaño permitido.')
        return errors
      },
    },
    data: () => ({
        otro: false,
        otroAmenaza: null,
        descrAmenaza: null,
        dataAmenaza: [],
        dialog: false,
    }),
    validations: {
      descrAmenaza: { 
        required: requiredIf(function(){
            return this.otro;
        }), 
        maxLength: maxLength(500) 
      },
      otroAmenaza: { 
        required: requiredIf(function(){
            return this.otro;
        }), 
        maxLength: maxLength(100) 
      },
    },
    methods: {
      getData() {
        this.dataAmenaza = this.amenazas
      },
      agregar() {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.dataAmenaza = this.otraAmenazaNewValues()
          this.loadingModal(true);
          axios.post('/adaptacion/identificarAmenazas', this.dataAmenaza, {params: {
              inst: this.instalacion.id,
              usuario: this.usuario.id,
              anio: this.anioConsulta
          }}).then(response => {
              this.$store.dispatch('noti', {mensaje: 'Amenazas identificadas', color: 'success'});
              this.loadingModal(false);
              this.$emit('reload');
              this.cancelar();
          }).catch(error => {
            if (error.request && error.request.status === 401) {
              this.$store.commit('showSessionDialog', true);
            } else {
              this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se pudo identificar amenazas', color: 'error'});
              this.loadingModal(false);
            }
          })
        }
      },
      cancelar() {
        this.otro =false
        this.otroAmenaza = null
        this.descrAmenaza = null
        this.dialog = false;
      },
      loadingModal(modal) {                        
          this.$store.dispatch('loadingPage', modal);
      },
      otraAmenazaNewValues() {
        if(this.otro){
          let updatedData = { amenazaNombre: this.otroAmenaza, amenazaDefinicion: this.descrAmenaza }
          return this.dataAmenaza.map((item) => (item.idAmenazaClimatica === 13 ? { ...item, ...updatedData } : item))
        }
      }
    },
}
</script>