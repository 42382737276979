import moment from "moment";
import diccionario from '@/imageDictionary.js';

export class InformeService {
    constructor(constantes) {
        this.constantes = constantes;
    }

    agregaTablaComite(comiteAmbiental, instalacion) {
        let tablaComite = {
            table: {
                body: [
                    [
                        { text: 'Comité Interno Categoría Cambio Climático PBAE', alignment: 'center', bold: true, colSpan: 6, fillColor: '#8db3e2' }, '', '', '', '', ''
                    ],
                    [
                        { text: 'Tipo de miembro', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Nombre de los integrantes', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Puesto dentro de la organización', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Cedula de identidad o número de identificación', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Correo electrónico', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Número de teléfono', alignment: 'center', bold: true, fillColor: '#8db3e2' }
                    ],
                ]
            }
        }
        comiteAmbiental.forEach(comite => {
            let row = [
                { text: comite.nombreRol, alignment: 'center' },
                { text: comite.nombre + ' ' + comite.apellido, alignment: 'left' },
                { text: comite.puestoTrabajo, alignment: 'left' },
                { text: comite.cedula, alignment: 'center' },
                { text: comite.correo, alignment: 'center' },
                { text: instalacion.telefono, alignment: 'center' },
            ]
            tablaComite.table.body.push(row);
        })
        return tablaComite;
    }

    tablaComiteAgrop(comiteAmbiental, color) {
        let tablaComite = {
            table: {
                widths: ['20%', '30%', '20%', '30%'],
                body: [
                    [
                        { text: 'Comité Interno PBAE', alignment: 'center', bold: true, colSpan: 4, fillColor: color }, '', '', ''
                    ],
                    [
                        { text: 'Nombre de los integrantes', alignment: 'center', bold: true, fillColor: color },
                        { text: 'Puesto dentro de la organización', alignment: 'center', bold: true, fillColor: color },
                        { text: 'Cedula de identidad', alignment: 'center', bold: true, fillColor: color },
                        { text: 'Correo electrónico', alignment: 'center', bold: true, fillColor: color },
                    ],
                ]
            }
        }
        if(comiteAmbiental.length) {
            comiteAmbiental.forEach(comite => {
                let row = [
                    { text: `${comite.nombre} ${comite.apellido}`, alignment: 'left' },
                    { text: comite.puestoTrabajo, alignment: 'left' },
                    { text: comite.cedula, alignment: 'center' },
                    { text: comite.correo, alignment: 'center' },
                ]
                tablaComite.table.body.push(row);
            })
        } else {
            let rowEmpty = [
                { text: 'N/A', alignment: 'left' },
                { text: 'N/A', alignment: 'left' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
            ]
            tablaComite.table.body.push(rowEmpty);
        }
        return tablaComite;
    }

    combustiblesTablaCasoUno(flotaCasoUno) {
        let combustibles = {
            table: {
                widths: ['15%', '15%', '20%', '20%', '10%', '20%'],
                body: [
                    [
                        { text: 'Número de activo', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Placa', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Tipo de equipo', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Marca', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Año', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Tipo de combustible fósil', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                    ],
                ]
            }
        }
        if (flotaCasoUno != undefined && flotaCasoUno != null) {
            flotaCasoUno.forEach(vehiculo => {
                let row = [
                    { text: vehiculo.numPlacaActivo },
                    { text: vehiculo.numPlacaActivo },
                    { text: vehiculo.tipoConsumidor },
                    { text: vehiculo.marca },
                    { text: vehiculo.anio },
                    { text: vehiculo.tipoCombustible },
                ]
                combustibles.table.body.push(row);
            })
        } else {
            let row = [
                { text: 'N/A' },
                { text: 'N/A' },
                { text: 'N/A' },
                { text: 'N/A' },
                { text: 'N/A' },
                { text: 'N/A' },
            ]
            combustibles.table.body.push(row);
        }
        return combustibles;
    }

    justificacionReduccion2022(justificacion, color, titulo) {
        let justificaciuonReduccion = {
            table: {
                widths: ['100%'],
                body: [
                    [
                        { text: titulo, alignment: 'left', bold: true, fillColor: color },

                    ],
                ]
            }
        }
        if (justificacion) {
            let row = [
                { text: justificacion, alignment: 'justify' }
            ]
            justificaciuonReduccion.table.body.push(row);

        } else {
            let row = [
                { text: 'N/A', alignment: 'center' },
            ]
            justificaciuonReduccion.table.body.push(row);
        }
        return justificaciuonReduccion;
    }

    justificacionAguasRes022(justificacion, sistema, tipoSistemaTrat, color, titulo, evidencias) {
        let tipo = (sistema != null && sistema != undefined) ? sistema.sistema : 99;
        let justificaciuonReduccion = {
            table: {
                widths: ['100%'],
                body: [
                    [
                        { text: titulo, alignment: 'left', bold: true, fillColor: color },

                    ],
                ]
            }
        }
        if (tipo == tipoSistemaTrat && justificacion != undefined && justificacion != null) {
            let row = [
                { text: justificacion, alignment: 'justify' }
            ]
            justificaciuonReduccion.table.body.push(row);

        } else {
            let row = [
                { text: 'N/A', alignment: 'center' },
            ]
            justificaciuonReduccion.table.body.push(row);
        }
        if (tipo == tipoSistemaTrat && evidencias != undefined && evidencias.length > 0 && evidencias != null) {
         //   let evidenciasTable = {
         //       table: {
         //           widths: ['100%'],
         //           body: [],
         //           layout: 'noBorders',
         //       }
         //   }
            let row = [];
            evidencias.forEach((evidencia, index) => {
                let image = {
                    image: evidencia,
                    alignment: 'center',
                    fit: [460, 380],
                }
                row.push(image);
            })
            //se dividen en arrays de 2 elementos
            let index = 0;
            let arrayLength = row.length;
            for (index = 0; index < arrayLength; index += 1) {
                let myRow = row.slice(index, index + 1);

                if (myRow.length < 1) {
                    myRow.push('')
                }
                justificaciuonReduccion.table.body.push(myRow);
            }
        }
        return justificaciuonReduccion;
    }

    combustiblesJustificacion(justificacion, color, titulo) {
        let combustibles = {
            table: {
                widths: ['100%'],
                body: [
                    [
                        { text: titulo, alignment: 'left', bold: true, fillColor: color },

                    ],
                ]
            }
        }
        if (justificacion != undefined && justificacion != null) {
            let row = [
                { text: justificacion, alignment: 'left' }
            ]
            combustibles.table.body.push(row);
        } else {
            let row = [
                { text: 'N/A' },
            ]
            combustibles.table.body.push(row);
        }
        return combustibles;
    }

    combustiblesTablaCasoDos(acciones, instalacion) {
        let combustibles = {
            table: {
                widths: ['25%', '10%', '10%', '35%', '20%'],
                body: [
                    [
                        { text: 'Actividad', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Primer orden', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Segundo orden', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Descripción de la actividad', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Fecha de la actividad', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                    ]
                ]
            }
        }
        if (!instalacion.tieneCombustibles) {
            acciones.forEach(accion => {
                let row = [
                    { text: accion.tipoActividad },
                    { text: accion.tipoAccion === 1 ? 'X' : '', alignment: 'center' },
                    { text: accion.tipoAccion === 2 ? 'X' : '', alignment: 'center' },
                    { text: accion.descripcion },
                    { text: moment(accion.fechaActividad).format('DD/MM/YYYY'), alignment: 'center' },
                ]
                combustibles.table.body.push(row);
            })
        } else {
            let row = [
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
            ]
            combustibles.table.body.push(row);
        }
        return combustibles;
    }

    combustiblesTablaCasoDos2022(acciones, instalacion) {
        let sale1 = false;
        let sale2 = false;
        let sale3 = false;
        let sale4 = false;

        let combustibles = {
            table: {
                widths: [],
                body: []
            }
        }
        {
            combustibles.table.widths = ['50%', '50%']
            let rowEnc = [
                { text: 'Actividad N°1', alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
            ]
            combustibles.table.body.push(rowEnc);
        }
        {
            combustibles.table.widths = ['50%', '50%']
            let rowEnc1 = [
                { text: 'Nombre de la actividad', alignment: 'left', fillColor: '#8db3e2', bold: true },
                { text: 'Fecha de la actividad', alignment: 'left', fillColor: '#8db3e2', bold: true }
            ]
            combustibles.table.body.push(rowEnc1);
            if (!instalacion.tieneCombustibles) {
                acciones.forEach((accion) => {
                    let row1 = [
                        { text: accion.tipoActividad, alignment: 'center' },
                        { text: moment(accion.fechaActividad).format('DD/MM/YYYY'), alignment: 'center' }
                    ]
                    if (!sale1) {
                        combustibles.table.body.push(row1);
                        sale1 = true;
                    }
                })
            }
            if (!sale1) {
                let row1 = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' }
                ]
                combustibles.table.body.push(row1);
            }
        }

        {
            combustibles.table.widths = ['50%', '50%']
            let rowEnc2 = [
                { text: 'Descripción o temas tratados', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
            ]
            combustibles.table.body.push(rowEnc2);

            if (!instalacion.tieneCombustibles) {
                acciones.forEach((accion, i) => {
                    let row2 = [
                        { text: accion.descripcion + ' ' + accion.temasTratados, alignment: 'left', colSpan: 2 }, ''
                    ]
                    if (!sale2) {
                        combustibles.table.body.push(row2);
                        sale2 = true;
                    }
                })
            }
            if (!sale2) {
                let row2 = [
                    { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                ]
                combustibles.table.body.push(row2);
            }
        }

        {
            combustibles.table.widths = ['50%', '50%']
            let rowEnc3 = [
                { text: 'Marque con x el tipo de público al que va dirigido la acción:', alignment: 'left', fillColor: '#8db3e2', bold: true },
                { text: 'Si corresponde, marque con x el tipo de modalidad de la actividad:', alignment: 'left', fillColor: '#8db3e2', bold: true }
            ]
            combustibles.table.body.push(rowEnc3);

            if (!instalacion.tieneCombustibles) {
                acciones.forEach((accion, i) => {
                    let row4 = [
                        { text: accion.listaParticipante, alignment: 'center' },
                        { text: accion.modalidad.nombre, alignment: 'center' }
                    ]
                    if (!sale3) {
                        combustibles.table.body.push(row4);
                        sale3 = true;
                    }
                })
            }
            if (!sale3) {
                let row4 = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' }
                ]
                combustibles.table.body.push(row4);
            }
        }
        {
            combustibles.table.widths = ['50%', '50%']
            let rowEnc4 = [
                { text: 'Evidencias de las actividades realizadas', alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
            ]
            combustibles.table.body.push(rowEnc4);
        }

        {
            combustibles.table.widths = ['50%', '50%']
            let rowEnc5 = [
                { text: 'Fotografías de la actividad', alignment: 'left', fillColor: '#8db3e2', bold: true },
                { text: 'Listas de asistencia o Certificaciones de participación. Solamente si es educación ambiental.', alignment: 'left', fillColor: '#8db3e2', bold: true },
            ]
            combustibles.table.body.push(rowEnc5);
            if (!instalacion.tieneCombustibles) {
                acciones.forEach((accion, i) => {
                    let row5 = [
                        { image: accion.docActividad, alignment: 'center', fit: [460, 380] },
                        { image: accion.docAsistencia, alignment: 'center', fit: [460, 380] },
                    ]
                    if (!sale4) {
                        combustibles.table.body.push(row5);
                        sale4 = true;
                    }
                })
            }
            if (!sale4) {
                let row5 = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' }

                ]
                combustibles.table.body.push(row5);
            }
        }
        return combustibles;
    }


    tablaEvidenciasCasoDos2022(evidencias) {
        let sale5 = false;

        let tablaEvidenciaCasoDos22 = {
            table: {
                widths: [],
                body: []
            }
        }
        {
            tablaEvidenciaCasoDos22.table.widths = ['50%', '50%']
            let rowEnc6 = [
                { text: 'Imágenes (específicas del tema tratado asociado a este parámetro) con el contenido de la presentación utilizada en la capacitación o charla. Solamente si es educación ambiental.', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
            ]
            tablaEvidenciaCasoDos22.table.body.push(rowEnc6);
            if (evidencias != undefined && evidencias.length > 0 && evidencias != null) {
                evidencias.forEach((evidencia, index) => {
                    let row6 = [
                        { image: evidencia, alignment: 'center', fit: [460, 380], colSpan: 2 }, ''
                    ]
                    if (!sale5) {
                        tablaEvidenciaCasoDos22.table.body.push(row6);
                        sale5 = true;
                    }
                })
            }
            if (!sale5) {
                let row6 = [
                    { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                ]
                tablaEvidenciaCasoDos22.table.body.push(row6);
            }
        }
        return tablaEvidenciaCasoDos22;
    }


    tablaEvidenciasAgua(evidencias) {
        let sale5 = false;

        let tablaEvidenciaagua = {
            table: {
                widths: [],
                body: []
            }
        }
        {
            tablaEvidenciaagua.table.widths = ['50%', '50%']
            let rowEnc6 = [
                { text: 'Constancia de la potabilidad del agua, emitida por el ente que les brinda el servicio \n(AyA-ESPH-ASADA-Municipalidad-otro)', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
            ]
            tablaEvidenciaagua.table.body.push(rowEnc6);
            if (evidencias != undefined && evidencias.length > 0 && evidencias != null) {
                evidencias.forEach((evidencia, index) => {
                    let row6 = [
                        { image: evidencia, alignment: 'center', fit: [460, 380], colSpan: 2 }, ''
                    ]
                    if (!sale5) {
                        tablaEvidenciaagua.table.body.push(row6);
                        sale5 = true;
                    }
                })
            }
            if (!sale5) {
                let row6 = [
                    { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                ]
                tablaEvidenciaagua.table.body.push(row6);
            }
        }
        return tablaEvidenciaagua;
    }

    tablaEvidenciasAguaF(evidencias) {
        let sale4 = false;

        let tablaEvidenciaaguaf = {
            table: {
                widths: [],
                body: []
            }
        }
        {
            tablaEvidenciaaguaf.table.widths = ['50%', '50%']
            let rowEnc5 = [
                { text: 'Constancia de la potabilidad del agua, emitida mediante análisis realizado por un laboratorio acreditado por el Ente Costarricense de Acreditación (ECA). Aplica para aquellos casos que, tienen fuentes propias de abastecimiento o porque no obtuvieron el certificado del ente que les suministra el servicio.:', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
            ]
            tablaEvidenciaaguaf.table.body.push(rowEnc5);
            if (evidencias != undefined && evidencias.length > 0 && evidencias != null) {
                evidencias.forEach((evidencia, index) => {
                    let row5 = [
                        { image: evidencia, alignment: 'center', fit: [460, 380], colSpan: 2 }, ''
                        //{ text: 'N/A', alignment: 'center', colSpan: 2 }, ''

                    ]
                    if (!sale4) {
                        tablaEvidenciaaguaf.table.body.push(row5);
                        sale4 = true;
                    }
                })
            }
            if (!sale4) {
                let row5 = [
                    { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                ]
                tablaEvidenciaaguaf.table.body.push(row5);
            }
        }
        return tablaEvidenciaaguaf;
    }

    tablaConsumo(consumo, indicador, color) {
        let titulo;
        let subTitulo;
        switch (indicador) {
            case this.constantes.indicadorCombustibles:
                titulo = 'Tipo Combustible';
                subTitulo = 'Cantidad de combustibles fósiles consumidos (L)';
                break;
            case this.constantes.indicadorAgua:
                titulo = 'Medidor';
                subTitulo = 'Consumo mensual de agua en metros cúbicos (m3 )';
                break;
            case this.constantes.indicadorEnergia:
                titulo = 'Medidor/Mes';
                subTitulo = 'Consumo mensual de electricidad en kW/h';
                break;
            case this.constantes.indicadorResiduos:
                titulo = 'Cantidad/Mes';
                subTitulo = 'Consumo mensual de papel (Resmas)';
                break;
        }
        let tablaCons = {
            table: {
                widths: ['14%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '14%'],
                body: [

                    [
                        { text: titulo, alignment: 'center', rowSpan: 2, bold: false, fillColor: color, fontSize: 9 },
                        { text: subTitulo, alignment: 'center', colSpan: 13, bold: false, fillColor: color },
                        '', '', '', '', '', '', '', '', '', '', '', '',
                    ],
                    [
                        '',
                        { text: 'Ene', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Feb', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Mar', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Abr', alignment: 'center', bold: false, fillColor: color },
                        { text: 'May', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Jun', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Jul', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Ago', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Sep', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Oct', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Nov', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Dic', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Total', alignment: 'center', bold: false, fillColor: color },
                    ]
                ],
            }
        }
        if (consumo != undefined && consumo.length > 0) {
            consumo.forEach(c => {
                let row = [
                    { text: c.medidor, alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.enero), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.febrero), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.marzo), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.abril), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.mayo), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.junio), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.julio), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.agosto), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.septiembre), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.octubre), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.noviembre), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.diciembre), alignment: 'center', fontSize: 9 },
                    { text: this.consumoTotal(c), alignment: 'center' },
                ]
                tablaCons.table.body.push(row);
            })
        } else {
            let row = [
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
            ]
            tablaCons.table.body.push(row);
        }
        return tablaCons;
    }

    tablaConsumo2022(consumo, indicador, color) {
        let titulo;
        let subTitulo;
        switch (indicador) {
            case this.constantes.indicadorCombustibles:
                titulo = 'Tipo Combustible';
                subTitulo = 'Cantidad de combustibles fósiles consumidos (L)';
                break;
            case this.constantes.indicadorAgua:
                titulo = 'Medidor';
                subTitulo = 'Consumo mensual de agua en metros cúbicos (m3 )';
                break;
            case this.constantes.indicadorEnergia:
                titulo = 'Medidor/Mes';
                subTitulo = 'Consumo mensual de electricidad en kW/h';
                break;
            case this.constantes.indicadorResiduos:
                titulo = 'Cantidad/Mes';
                subTitulo = 'Consumo mensual de papel (Resmas)';
                break;
        }
        let tablaCons = {
            table: {
                unbreakable: true,
                widths: ['13%', '6%', '6%', '6%', '6%', '6%', '7%', '7%', '7%', '7%', '7%', '7%', '7%', '9%'],
                body: [

                    [
                        { text: titulo, alignment: 'center', rowSpan: 2, bold: false, fillColor: color, fontSize: 9 },
                        { text: subTitulo, alignment: 'center', colSpan: 13, bold: false, fillColor: color },
                        '', '', '', '', '', '', '', '', '', '', '', '',
                    ],
                    [
                        '',
                        { text: 'Ene', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Feb', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Mar', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Abr', alignment: 'center', bold: false, fillColor: color },
                        { text: 'May', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Jun', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Jul', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Ago', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Sep', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Oct', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Nov', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Dic', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Total', alignment: 'center', bold: false, fillColor: color },
                    ]
                ],
            }
        }
        if (consumo != undefined && consumo.length > 0) {
            consumo.forEach(c => {
                let row = [
                    { text: c.medidor, alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.enero), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.febrero), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.marzo), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.abril), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.mayo), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.junio), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.julio), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.agosto), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.septiembre), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.octubre), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.noviembre), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.diciembre), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.total), alignment: 'center', fontSize: 9 },
                ]
                tablaCons.table.body.push(row);
            })
        } else {
            let row = [
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
            ]
            tablaCons.table.body.push(row);
        }
        return tablaCons;
    }

    tablaResidSoli(consumo, color) {
        let titulo = 'Tipo';
        let subTitulo = 'Generación mensual de residuos (kg)';

        let tablaCons = {
            table: {
                widths: ['14%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '14%'],
                body: [

                    [
                        { text: titulo, alignment: 'center', rowSpan: 2, bold: false, fillColor: color, fontSize: 9 },
                        { text: subTitulo, alignment: 'center', colSpan: 13, bold: false, fillColor: color },
                        '', '', '', '', '', '', '', '', '', '', '', '',
                    ],
                    [
                        '',
                        { text: 'Ene', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Feb', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Mar', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Abr', alignment: 'center', bold: false, fillColor: color },
                        { text: 'May', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Jun', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Jul', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Ago', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Sep', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Oct', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Nov', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Dic', alignment: 'center', bold: false, fillColor: color },
                        { text: 'Total', alignment: 'center', bold: false, fillColor: color },
                    ]
                ],
            }
        }
        if (consumo != undefined && consumo.length > 0) {
            consumo.forEach(c => {
                let row = [
                    { text: c.medidor, alignment: 'center', fillColor: color, fontSize: 9 },
                    { text: this.numberFormat(c.enero), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.febrero), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.marzo), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.abril), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.mayo), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.junio), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.julio), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.agosto), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.septiembre), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.octubre), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.noviembre), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.diciembre), alignment: 'center', fontSize: 9 },
                    { text: this.numberFormat(c.total), alignment: 'center', fontSize: 9 },
                ]
                tablaCons.table.body.push(row);
            })
        } else {
            let row = [
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
            ]
            tablaCons.table.body.push(row);
        }
        return tablaCons;
    }


    consumoTotal(consumo) {
        let total = 0;
        let values = Object.values(consumo);
        values.forEach(val => {
            if (Number.isFinite(val)) {
                total += val;
            }
        })

        return this.numberFormat(total);
    }

    comparacionConsumoTable(consumo, indicador, anio, anioAnterior, instalacion) {
        let titulo;
        switch (indicador) {
            case this.constantes.indicadorCombustibles:
                titulo = 'Tipo de Combustible';
                break;
        }
        let tablaCompraracion = {
            table: {
                widths: ['15%', '13%', '13%', '13%', '20%', '17%'],
                body: [
                    [
                        { text: 'DATOS ESTRICTAMENTE REQUERIDOS', alignment: 'center', bold: true, colSpan: 5, fillColor: '#8db3e2' },
                        '', '', '', '',
                        { text: 'EQUIVALENCIAS', alignment: 'center', bold: true, fillColor: '#c2d69b' }
                    ],
                    [
                        { text: titulo, alignment: 'center', bold: true, rowSpan: 2, fillColor: '#8db3e2' },
                        { text: 'Consumo Total (litros)', alignment: 'center', bold: true, colSpan: 2, fillColor: '#8db3e2' },
                        '',
                        { text: 'Ahorro (en litros) (C)', alignment: 'center', bold: true, colSpan: 2, fillColor: '#8db3e2' },
                        '',
                        { text: 'Colones ahorrados (C) * Precio por el combustible', alignment: 'center', rowSpan: 2, fillColor: '#c2d69b' }
                    ],
                    [
                        '',
                        { text: 'Anterior ' + anioAnterior + ' (A)', alignment: 'center', bold: true, fillColor: '#fac090' },
                        { text: 'Actual ' + anio + ' (B)', alignment: 'center', bold: true, fillColor: '#81b9fc' },
                        { text: 'Cifra (números) ', alignment: 'center', fillColor: '#8db3e2' },
                        { text: 'Escriba en letras la reducción ', alignment: 'center', fillColor: '#8db3e2' },
                        ''
                    ]
                ]
            }
        }
        if (instalacion.tieneCombustibles) {
            consumo.forEach(c => {
                let row = [
                    { text: c.nombre, alignment: 'center' },
                    { text: this.numberFormat(c.consumoAnterior), alignment: 'center' },
                    { text: this.numberFormat(c.consumoActual), alignment: 'center' },
                    { text: this.numberFormat(c.ahorroConsumo), alignment: 'center' },
                    { text: this.numberFormat(c.consumoWords), alignment: 'center' },
                    { text: this.numberFormat(c.ahorroColones), alignment: 'center' },
                ]
                tablaCompraracion.table.body.push(row);
            })
        } else {
            let row = [
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' }
            ]
            tablaCompraracion.table.body.push(row);
        }
        return tablaCompraracion;
    }

    reduccionConsumo(datos, indicador, anio, anioAnterior) {
        let tablaReduCons = {
            table: {
                widths: [],
                body: []
            }
        }
        switch (indicador) {
            case this.constantes.indicadorAgua:
                tablaReduCons.table.widths = ['18%', '10%', '10%', '10%', '10%', '14%', '14%', '14%']
                let encabezado = [
                    [
                        { text: 'DATOS ESTRICTAMENTE REQUERIDOS', alignment: 'center', bold: true, colSpan: 4, fillColor: '#8db3e2' },
                        '', '', '',
                        { text: 'EQUIVALENCIAS', alignment: 'center', bold: true, colSpan: 4, rowSpan: 2, fillColor: '#c2d69b' },
                        '', '', ''
                    ],
                    [
                        { text: 'Consumo Total (m3)', alignment: 'center', bold: true, colSpan: 4, fillColor: '#8db3e2' },
                        '', '', '', ''
                    ],
                    [
                        { text: 'Tipo Proceso', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Anterior ' + anioAnterior + ' (A)', alignment: 'center', bold: true, fillColor: '#fac090' },
                        { text: 'Actual ' + anio + ' (B)', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Ahorro (En m3) (C)', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Colones', alignment: 'center', bold: true, fillColor: '#c2d69b' },
                        { text: 'Bidones para agua de 20 L', alignment: 'center', bold: true, fillColor: '#c2d69b' },
                        { text: 'Tanques de almacenamiento de agua de 750 L', alignment: 'center', bold: true, fillColor: '#c2d69b' },
                        { text: 'Piscinas Olímpicas', alignment: 'center', bold: true, fillColor: '#c2d69b' },
                    ]
                ]
                tablaReduCons.table.body = encabezado;
                if (datos != undefined && datos.length > 0) {
                    datos.forEach(reduc => {
                        let row = [
                            { text: reduc.nombre, alignment: 'center' },
                            { text: this.numberFormat(reduc.consumoAnterior), alignment: 'center' },
                            { text: this.numberFormat(reduc.consumoActual), alignment: 'center' },
                            { text: this.numberFormat(reduc.ahorroConsumo), alignment: 'center' },
                            { text: this.numberFormat(reduc.ahorroColones), alignment: 'center' },
                            { text: this.numberFormat(reduc.bidones), alignment: 'center' },
                            { text: this.numberFormat(reduc.tanquesAgua), alignment: 'center' },
                            { text: this.numberFormat(reduc.piscinasOlimp), alignment: 'center' },
                        ]
                        tablaReduCons.table.body.push(row);
                    })
                } else {
                    let row = [
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                    ]
                    tablaReduCons.table.body.push(row);
                }
                break;
            case this.constantes.indicadorEnergia:
                tablaReduCons.table.widths = ['15%', '14%', '14%', '14%', '14%', '14%', '15%']
                let encabezadoEner = [
                    [
                        { text: 'DATOS ESTRICTAMENTE REQUERIDOS', alignment: 'center', bold: true, colSpan: 4, fillColor: '#8db3e2' },
                        '', '', '',
                        { text: 'EQUIVALENCIAS', alignment: 'center', bold: true, colSpan: 3, rowSpan: 2, fillColor: '#c2d69b' },
                        '', ''
                    ],
                    [
                        { text: 'Consumo Total (m3)', alignment: 'center', bold: true, colSpan: 4, fillColor: '#8db3e2' },
                        '', '', '', ''
                    ],
                    [
                        { text: 'Instalación', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Anterior ' + anioAnterior + ' (A)', alignment: 'center', bold: true, fillColor: '#fac090' },
                        { text: 'Actual ' + anio + ' (B)', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Ahorro (En kW/h) (C)', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Colones', alignment: 'center', bold: true, fillColor: '#c2d69b' },
                        { text: 'Hogares Abastecidos', alignment: 'center', bold: true, fillColor: '#c2d69b' },
                        { text: 'Ton CO2 eq evitadas', alignment: 'center', bold: true, fillColor: '#c2d69b' },
                    ]
                ]
                tablaReduCons.table.body = encabezadoEner;
                if (datos != undefined && datos.length > 0) {
                    datos.forEach(reduc => {
                        let rowEner = [
                            { text: reduc.nombre, alignment: 'center' },
                            { text: this.numberFormat(reduc.consumoAnterior), alignment: 'center' },
                            { text: this.numberFormat(reduc.consumoActual), alignment: 'center' },
                            { text: this.numberFormat(reduc.ahorroConsumo), alignment: 'center' },
                            { text: this.numberFormat(reduc.ahorroColones), alignment: 'center' },
                            { text: this.numberFormat(reduc.hogaresAbas), alignment: 'center' },
                            { text: this.numberFormat(reduc.tonC02), alignment: 'center' },
                        ]
                        tablaReduCons.table.body.push(rowEner);
                    })
                } else {
                    let row = [
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                    ]
                    tablaReduCons.table.body.push(row);
                }
                break;
            case this.constantes.indicadorResiduos:
                tablaReduCons.table.widths = ['10%', '20%', '20%', '20%', '15%', '15%']
                let encabezadoPapel = [
                    [
                        { text: 'DATOS ESTRICTAMENTE REQUERIDOS', alignment: 'center', bold: true, colSpan: 4, fillColor: '#8db3e2' },
                        '', '', '',
                        { text: 'EQUIVALENCIAS', alignment: 'center', bold: true, colSpan: 2, rowSpan: 2, fillColor: '#c2d69b' },
                        ''
                    ],
                    [
                        { text: 'Consumo Total (Resmas)', alignment: 'center', bold: true, colSpan: 4, fillColor: '#8db3e2' },
                        '', '', '', ''
                    ],
                    [
                        { text: 'Material', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Anterior ' + anioAnterior + ' (A)', alignment: 'center', bold: true, fillColor: '#fac090' },
                        { text: 'Actual ' + anio + ' (B)', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Ahorro', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Árboles', alignment: 'center', bold: true, fillColor: '#c2d69b' },
                        { text: 'Dinero', alignment: 'center', bold: true, fillColor: '#c2d69b' },
                    ]
                ]
                tablaReduCons.table.body = encabezadoPapel;
                if (datos != undefined && datos.length > 0) {
                    datos.forEach(reduc => {
                        let rowPapel = [
                            { text: reduc.nombre, alignment: 'center' },
                            { text: this.numberFormat(reduc.consumoAnterior), alignment: 'center' },
                            { text: this.numberFormat(reduc.consumoActual), alignment: 'center' },
                            { text: this.numberFormat(reduc.ahorroConsumo), alignment: 'center' },
                            { text: this.numberFormat(reduc.arboles), alignment: 'center' },
                            { text: this.numberFormat(reduc.ahorroColones), alignment: 'center' },
                        ]
                        tablaReduCons.table.body.push(rowPapel);
                    })
                } else {
                    let row = [
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                    ]
                    tablaReduCons.table.body.push(row);
                }
                break;
        }
        return tablaReduCons;
    }

    reduccionConsumo2022(datos, indicador, anio, anioAnterior) {
        let tablaReduCons = {
            table: {
                dontBreakRows: true,
                widths: [],
                body: []
            }
        }
        switch (indicador) {
            case this.constantes.indicadorAgua:
                tablaReduCons.table.widths = ['14%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%']
                let encabezado = [
                    [
                        { text: 'DATOS ESTRICTAMENTE REQUERIDOS', alignment: 'center', bold: true, colSpan: 5, fillColor: '#8db3e2' },
                        '', '', '', '',
                        { text: 'EQUIVALENCIAS', alignment: 'center', bold: true, colSpan: 4, rowSpan: 2, fillColor: '#c2d69b' },
                        '', '', ''
                    ],
                    [
                        { text: 'Uso', alignment: 'center', rowSpan: 2, bold: true, fillColor: '#8db3e2' },
                        { text: 'Consumo Total (m3)', alignment: 'center', bold: true, colSpan: 2, fillColor: '#8db3e2' },
                        '',
                        { text: 'Ahorro (En m3) (C)', alignment: 'center', bold: true, colSpan: 2, fillColor: '#8db3e2' },
                        '',
                    ],
                    [
                        { text: '', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Anterior ' + anioAnterior + ' (A)', alignment: 'center', fillColor: '#fac090' },
                        { text: 'Actual ' + anio + ' (B)', alignment: 'center', fillColor: '#81b9fc' },
                        { text: 'Cifra (números) ', alignment: 'center', fillColor: '#8db3e2' },
                        { text: 'Escriba en letras la reducción ', alignment: 'center', fillColor: '#8db3e2' },
                        { text: 'Colones', alignment: 'center', bold: true, fillColor: '#c2d69b' },
                        { text: 'Bidones para agua de 20 L', alignment: 'center', bold: true, fillColor: '#c2d69b' },
                        { text: 'Tanques de almacenamiento de agua de 750 L', alignment: 'center', bold: true, fillColor: '#c2d69b' },
                        { text: 'Piscinas Olímpicas', alignment: 'center', bold: true, fillColor: '#c2d69b' },
                    ]
                ]
                tablaReduCons.table.body = encabezado;
                if (datos != undefined && datos.length > 0) {
                    datos.forEach(reduc => {
                        let row = [
                            { text: reduc.nombre, alignment: 'center' },
                            { text: this.numberFormat(reduc.consumoAnterior), alignment: 'center' },
                            { text: this.numberFormat(reduc.consumoActual), alignment: 'center' },
                            { text: this.numberFormat(reduc.ahorroConsumo), alignment: 'center' },
                            { text: this.numberFormat(reduc.consumoWords), alignment: 'center' },
                            { text: this.numberFormat(reduc.ahorroColones), alignment: 'center' },
                            { text: this.numberFormat(reduc.bidones), alignment: 'center' },
                            { text: this.numberFormat(reduc.tanquesAgua), alignment: 'center' },
                            { text: this.numberFormat(reduc.piscinasOlimp), alignment: 'center' },
                        ]
                        tablaReduCons.table.body.push(row);
                    })
                } else {
                    let row = [
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                    ]
                    tablaReduCons.table.body.push(row);
                }
                break;
            case this.constantes.indicadorEnergia:
                tablaReduCons.table.widths = ['15%', '14%', '14%', '14%', '14%', '14%', '15%']
                let encabezadoEner = [
                    [
                        { text: 'DATOS ESTRICTAMENTE REQUERIDOS', alignment: 'center', bold: true, colSpan: 5, fillColor: '#8db3e2' },
                        '', '', '', '',
                        { text: 'EQUIVALENCIAS', alignment: 'center', bold: true, colSpan: 2, rowSpan: 2, fillColor: '#c2d69b' },
                        ''
                    ],
                    [
                        { text: 'Medidor', alignment: 'center', rowSpan: 2, bold: true, fillColor: '#8db3e2' },
                        { text: 'Consumo Total (kW/h)', alignment: 'center', bold: true, colSpan: 2, fillColor: '#8db3e2' },
                        '',
                        { text: 'Ahorro (en kW/h)', alignment: 'center', bold: true, colSpan: 2, fillColor: '#8db3e2' },
                        '',
                    ],
                    [
                        { text: '', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Anterior ' + anioAnterior + ' (A)', alignment: 'center', fillColor: '#fac090' },
                        { text: 'Actual ' + anio + ' (B)', alignment: 'center', fillColor: '#8db3e2' },
                        { text: 'Cifra (números) ', alignment: 'center', fillColor: '#8db3e2' },
                        { text: 'Escriba en letras la reducción ', alignment: 'center', fillColor: '#8db3e2' },
                        { text: 'Colones', alignment: 'center', bold: true, fillColor: '#c2d69b' },
                        { text: 'Ton CO2 eq evitadas', alignment: 'center', bold: true, fillColor: '#c2d69b' },
                    ]
                ]
                tablaReduCons.table.body = encabezadoEner;
                if (datos != undefined && datos.length > 0) {
                    datos.forEach(reduc => {
                        let rowEner = [
                            { text: reduc.nombre, alignment: 'center' },
                            { text: this.numberFormat(reduc.consumoAnterior), alignment: 'center' },
                            { text: this.numberFormat(reduc.consumoActual), alignment: 'center' },
                            { text: this.numberFormat(reduc.ahorroConsumo), alignment: 'center' },
                            { text: this.numberFormat(reduc.consumoWords), alignment: 'center' },
                            { text: this.numberFormat(reduc.ahorroColones), alignment: 'center' },
                            { text: this.numberFormat(reduc.tonC02), alignment: 'center' },
                        ]
                        tablaReduCons.table.body.push(rowEner);
                    })
                } else {
                    let row = [
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                    ]
                    tablaReduCons.table.body.push(row);
                }
                break;
            case this.constantes.indicadorResiduos:
                tablaReduCons.table.widths = ['10%', '15%', '15%', '20%', '20%', '10%', '10%']
                let encabezadoPapel = [
                    [
                        { text: 'DATOS ESTRICTAMENTE REQUERIDOS', alignment: 'center', bold: true, colSpan: 5, fillColor: '#8db3e2' },
                        '', '', '', '',
                        { text: 'EQUIVALENCIAS', alignment: 'center', bold: true, colSpan: 2, rowSpan: 2, fillColor: '#c2d69b' },
                        '',
                    ],
                    [
                        { text: 'Material', alignment: 'center', rowSpan: 2, bold: true, fillColor: '#8db3e2' },
                        { text: 'Consumo Total (Resmas)', alignment: 'center', bold: true, colSpan: 2, fillColor: '#8db3e2' },
                        '',
                        { text: 'Ahorro (en resmas)', alignment: 'center', bold: true, colSpan: 2, fillColor: '#8db3e2' },
                        '',
                    ],
                    [
                        { text: '', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Anterior ' + anioAnterior + ' (A)', alignment: 'center', fillColor: '#fac090' },
                        { text: 'Actual ' + anio + ' (B)', alignment: 'center', fillColor: '#8db3e2' },
                        { text: 'Cifra (números) ', alignment: 'center', fillColor: '#8db3e2' },
                        { text: 'Escriba en letras la reducción ', alignment: 'center', fillColor: '#8db3e2' },
                        { text: 'Árboles', alignment: 'center', bold: true, fillColor: '#c2d69b' },
                        { text: 'Dinero', alignment: 'center', bold: true, fillColor: '#c2d69b' },
                    ]
                ]
                tablaReduCons.table.body = encabezadoPapel;
                if (datos != undefined && datos.length > 0) {
                    datos.forEach(reduc => {
                        let rowPapel = [
                            { text: 'Resmas', alignment: 'center' },
                            { text: this.numberFormat(reduc.consumoAnterior), alignment: 'center' },
                            { text: this.numberFormat(reduc.consumoActual), alignment: 'center' },
                            { text: this.numberFormat(reduc.ahorroConsumo), alignment: 'center' },
                            { text: this.numberFormat(reduc.consumoWords), alignment: 'center' },
                            { text: this.numberFormat(reduc.arboles), alignment: 'center' },
                            { text: this.numberFormat(reduc.ahorroColones), alignment: 'center' },
                        ]
                        tablaReduCons.table.body.push(rowPapel);
                    })
                } else {
                    let row = [
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                        { text: 'N/A', alignment: 'center' },
                    ]
                    tablaReduCons.table.body.push(row);
                }
                break;
        }
        return tablaReduCons;
    }


    comparResidNoVa(residuos, valorizable, anio, anioAnterior, titulo) {
        let tablaReduCons = {
            table: {
                widths: [],
                body: []
            }
        }
        tablaReduCons.table.widths = ['20%', '15%', '15%', '10%', '20%', '20%']
        let encabezadoPapel = [
            [
                { text: 'DATOS ESTRICTAMENTE REQUERIDOS', alignment: 'center', bold: true, colSpan: 5, fillColor: '#8db3e2' },
                '', '', '', '',
                { text: 'EQUIVALENCIAS', alignment: 'center', bold: true, rowSpan: 2, fillColor: '#c2d69b' },
            ],
            [
                { text: titulo, alignment: 'center', rowSpan: 2, bold: true, fillColor: '#8db3e2' },
                { text: 'Generación Total (Kg)', alignment: 'center', bold: true, colSpan: 2, fillColor: '#8db3e2' },
                '',
                { text: 'Reducción', alignment: 'center', bold: true, colSpan: 2, fillColor: '#8db3e2' },
                '',
            ],
            [
                { text: '', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                { text: 'Anterior ' + anioAnterior + ' (A)', alignment: 'center', fillColor: '#fac090' },
                { text: 'Actual ' + anio + ' (B)', alignment: 'center', fillColor: '#8db3e2' },
                { text: 'Cifra (números) ', alignment: 'center', fillColor: '#8db3e2' },
                { text: 'Escriba en letras la reducción ', alignment: 'center', fillColor: '#8db3e2' },
                { text: 'Contenedor industrial', alignment: 'center', bold: true, fillColor: '#c2d69b' },
            ]
        ]
        tablaReduCons.table.body = encabezadoPapel;
        if (residuos != undefined && residuos.length > 0) {
            residuos.forEach(res => {
                let rowPapel = [
                    { text: res.nombre, alignment: 'center' },
                    { text: this.numberFormat(res.consumoAnterior), alignment: 'center' },
                    { text: this.numberFormat(res.consumoActual), alignment: 'center' },
                    { text: this.numberFormat(res.ahorroConsumo), alignment: 'center' },
                    { text: this.numberFormat(res.consumoWords), alignment: 'center' },
                    { text: this.numberFormat(res.contenedorIndustrial), alignment: 'center' },
                ]
                tablaReduCons.table.body.push(rowPapel);
            })
        } else {
            let row = [
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
            ]
            tablaReduCons.table.body.push(row);
        }

        return tablaReduCons;
    }


    comparResid(residuos, valorizable, anio, anioAnterior, titulo) {
        let tablaReduCons = {
            table: {
                widths: [],
                body: []
            }
        }
        tablaReduCons.table.widths = ['20%', '15%', '15%', '10%', '20%', '20%']
        let encabezadoPapel = [
            [
                { text: 'Tipo de Residuo', alignment: 'center', rowSpan: 2, bold: true, fillColor: '#8db3e2' },
                { text: 'Generación Total (Kg)', alignment: 'center', bold: true, colSpan: 2, fillColor: '#8db3e2' },
                '',
                { text: 'Reducción', alignment: 'center', bold: true, colSpan: 2, fillColor: '#8db3e2' },
                '',
                { text: 'Disposición final', alignment: 'center', bold: true, rowSpan: 2, fillColor: '#8db3e2' },
            ],
            [
                { text: 'Tipo de Residuo', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                { text: 'Anterior ' + anioAnterior + ' (A)', alignment: 'center', fillColor: '#fac090' },
                { text: 'Actual ' + anio + ' (B)', alignment: 'center', fillColor: '#8db3e2' },
                { text: 'Cifra (números) ', alignment: 'center', fillColor: '#8db3e2' },
                { text: 'Escriba en letras la reducción ', alignment: 'center', fillColor: '#8db3e2' },
                { text: 'Dinero', alignment: 'center', bold: true, fillColor: '#c2d69b' },
            ]
        ]
        tablaReduCons.table.body = encabezadoPapel;
        if (residuos != undefined && residuos.length > 0) {
            residuos.forEach(res => {
                let disp = res.disposicion != null ? res.disposicion : 'N/A'
                let gest = res.gestor != null ? res.gestor : 'N/A'
                let rowPapel = [
                    { text: res.nombre, alignment: 'center' },
                    { text: this.numberFormat(res.consumoAnterior), alignment: 'center' },
                    { text: this.numberFormat(res.consumoActual), alignment: 'center' },
                    { text: this.numberFormat(res.ahorroConsumo), alignment: 'center' },
                    { text: this.numberFormat(res.consumoWords), alignment: 'center' },
                    { text: disp + ' \nGestor: ' + gest, alignment: 'center' },
                ]
                tablaReduCons.table.body.push(rowPapel);
            })
        } else {
            let row = [
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'center' },
            ]
            tablaReduCons.table.body.push(row);
        }

        return tablaReduCons;
    }

    tablaJustificaciones(justificaciones, indicador) {
        let justificacionTable = {
            table: {
                widths: ['5%', '95%'],
                body: [
                    [
                        { text: '#', alignment: 'center' },
                        { text: 'Justificación', alignment: 'center' }
                    ]
                ]
            }
        }
        if (justificaciones != undefined && justificaciones.length > 0) {
            justificaciones.forEach((justificacion, i) => {
                let row = [
                    { text: i + 1, alignment: 'center' },
                    { text: justificacion, alignment: 'left' }
                ]
                justificacionTable.table.body.push(row);
            })
        } else {
            let row = [
                { text: 'N/A', alignment: 'center' },
                { text: 'N/A', alignment: 'left' }
            ]
            justificacionTable.table.body.push(row);
        }
        return justificacionTable;
    }

    educacionAmbiental(accionesEducacion, indicador) {
        let educTable = {
            table: {
                widths: ['20%', '20%', '20%', '20%', '20%'],
                body: [
                    [
                        { text: 'DATOS ESTRICTAMENTE REQUERIDOS', bold: true, alignment: 'center', fillColor: '#8db3e2', colSpan: 5 },
                        '', '', '', ''
                    ],
                    [
                        { text: 'Tipo de actividades', alignment: 'center', fillColor: '#8db3e2', bold: true },
                        { text: 'Descripción de la actividad', alignment: 'center', fillColor: '#8db3e2', bold: true },
                        { text: 'Cantidad de actividades', alignment: 'center', fillColor: '#8db3e2', bold: true },
                        { text: 'Fecha de la actividad', alignment: 'center', fillColor: '#8db3e2', bold: true },
                        { text: 'Temas tratados en la actividad', alignment: 'center', fillColor: '#8db3e2', bold: true },
                    ]
                ]
            }
        }
        accionesEducacion.forEach(accion => {
            let row = [
                { text: accion.tipoActividad, alignment: 'center' },
                { text: accion.descripcion, alignment: 'center' },
                { text: accion.cantidadActividades, alignment: 'center' },
                { text: moment(accion.fechaActividad).format('DD/MM/YYYY'), alignment: 'center' },
                { text: accion.temasTratados, alignment: 'center' },
            ]
            educTable.table.body.push(row);
        })
        return educTable;
    }

    educacionAmbiental2022(accionesEducacion) {
        let eduData = [];
        accionesEducacion.forEach((accion, index) => {
            let numActividad = index + 1;
            let modalidad = accion.modalidad != null ? accion.modalidad.nombre : 'N/A';
            let participantes = accion.listaParticipante != null ? accion.listaParticipante : 'N/A';
            let educTable = {
                style: 'table',
                table: {
                    widths: ['50%', '50%'],
                    body: [
                    	[
                            { text: `Actividad N°${numActividad}`, alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                        ],
                    	[
                            { text: 'Nombre de la actividad', alignment: 'left', fillColor: '#8db3e2', bold: true },
                            { text: 'Fecha de la actividad', alignment: 'left', fillColor: '#8db3e2', bold: true }
                        ],
                        [
                            { text: accion.tipoActividad, alignment: 'center' },
                            { text: moment(accion.fechaActividad).format('DD/MM/YYYY'), alignment: 'center' }
                        ],
                        [
                            { text: 'Descripción y Temas tratados', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                        ],
                        [
                            { text: 'Descripción: ' + accion.descripcion + '\n Temas tratados: ' + accion.temasTratados, alignment: 'justify', colSpan: 2 }, ''
                        ],
                        [
                            { text: 'Público participante ', alignment: 'left', fillColor: '#8db3e2', bold: true },
                            { text: 'Modalidad de la actividad ', alignment: 'left', fillColor: '#8db3e2', bold: true }
                        ],
                        [
                            { text: participantes, alignment: 'center' },
                            { text: modalidad, alignment: 'center' }
                        ],
                        [
                            { text: 'Evidencias mínimas para presentar', alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                        ],
                        [
                            { text: 'Fotografías de la actividad', alignment: 'left', fillColor: '#8db3e2', bold: true },
                            { text: 'Listas de asistencia o Certificaciones de participación', alignment: 'left', fillColor: '#8db3e2', bold: true }
                        ],
                        [
                            { image: accion.docActividad, alignment: 'center', fit: [460, 380] },
                            { image: accion.docAsistencia, alignment: 'center', fit: [460, 380] }
                        ],
                        [
                            { text: 'Imágenes (específicas del tema tratado asociado a este parámetro) con el contenido de la presentación utilizada en la capacitación o charla', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                        ],
                    ],                    
                },
            }
            
            accion.docEvidencia.forEach(doc => {
               let row6 = [
                    { image: doc, alignment: 'center', fit: [460, 380], colSpan: 2 }, '',
               ]
               educTable.table.body.push(row6);
            })
            eduData.push(educTable);
        })

        return eduData;
    }

    tablaEstrellaTres22(promos) {
        let sale0 = false;
        let sale1 = false;
        let sale2 = false;
        let sale3 = false;
        let sale4 = false;
        let stop = false;

        let eduData = [];

        if(promos.length) {
            promos.forEach((p) => {
                let educTable = {
                    table: {
                        widths: ['50%', '50%'],
                        body: []
                    }
                }
                if (!stop) {

                    {
                        let rowEnc = [
                            { text: 'Actividad N°1', alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                        ]
                        if (!sale0) {
                            educTable.table.body.push(rowEnc);
                            sale0 = true;
                        }
                        else {
                            let rowEnc = [
                                { pageBreak: 'before', text: 'Actividad N°2', alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                            ]
                            educTable.table.body.push(rowEnc);
                            sale0 = false;
                            stop = true;
                        }
                    }
                    {
                        let rowEnc1 = [
                            { text: 'Nombre empresa o personas que se les promovió el PBAE', alignment: 'left', fillColor: '#8db3e2', bold: true },
                            { text: 'Fecha de la actividad', alignment: 'left', fillColor: '#8db3e2', bold: true }
                        ]
                        educTable.table.body.push(rowEnc1);
                        if (promos != undefined && promos.length > 0) {
                            let row1 = [
                                { text: p.interesado, alignment: 'center' },
                                { text: moment(p.fechaActividad).format('DD/MM/YYYY'), alignment: 'center' }
                            ]
                            if (!sale1) {
                                educTable.table.body.push(row1);
                                sale1 = true;
                            }
                        }
                        if (!sale1) {
                            let row1 = [
                                { text: 'N/A', alignment: 'center' },
                                { text: 'N/A', alignment: 'center' }
                            ]
                            educTable.table.body.push(row1);
                        }
                    }

                    {
                        let rowEnc3 = [
                            { text: 'La empresa que se le promovió el PBAE obtuvo galardón el año anterior en alguna de las Categorías del PBAE ', alignment: 'left', fillColor: '#8db3e2', bold: true },
                            { text: 'Relación con la organización ', alignment: 'left', fillColor: '#8db3e2', bold: true }
                        ]
                        educTable.table.body.push(rowEnc3);
                        if (promos != undefined && promos.length > 0) {
                            let row4 = [
                                { text: 'SI: ' + p.categoriaPbae, alignment: 'center' },
                                { text: p.listaParticipante, alignment: 'center' }
                            ]
                            if (!sale3) {
                                educTable.table.body.push(row4);
                                sale3 = true;
                            }
                        }
                        if (!sale3) {
                            let row4 = [
                                { text: 'NO', alignment: 'center' },
                                { text: 'N/A', alignment: 'center' }
                            ]
                            educTable.table.body.push(row4);
                        }
                    }
                    {
                        let rowEnc2 = [
                            { text: 'Descripción de la actividad realizada', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                        ]
                        educTable.table.body.push(rowEnc2);
                        if (promos != undefined && promos.length > 0) {
                            let row2 = [
                                { text: p.descripcionActividad, alignment: 'left', colSpan: 2 }, ''
                            ]
                            if (!sale2) {
                                educTable.table.body.push(row2);
                                sale2 = true;
                            }
                        }
                        if (!sale2) {
                            let row2 = [
                                { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                            ]
                            educTable.table.body.push(row2);
                        }
                    }
                    {
                        let rowEnc4 = [
                            { text: 'Evidencias', alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                        ]
                        educTable.table.body.push(rowEnc4);
                    }
                    {
                        let rowEnc5 = [
                            { text: 'Fotografías de las actividades', alignment: 'left', fillColor: '#8db3e2', bold: true },
                            { text: 'Listas de asistencia', alignment: 'left', fillColor: '#8db3e2', bold: true },
                        ]
                        educTable.table.body.push(rowEnc5);
                        if (promos != undefined && promos.length > 0) {
                            let row5 = [
                                { image: p.docActividad, alignment: 'center', fit: [460, 380] },
                                { image: p.docAsistencia, alignment: 'center', fit: [460, 380] },
                            ]
                            educTable.table.body.push(row5);
                        }
                        else {
                            let row5 = [
                                { text: 'N/A', alignment: 'center' },
                                { text: 'N/A', alignment: 'center' }
                            ]
                            educTable.table.body.push(row5);
                        }
                    }

                    {
                        let rowEnc6 = [
                            { text: 'Otras', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                        ]
                        educTable.table.body.push(rowEnc6);
                        if (promos != undefined && promos.length > 0 && promos != null) {
                            p.docEvidencia.forEach(doc => {
                                let row6 = [
                                    { image: doc, alignment: 'center', fit: [460, 380], colSpan: 2 }, '',
                                ]
                                educTable.table.body.push(row6);
                            })
                        }
                        else {
                            let row6 = [
                                { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                            ]
                            educTable.table.body.push(row6);
                        }

                    }

                    eduData.push(educTable);
                    sale0 = true;
                    sale1 = false;
                    sale2 = false;
                    sale3 = false;
                    sale4 = false;
                }
            })
        } else {
            let educTable = {
                table: {
                    widths: ['50%', '50%'],
                    body: [
                        [
                            { text: 'Actividad N°1', alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                        ],
                        [
                            { text: 'Nombre empresa o personas que se les promovió el PBAE', alignment: 'left', fillColor: '#8db3e2', bold: true },
                            { text: 'Fecha de la actividad', alignment: 'left', fillColor: '#8db3e2', bold: true }
                        ],
                        [
                            { text: 'N/A', alignment: 'center' },
                            { text: 'N/A', alignment: 'center' }
                        ],
                        [
                            { text: 'La empresa que se le promovió el PBAE obtuvo galardón el año anterior en alguna de las Categorías del PBAE ', alignment: 'left', fillColor: '#8db3e2', bold: true },
                            { text: 'Relación con la organización ', alignment: 'left', fillColor: '#8db3e2', bold: true }
                        ],
                        [
                            { text: 'N/A', alignment: 'center' },
                            { text: 'N/A', alignment: 'center' }
                        ],
                        [
                            { text: 'Descripción de la actividad realizada', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                        ],
                        [
                            { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                        ],
                        [
                            { text: 'Evidencias', alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                        ],
                        [
                            { text: 'Fotografías de las actividades', alignment: 'left', fillColor: '#8db3e2', bold: true },
                            { text: 'Listas de asistencia', alignment: 'left', fillColor: '#8db3e2', bold: true },
                        ],
                        [
                            { text: 'N/A', alignment: 'center' },
                            { text: 'N/A', alignment: 'center' }
                        ],
                        [
                            { text: 'Otras', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                        ],
                        [
                            { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                        ]
                    ]
                }
            }
            eduData.push(educTable);
        }

        return eduData;
    }

    tablaEstrellaCuatro22(promos) {
        let sale0 = false;
        let sale1 = false;
        let sale2 = false;
        let sale3 = false;
        let sale4 = false;
        let stop = false;

        let eduData = [];

        if(promos.length) {
            promos.forEach((p) => {
                let educTable = {
                    table: {
                        widths: ['50%', '50%'],
                        body: []
                    }
                }
                if (!stop) {

                    {
                        let rowEnc = [
                            { text: 'Actividad N°1', alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                        ]
                        if (!sale0) {
                            educTable.table.body.push(rowEnc);
                            sale0 = true;
                        }
                        else {
                            let rowEnc = [
                                { pageBreak: 'before', text: 'Actividad N°2', alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                            ]
                            educTable.table.body.push(rowEnc);
                            sale0 = false;
                            stop = true;
                        }
                    }
                    {
                        let rowEnc1 = [
                            { text: 'Nombre organización o personas que se les promovió el PBAE, y tipo de actividad económica (en caso de que aplique)', alignment: 'left', fillColor: '#8db3e2', bold: true },
                            { text: 'Fecha de la actividad', alignment: 'left', fillColor: '#8db3e2', bold: true }
                        ]
                        educTable.table.body.push(rowEnc1);
                        if (promos != undefined && promos.length > 0) {
                            let row1 = [
                                { text: p.interesado, alignment: 'center' },
                                { text: moment(p.fechaActividad).format('DD/MM/YYYY'), alignment: 'center' }
                            ]
                            if (!sale1) {
                                educTable.table.body.push(row1);
                                sale1 = true;
                            }
                        }
                        if (!sale1) {
                            let row1 = [
                                { text: 'N/A', alignment: 'center' },
                                { text: 'N/A', alignment: 'center' }
                            ]
                            educTable.table.body.push(row1);
                        }
                    }

                    {
                        let rowEnc3 = [
                            { text: 'La organización o personas que se le promovió el PBAE obtuvo galardón el año anterior en alguna de las Categorías del PBAE ', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                        ]
                        educTable.table.body.push(rowEnc3);
                        if (promos != undefined && promos.length > 0) {
                            let row4 = [
                                { text: 'SI: ' + p.categoriaPbae, alignment: 'center', colSpan: 2 }, ''
                            ]
                            if (!sale3) {
                                educTable.table.body.push(row4);
                                sale3 = true;
                            }
                        }
                        if (!sale3) {
                            let row4 = [
                                { text: 'NO', alignment: 'center', colSpan: 2 }, ''
                            ]
                            educTable.table.body.push(row4);
                        }
                    }
                    {
                        let rowEnc2 = [
                            { text: 'Descripción de la actividad realizada', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                        ]
                        educTable.table.body.push(rowEnc2);
                        if (promos != undefined && promos.length > 0) {
                            let row2 = [
                                { text: p.descripcionActividad, alignment: 'left', colSpan: 2 }, ''
                            ]
                            if (!sale2) {
                                educTable.table.body.push(row2);
                                sale2 = true;
                            }
                        }
                        if (!sale2) {
                            let row2 = [
                                { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                            ]
                            educTable.table.body.push(row2);
                        }
                    }
                    {
                        let rowEnc4 = [
                            { text: 'Evidencias', alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                        ]
                        educTable.table.body.push(rowEnc4);
                    }
                    {
                        let rowEnc5 = [
                            { text: 'Fotografías de las actividades', alignment: 'left', fillColor: '#8db3e2', bold: true },
                            { text: 'Listas de asistencia', alignment: 'left', fillColor: '#8db3e2', bold: true },
                        ]
                        educTable.table.body.push(rowEnc5);
                        if (promos != undefined && promos.length > 0) {
                            let row5 = [
                                { image: p.docActividad, alignment: 'center', fit: [460, 380] },
                                { image: p.docAsistencia, alignment: 'center', fit: [460, 380] },
                            ]
                            educTable.table.body.push(row5);
                        }
                        else {
                            let row5 = [
                                { text: 'N/A', alignment: 'center' },
                                { text: 'N/A', alignment: 'center' }
                            ]
                            educTable.table.body.push(row5);
                        }
                    }

                    {
                        let rowEnc6 = [
                            { text: 'Otras', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                        ]
                        educTable.table.body.push(rowEnc6);
                        if (promos != undefined && promos.length > 0 && promos != null) {
                            p.docEvidencia.forEach(doc => {
                                let row6 = [
                                    { image: doc, alignment: 'center', fit: [460, 380], colSpan: 2 }, '',
                                ]
                                educTable.table.body.push(row6);
                            })
                        }
                        else {
                            let row6 = [
                                { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                            ]
                            educTable.table.body.push(row6);
                        }

                    }

                    eduData.push(educTable);
                    sale0 = true;
                    sale1 = false;
                    sale2 = false;
                    sale3 = false;
                    sale4 = false;
                }
            })
        } else {
            let educTable = {
                table: {
                    widths: ['50%', '50%'],
                    body: [
                        [
                            { text: 'Actividad N°1', alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                        ],
                        [
                            { text: 'Nombre organización o personas que se les promovió el PBAE, y tipo de actividad económica (en caso de que aplique)', alignment: 'left', fillColor: '#8db3e2', bold: true },
                            { text: 'Fecha de la actividad', alignment: 'left', fillColor: '#8db3e2', bold: true }
                        ],
                        [
                            { text: 'N/A', alignment: 'center' },
                            { text: 'N/A', alignment: 'center' }
                        ],
                        [
                            { text: 'La organización o personas que se le promovió el PBAE obtuvo galardón el año anterior en alguna de las Categorías del PBAE ', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                        ],
                        [
                            { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                        ],
                        [
                            { text: 'Descripción de la actividad realizada', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                        ],
                        [
                            { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                        ],
                        [
                            { text: 'Evidencias', alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                        ],
                        [
                            { text: 'Fotografías de las actividades', alignment: 'left', fillColor: '#8db3e2', bold: true },
                            { text: 'Listas de asistencia', alignment: 'left', fillColor: '#8db3e2', bold: true },
                        ],
                        [
                            { text: 'N/A', alignment: 'center' },
                            { text: 'N/A', alignment: 'center' }
                        ],
                        [
                            { text: 'Otras', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                        ],
                        [
                            { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                        ]
                    ]
                }
            }
            eduData.push(educTable);
        }
        return eduData;
    }

        evidencia22(evidencias, titulo) {
            let evidenciasTable = {
                table: {
                    widths: ['100%'],
                    body: [],
                    layout: 'noBorders',
                }
            }
            {
                evidenciasTable.table.widths = ['100%']
                let rowEnc4 = [
                    { text: titulo, alignment: 'left', bold: true, fillColor: '#8db3e2' },
                ]
                evidenciasTable.table.body.push(rowEnc4);
                if (evidencias != undefined && evidencias.length > 0 && evidencias != null) {
                    let row = [];
                    evidencias.forEach((evidencia, index) => {
                        let image = {
                            image: evidencia,
                            alignment: 'center',
                            fit: [460, 380],
                        }
                        row.push(image);
                    })
                    //se dividen en arrays de 2 elementos
                    let index = 0;
                    let arrayLength = row.length;
                    for (index = 0; index < arrayLength; index += 1) {
                        let myRow = row.slice(index, index + 1);

                        if (myRow.length < 1) {
                            myRow.push('')
                        }
                        evidenciasTable.table.body.push(myRow);
                    }
                }
            }
            return evidenciasTable;
        }

        evidPlanAccion(evidencias) {
            let tablaEvidenciaE = {
                table: {
                    widths: [],
                    body: []
                }
            }
            {
                tablaEvidenciaE.table.widths = ['50%', '50%']
                let rowEnc4 = [
                    { text: 'Evidencias sobre la ejecución y/o seguimiento del plan de acción', alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                ]
                tablaEvidenciaE.table.body.push(rowEnc4);
            }
            {
                evidencias.forEach(datos => {
                    const data = new Map(Object.entries(datos));
                    data.forEach((docs, key) => {
                        let rowEnc5 = [
                            { text: `Medida de adaptación: ${key}`, alignment: 'left', fillColor: '#8db3e2', colSpan: 2, bold: true }, ''
                        ]
                        tablaEvidenciaE.table.body.push(rowEnc5);
                        if (docs != undefined && docs.length > 0 && docs != null) {
                            docs.forEach(doc => {
                                let row5 = [
                                    { image: doc, alignment: 'center', colSpan: 2, fit: [460, 380] }, ''
                                ]
                                tablaEvidenciaE.table.body.push(row5);
                            })
                        }
                        else {
                            let row5 = [
                                { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                            ]
                            tablaEvidenciaE.table.body.push(row5);
                        }
                    });
                });
            }
            return tablaEvidenciaE;
        }

        evideEcoinsEstrella22(evidencias, titulo) {
            let tablaEvidenciaE = {
                table: {
                    widths: [],
                    body: []
                }
            }
            {
                tablaEvidenciaE.table.widths = ['50%', '50%']
                let rowEnc6 = [
                    { text: titulo, alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                ]
                tablaEvidenciaE.table.body.push(rowEnc6);
                if (evidencias != undefined && evidencias.length > 0 && evidencias != null) {
                    evidencias.forEach((e) => {
                        let row6 = [
                            { image: e.docCertificacion, alignment: 'center', fit: [460, 380], colSpan: 2 },
                        ]
                        tablaEvidenciaE.table.body.push(row6);
                    })
                }
                else {
                    let row6 = [
                        { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                    ]
                    tablaEvidenciaE.table.body.push(row6);
                }
            }

            return tablaEvidenciaE;
        }


        evideTratamEstrella22(evidencias, titulo) {
            let sale5 = false;

            let tablaEvidenciaE = {
                table: {
                    widths: [],
                    body: []
                }
            }
            {
                tablaEvidenciaE.table.widths = ['50%', '50%']
                let rowEnc6 = [
                    { text: titulo, alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                ]
                tablaEvidenciaE.table.body.push(rowEnc6);
                if (evidencias != undefined && evidencias.length > 0 && evidencias != null) {
                    evidencias.forEach((e) => {
                        let row6 = [
                            { image: e.docOtros, alignment: 'center', fit: [460, 380], colSpan: 2 }, ''
                        ]
                        tablaEvidenciaE.table.body.push(row6);
                    })
                }
                else {
                    let row6 = [
                        { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                    ]
                    tablaEvidenciaE.table.body.push(row6);
                }
            }

            return tablaEvidenciaE;
        }

        evideDisposEstrella22(evidencias, titulo) {
            let sale5 = false;

            let tablaEvidenciaE = {
                table: {
                    widths: [],
                    body: []
                }
            }
            {
                tablaEvidenciaE.table.widths = ['50%', '50%']
                let rowEnc6 = [
                    { text: titulo, alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                ]
                tablaEvidenciaE.table.body.push(rowEnc6);
                if (evidencias != undefined && evidencias.length > 0 && evidencias != null) {
                    evidencias.forEach((e) => {
                        let row6 = [
                            { image: e.docEvidencia, alignment: 'center', fit: [460, 380], colSpan: 2 }, ''
                        ]
                        tablaEvidenciaE.table.body.push(row6);
                    })
                }
                else {
                    let row6 = [
                        { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                    ]
                    tablaEvidenciaE.table.body.push(row6);
                }
            }

            return tablaEvidenciaE;
        }


        evideHistEstrella22(evidencias, titulo, caso) {
            
            let tablaEvidenciaE = {
                table: {
                    widths: [],
                    body: []
                }
            }
            {
                tablaEvidenciaE.table.widths = ['50%', '50%']
                let rowEnc6 = [
                    { text: titulo, alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                ]
                tablaEvidenciaE.table.body.push(rowEnc6);
                if (evidencias != undefined && evidencias.length > 0 && evidencias != null) {
                    evidencias.forEach((e) => {
                        if (e.docCertificacionTipo == caso) {
                            let row6 = [
                                { image: e.docCertificacion, alignment: 'center', fit: [460, 380], colSpan: 2 }, ''
                            ]
                            tablaEvidenciaE.table.body.push(row6);
                        } else {
                            let row = [
                                { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                            ]
                            tablaEvidenciaE.table.body.push(row);
                        }                       
                    })
                } else {
                    let row6 = [
                        { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                    ]
                    tablaEvidenciaE.table.body.push(row6);
                }
            }

            return tablaEvidenciaE;
        }



        evideentrEstrella22(evidencias, titulo,caso) {

            let tablaEvidenciaE = {
                table: {
                    widths: [],
                    body: []
                }
            }
            {
                tablaEvidenciaE.table.widths = ['50%', '50%']
                let rowEnc6 = [
                    { text: titulo, alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                ]
                tablaEvidenciaE.table.body.push(rowEnc6);
                if (evidencias != undefined && evidencias.length > 0 && evidencias != null) {
                    evidencias.forEach((e) => {
                        if (e.docEvidenciaTipo == caso) {
                            let row6 = [
                                { image: e.docEvidencia, alignment: 'center', fit: [460, 380], colSpan: 2 }, ''
                            ]
                            tablaEvidenciaE.table.body.push(row6);
                        } else {
                            let row6 = [
                                { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                            ]
                            tablaEvidenciaE.table.body.push(row6);
                        }
                    })
                }
                else {
                    let row6 = [
                        { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                    ]
                    tablaEvidenciaE.table.body.push(row6);
                }
            }

            return tablaEvidenciaE;
        }

        evideevidEstrella22(evidencias, titulo,caso) {

            let tablaEvidenciaE = {
                table: {
                    widths: [],
                    body: []
                }
            }
            {
                tablaEvidenciaE.table.widths = ['50%', '50%']
                let rowEnc6 = [
                    { text: titulo, alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                ]
                tablaEvidenciaE.table.body.push(rowEnc6);
                if (evidencias != undefined && evidencias.length > 0 && evidencias != null) {
                    evidencias.forEach((e) => {
                        if (e.docOtrosTipo == caso) {
                            let row6 = [
                                { image: e.docOtros , alignment: 'center', fit: [460, 380], colSpan: 2 }, ''
                            ]
                            tablaEvidenciaE.table.body.push(row6);
                        } else {
                            let row6 = [
                                { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                            ]
                            tablaEvidenciaE.table.body.push(row6);
                        }
                    })
                }
                else {
                    let row6 = [
                        { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                    ]
                    tablaEvidenciaE.table.body.push(row6);
                }
            }

            return tablaEvidenciaE;
        }


        nombreimagen(evidencias, titulo) {

            let tablaEvidenciaE = {
                table: {
                    widths: [],
                    body: []
                }
            }
            {
                tablaEvidenciaE.table.widths = ['50%', '50%']
                let rowEnc6 = [
                    { text: titulo, alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                ]
                tablaEvidenciaE.table.body.push(rowEnc6);
                if (evidencias != undefined && evidencias.length > 0 && evidencias != null) {
                    evidencias.forEach((e, index) => {
                        let row6 = [
                            { text: e.listaNombreEvidencia, alignment: 'center', colSpan: 2 }, ''

                        ]
                        tablaEvidenciaE.table.body.push(row6);
                    })
                }
                else {
                    let row6 = [
                        { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                    ]
                    tablaEvidenciaE.table.body.push(row6);
                }
            }

            return tablaEvidenciaE;
        }

        nombreimagenotras(evidencias, titulo) {

            let tablaEvidenciaE = {
                table: {
                    widths: [],
                    body: []
                }
            }
            {
                tablaEvidenciaE.table.widths = ['50%', '50%']
                let rowEnc6 = [
                    { text: titulo, alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                ]
                tablaEvidenciaE.table.body.push(rowEnc6);
                if (evidencias != undefined && evidencias.length > 0 && evidencias != null) {
                    evidencias.forEach((e, index) => {
                        let row6 = [
                            { text: e.listaNombreEvidenciaevidenciaOtros, alignment: 'center', colSpan: 2 }, ''

                        ]
                        tablaEvidenciaE.table.body.push(row6);
                    })
                }
                else {
                    let row6 = [
                        { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                    ]
                    tablaEvidenciaE.table.body.push(row6);
                }
            }

            return tablaEvidenciaE;
        }

        nombreimageninic(evidencias, titulo) {

            let tablaEvidenciaE = {
                table: {
                    widths: [],
                    body: []
                }
            }
            {
                tablaEvidenciaE.table.widths = ['50%', '50%']
                let rowEnc6 = [
                    { text: titulo, alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                ]
                tablaEvidenciaE.table.body.push(rowEnc6);
                if (evidencias != undefined && evidencias.length > 0 && evidencias != null) {
                    evidencias.forEach((e, index) => {
                        let row6 = [
                            { text: e.listaNombreEvidenciacertificacion, alignment: 'center', colSpan: 2 }, ''

                        ]
                        tablaEvidenciaE.table.body.push(row6);
                    })
                }
                else {
                    let row6 = [
                        { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                    ]
                    tablaEvidenciaE.table.body.push(row6);
                }
            }

            return tablaEvidenciaE;
        }

        evideEstrella22(evidencias, titulo,) {

            let tablaEvidenciaE = {
                table: {
                    widths: [],
                    body: []
                }
            }
            {
                tablaEvidenciaE.table.widths = ['50%', '50%']
                let rowEnc6 = [
                    { text: titulo, alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                ]
                tablaEvidenciaE.table.body.push(rowEnc6);
                if (evidencias != undefined && evidencias.length > 0 && evidencias != null) {
                    evidencias.forEach((e, index) => {
                        let row6 = [
                            { image: e, alignment: 'center', fit: [460, 380], colSpan: 2 }, ''
                        ]
                        tablaEvidenciaE.table.body.push(row6);
                    })
                }
                else {
                    let row6 = [
                        { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                    ]
                    tablaEvidenciaE.table.body.push(row6);
                }
            }

            return tablaEvidenciaE;
        }

        
        evidEspecialesTraz22(evidencias) {
            let tablaEvidenciaE = {
                table: {
                    widths: [],
                    body: []
                }
            }
            {
                tablaEvidenciaE.table.widths = ['50%', '50%']
                let rowEnc4 = [
                    { text: 'Evidencia del registro interno de la entrega y copia del certificado del gestor autorizado', alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                ]
                tablaEvidenciaE.table.body.push(rowEnc4);
            }
            {
                evidencias.forEach(datos => {
                    const data = new Map(Object.entries(datos));
                    data.forEach((docs, key) => {
                        let rowEnc5 = [
                            { text: `Residuo: ${key}`, alignment: 'left', fillColor: '#8db3e2', colSpan: 2, bold: true }, ''
                        ]
                        tablaEvidenciaE.table.body.push(rowEnc5);
                        if (docs != undefined && docs.length > 0 && docs != null) {
                            docs.forEach(doc => {
                                let row5 = [
                                    { image: doc, alignment: 'center', colSpan: 2, fit: [460, 380] }, ''
                                ]
                                tablaEvidenciaE.table.body.push(row5);
                            })
                        }
                        else {
                            let row5 = [
                                { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                            ]
                            tablaEvidenciaE.table.body.push(row5);
                        }
                    });
                });
            }
            
            return tablaEvidenciaE;
        }

        evidCompSost22(evidencias, titulo) {
            let tablaEvidenciaE = {
                table: {
                    style: 'table',
                    widths: [],
                    body: []
                }
            }
            {
                tablaEvidenciaE.table.widths = ['50%', '50%']
                let rowEnc4 = [
                    { text: `${titulo}`, alignment: 'left', colSpan: 2, bold: true, fillColor: '#8db3e2' }, ''
                ]
                tablaEvidenciaE.table.body.push(rowEnc4);
            }
            {
                evidencias.forEach(datos => {
                    const data = new Map(Object.entries(datos));
                    data.forEach((docs, key) => {
                        let rowEnc5 = [
                            { text: `Producto: ${key}`, alignment: 'left', fillColor: '#8db3e2', colSpan: 2, bold: true }, ''
                        ]
                        tablaEvidenciaE.table.body.push(rowEnc5);
                        if (docs != undefined && docs.length > 0 && docs != null) {
                            docs.forEach(doc => {
                                let row5 = [
                                    { image: doc, alignment: 'center', colSpan: 2, fit: [460, 380] }, ''
                                ]
                                tablaEvidenciaE.table.body.push(row5);
                            })
                        }
                        else {
                            let row5 = [
                                { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                            ]
                            tablaEvidenciaE.table.body.push(row5);
                        }
                    });
                });
            }
            return tablaEvidenciaE;
        }

        tablaAccionesReduccion(accionesReduccion) {
            let tablaAccionesReduc = {
                table: {
                    widths: ['25%', '25%', '25%', '25%'],
                    body: [
                        [
                            { text: 'DATOS ESTRICTAMENTE REQUERIDOS', alignment: 'center', colSpan: 4, fillColor: '#8db3e2' },
                            '', '', ''
                        ],
                        [
                            { text: 'Tipo de actividades', alignment: 'center', fillColor: '#8db3e2' },
                            { text: 'Descripción de la actividad', alignment: 'center', fillColor: '#8db3e2' },
                            { text: 'Cantidad de actividades', alignment: 'center', fillColor: '#8db3e2' },
                            { text: 'Fecha de la actividad', alignment: 'center', fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            if(accionesReduccion != undefined && accionesReduccion != null) {
                accionesReduccion.forEach(accion => {
                    let row = [
                        { text: accion.tipoActividad, alignment: 'center' },
                        { text: accion.descripcion, alignment: 'center' },
                        { text: accion.cantidadActividades, alignment: 'center' },
                        { text: moment(accion.fechaActividad).format('DD/MM/YYYY'), alignment: 'center' },
                    ]
                    tablaAccionesReduc.table.body.push(row);
                })
            } else {
                let rowEmpty = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                ]
                tablaAccionesReduc.table.body.push(rowEmpty);
            }

            return tablaAccionesReduc;
        }

        tablaAccionesReduccion2022(accionesReduccion) {
            if(accionesReduccion != undefined && accionesReduccion != null && accionesReduccion.length) {
                let accionData = [];
                accionesReduccion.forEach((accion, index) => {
                    let numActividad = index + 1;
                    let tablaAccionesReduc = {
                        style: 'table',
                        table: {
                            widths: ['50%', '50%'],
                            body: [
                                [
                                    { text: `Acción N°${numActividad}`, alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                                ],
                                [
                                    { text: 'Nombre de la acción realizada', alignment: 'left', fillColor: '#8db3e2', bold: true },
                                    { text: 'Fecha de la acción', alignment: 'left', fillColor: '#8db3e2', bold: true },
                                ],
                                [
                                    { text: accion.tipoActividad, alignment: 'center', colSpan: 2 },
                                    { text: moment(accion.fechaActividad).format('DD/MM/YYYY'), alignment: 'center' },
                                ],
                                [
                                    { text: 'Descripción', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                                ],
                                [
                                    { text: accion.descripcion, alignment: 'justify', colSpan: 2 }, ''
                                ],
                                [
                                    { text: 'Evidencias', alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                                ],
                                [
                                    { text: 'Fotografías de las acciones', alignment: 'left', colSpan: 2, fillColor: '#8db3e2', bold: true }, ''
                                ]
                            ]
                        }
                    }
                    accion.docEvidenciaR.forEach(doc => {
                        let row = [
                            { image: doc, alignment: 'center', fit: [460, 380], colSpan: 2 }, ''
                        ]
                        tablaAccionesReduc.table.body.push(row);
                    })
                    accionData.push(tablaAccionesReduc)
                })
                return accionData;
            } else {
                return this.tablaAccionesReduccionVacia()
            }
        }

        tablatipostar(plantaTratamiento) {
            let sale1 = false;
            let sale2 = false;
            let nombre;

            let tablaSistema = {
                table: {
                    widths: [],
                    body: []
                }
            }
            {
                tablaSistema.table.widths = ['100%']
                let rowEnc1 = [
                    { text: 'Nombre del STAR utilizado en la Sede', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 1 },
                ]
                tablaSistema.table.body.push(rowEnc1);
                if (plantaTratamiento != undefined && plantaTratamiento != null) {
                    if (plantaTratamiento.sistema === 1) {
                        nombre = 'Planta de Tratamiento';
                    }
                    if (plantaTratamiento.sistema === 2) {
                        nombre = 'Tanque séptico y drenaje';
                    }
                    if (plantaTratamiento.sistema === 3) {
                        nombre = 'Alcantarillado sanitario';
                    }
                    let row1 = [
                        { text: nombre, alignment: 'center', colSpan: 1 },
                    ]
                    if (!sale1) {
                        tablaSistema.table.body.push(row1);
                        sale1 = true;
                    }
                }
                if (!sale1) {
                    let row1 = [
                        { text: 'N/A', alignment: 'center', colSpan: 1 },
                    ]
                    tablaSistema.table.body.push(row1);
                }
            }
            {
                tablaSistema.table.widths = ['100%']
                let rowEnc2 = [
                    { text: 'Describa el Sistema de tratamiento de aguas', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 1 },
                ]
                tablaSistema.table.body.push(rowEnc2);

                if (plantaTratamiento != undefined && plantaTratamiento != null) {
                    let row2 = [
                        { text: plantaTratamiento.descripcion, alignment: 'center', colSpan: 1 },
                    ]
                    if (!sale2) {
                        tablaSistema.table.body.push(row2);
                        sale2 = true;
                    }
                }
                if (!sale2) {
                    let row2 = [
                        { text: 'N/A', alignment: 'center', colSpan: 1 },
                    ]
                    tablaSistema.table.body.push(row2);
                }
            }
            return tablaSistema;
        }

        evidcroquis(evidencia) {
            let sale3 = false;
            let tablaSistema = {
                table: {
                    widths: [],
                    body: []
                }
            }
            {
                tablaSistema.table.widths = ['100%']
                let rowEnc3 = [
                    { text: 'Croquis de la ubicación del STAR', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 1 },
                ]
                tablaSistema.table.body.push(rowEnc3);

                if (evidencia != null && evidencia != undefined) {
                    let row3 = [
                        { image: evidencia, alignment: 'center', fit: [460, 380] },
                    ]
                    if (!sale3) {
                        tablaSistema.table.body.push(row3);
                        sale3 = true;
                    }
                }
                if (!sale3) {
                    let row3 = [
                        { text: 'N/A', alignment: 'center', colSpan: 1 },
                    ]
                    tablaSistema.table.body.push(row3);
                }
            }
            return tablaSistema;
        }

        permisoVertido(evidencia) {
            let tablaSistema = {
                table: {
                    widths: [],
                    body: []
                }
            }
            {
                tablaSistema.table.widths = ['100%']
                let rowEnc3 = [
                    { text: 'Permiso para vertido de aguas residuales en un cuerpo de agua:', alignment: 'left', fillColor: '#8db3e2', bold: true },

                ]
                tablaSistema.table.body.push(rowEnc3);

                if (evidencia != null && evidencia != undefined && evidencia.length > 0) {
                    evidencia.forEach(doc => {
                        let row3 = [
                            { image: doc, alignment: 'center', fit: [460, 380] },
                        ]
                        tablaSistema.table.body.push(row3);
                    })
                } else {
                    let row3 = [
                        { text: 'N/A', alignment: 'center' },
                    ]
                    tablaSistema.table.body.push(row3);
                }
            }
            return tablaSistema;
        }

        reporteOperacional(evidencia) {
            let tablaSistema = {
                table: {
                    widths: [],
                    body: []
                }
            }
            {
                tablaSistema.table.widths = ['100%']
                let rowEnc3 = [
                    { text: 'Reporte operacional del año de participación:', alignment: 'left', fillColor: '#8db3e2', bold: true },

                ]
                tablaSistema.table.body.push(rowEnc3);

                if (evidencia != null && evidencia != undefined && evidencia.length > 0) {
                    evidencia.forEach(doc => {
                        let row3 = [
                            { image: doc, alignment: 'center', fit: [460, 380] },
                        ]
                        tablaSistema.table.body.push(row3);
                    })
                }
                else {
                    let row3 = [
                        { text: 'N/A', alignment: 'center' },
                    ]
                    tablaSistema.table.body.push(row3);
                }
            }
            return tablaSistema;
        }


        reporteDatos(evidencia) {
            let tablaSistema = {
                table: {
                    widths: [],
                    body: []
                }
            }
            {
                tablaSistema.table.widths = ['100%']
                let rowEnc3 = [
                    { text: 'Reporte de datos de manejo integral de lodos y biosólidos', alignment: 'left', fillColor: '#8db3e2', bold: true },

                ]
                tablaSistema.table.body.push(rowEnc3);

                if (evidencia != null && evidencia != undefined && evidencia.length > 0) {
                    evidencia.forEach(doc => {
                        let row3 = [
                            { image: doc, alignment: 'center', fit: [460, 380] },
                        ]
                        tablaSistema.table.body.push(row3);
                    })
                }
                else {
                    let row3 = [
                        { text: 'N/A', alignment: 'center' },
                    ]
                    tablaSistema.table.body.push(row3);
                }
            }
            return tablaSistema;
        }

        comprobantePago(evidencia) {
            let tablaSistema = {
                table: {
                    widths: [],
                    body: []
                }
            }
            {
                tablaSistema.table.widths = ['100%']
                let rowEnc3 = [
                    { text: 'Comprobante de pago del canon', alignment: 'left', fillColor: '#8db3e2', bold: true },

                ]
                tablaSistema.table.body.push(rowEnc3);

                if (evidencia != null && evidencia != undefined && evidencia.length > 0) {
                    evidencia.forEach(doc => {
                        let row3 = [
                            { image: doc, alignment: 'center', fit: [460, 380] },
                        ]
                        tablaSistema.table.body.push(row3);
                    })
                }
                else {
                    let row3 = [
                        { text: 'N/A', alignment: 'center' },
                    ]
                    tablaSistema.table.body.push(row3);
                }
            }
            return tablaSistema;
        }

        diagnostico(tipoSistema) {
            let tablaSistema = {
                table: {
                    widths: [],
                    body: []
                }
            }
            {
                tablaSistema.table.widths = ['100%']
                let rowEnc3 = [
                    { text: 'Diagnóstico y plan de mantenimiento', alignment: 'left', fillColor: '#8db3e2', bold: true },

                ]
                tablaSistema.table.body.push(rowEnc3);

                if (tipoSistema != null && tipoSistema != undefined && tipoSistema.diagnostico) {
                    let row3 = [
                        { text: tipoSistema.diagnostico, alignment: 'center' },
                    ]
                    tablaSistema.table.body.push(row3);
                }
                else {
                    let row3 = [
                        { text: 'N/A', alignment: 'center' },
                    ]
                    tablaSistema.table.body.push(row3);
                }
            }
            return tablaSistema;
        }

        permisoProveedor(evidencia) {
            let tablaSistema = {
                table: {
                    widths: [],
                    body: []
                }
            }
            {
                tablaSistema.table.widths = ['100%']
                let rowEnc1 = [
                    { text: 'Si realizaron limpiezas de lodos', alignment: 'left', fillColor: '#8db3e2', bold: true },

                ]
                let rowEnc3 = [
                    { text: 'Permiso de funcionamiento del proveedor que realizó limpieza', alignment: 'left', fillColor: '#8db3e2', bold: true },

                ]
                tablaSistema.table.body.push(rowEnc1);
                tablaSistema.table.body.push(rowEnc3);
                if (evidencia != null && evidencia != undefined && evidencia.length > 0) {
                    evidencia.forEach(doc => {
                        let row3 = [
                            { image: doc, alignment: 'center', fit: [460, 380] },
                        ]
                        tablaSistema.table.body.push(row3);
                    })
                }
                else {
                    let row3 = [
                        { text: 'N/A', alignment: 'center' },
                    ]
                    tablaSistema.table.body.push(row3);
                }
            }
            return tablaSistema;
        }

        permisoSanitario(evidencia) {
            let tablaSistema = {
                table: {
                    widths: [],
                    body: []
                }
            }
            {
                tablaSistema.table.widths = ['100%']
                let rowEnc3 = [
                    { text: 'Permiso sanitario de funcionamiento del proveedor que realizó tratamiento o disposición final de los lodos', alignment: 'left', fillColor: '#8db3e2', bold: true },

                ]
                tablaSistema.table.body.push(rowEnc3);

                if (evidencia != null && evidencia != undefined && evidencia.length > 0) {
                    evidencia.forEach(doc => {
                        let row3 = [
                            { image: doc, alignment: 'center', fit: [460, 380] },
                        ]
                        tablaSistema.table.body.push(row3);
                    })
                }
                else {
                    let row3 = [
                        { text: 'N/A', alignment: 'center' },
                    ]
                    tablaSistema.table.body.push(row3);
                }
            }
            return tablaSistema;
        }


        constanciaProveedor(evidencia) {
            let tablaSistema = {
                table: {
                    widths: [],
                    body: []
                }
            }
            {
                tablaSistema.table.widths = ['100%']
                let rowEnc3 = [
                    { text: 'Constancia del proveedor que trató los lodos declarando que el proveedor de limpieza entregó lodos provenientes de la organización participante', alignment: 'left', fillColor: '#8db3e2', bold: true },

                ]
                tablaSistema.table.body.push(rowEnc3);

                if (evidencia != null && evidencia != undefined && evidencia.length > 0) {
                    evidencia.forEach(doc => {
                        let row3 = [
                            { image: doc, alignment: 'center', fit: [460, 380] },
                        ]
                        tablaSistema.table.body.push(row3);
                    })
                }
                else {
                    let row3 = [
                        { text: 'N/A', alignment: 'center' },
                    ]
                    tablaSistema.table.body.push(row3);
                }
            }
            return tablaSistema;
        }


        constanciaDescarga(evidencias) {
            let dataTable = {
                style: 'table',
                table: {
                    widths: ['100%'],
                    body: [
                        [
                            { text: 'Constancia del ente que les brinda ese servicio, que permita comprobar la descarga de la sede participante al sistema de alcantarillado de esa empresa o bien, copia de un recibo del servicio de agua, del año de participación, en donde se indique el cobro de ese servicio.', alignment: 'left', fillColor: '#8db3e2', bold: true },
                        ]
                    ]
                }
            }
            if (evidencias != null && evidencias != undefined && evidencias.length > 0) {
                evidencias.forEach(doc => {
                    let row = [
                        { image: doc, alignment: 'center', fit: [460, 380] },
                    ]
                    dataTable.table.body.push(row);
                })
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'center' },
                ]
                dataTable.table.body.push(rowEmp);
            }
            return dataTable;
        }


        constanciaTragua(evidencias) {
            let dataTable = {
                style: 'table',
                table: {
                    widths: ['100%'],
                    body: [
                        [
                            { text: 'Documento emitido por el ente administrador con el tipo de tratamiento que reciben las aguas residuales', alignment: 'left', fillColor: '#8db3e2', bold: true },
                        ]
                    ]
                }
            }
            if (evidencias != null && evidencias != undefined && evidencias.length > 0) {
                evidencias.forEach(doc => {
                    let row = [
                        { image: doc, alignment: 'center', fit: [460, 380] },
                    ]
                    dataTable.table.body.push(row);
                })
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'center' },
                ]
                dataTable.table.body.push(rowEmp);
            }
            return dataTable;
        }


        tablaAccionesTrat(plantaTratamiento) {
            let sale4 = false;

            let tablaSistema = {
                table: {
                    widths: [],
                    body: []
                }
            }
            {
                tablaSistema.table.widths = ['100%']
                let rowEnc4 = [
                    { text: 'Acciones realizadas a las aguas residuales ordinarias y de tipo especial previo a su vertido a cuerpos de agua u otros STAR que garantice se mejore la calidad de éstas. En caso de no generar AR tipo especial, indicar porque consideran que no las generan.', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 1 },
                ]
                tablaSistema.table.body.push(rowEnc4);

                if (plantaTratamiento != undefined && plantaTratamiento != null) {
                    let row4 = [
                        { text: plantaTratamiento.accioneeRealizadas, alignment: 'justify', colSpan: 1 },
                    ]
                    if (!sale4) {
                        tablaSistema.table.body.push(row4);
                        sale4 = true;
                    }
                }
                if (!sale4) {
                    let row4 = [
                        { text: 'N/A', alignment: 'center', colSpan: 1 },
                    ]
                    tablaSistema.table.body.push(row4);
                }
            }
            return tablaSistema;
        }

        tablaEvidencias(evidencias) {
            if (evidencias != undefined && evidencias.length > 0 && evidencias != null) {
                let evidenciasTable = {
                    table: {
                        widths: ['100%'],
                        body: [],
                        layout: 'noBorders',
                    }
                }
                let row = [];
                evidencias.forEach((evidencia, index) => {
                    let image = {
                        image: evidencia,
                        alignment: 'center',
                        fit: [460, 380],
                    }
                    row.push(image);
                })
                //se dividen en arrays de 2 elementos
                let index = 0;
                let arrayLength = row.length;
                for (index = 0; index < arrayLength; index += 1) {
                    let myRow = row.slice(index, index + 1);

                    if (myRow.length < 1) {
                        myRow.push('')
                    }
                    evidenciasTable.table.body.push(myRow);
                }

                return evidenciasTable;
            }
        }

        tablaAccionesPrevencion(accionesPrevencion) {
            let tablaAccionesReduc = {
                table: {
                    widths: ['25%', '25%', '25%', '25%'],
                    body: [
                        [
                            { text: 'Indicar acción realizada', alignment: 'center', fillColor: '#8db3e2' },
                            { text: 'Descripción de la acción', alignment: 'center', fillColor: '#8db3e2' },
                            { text: 'Cantidad de acciones realizadas', alignment: 'center', fillColor: '#8db3e2' },
                            { text: 'Fecha de ejecución', alignment: 'center', fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            accionesPrevencion.forEach(accion => {
                let row = [
                    { text: accion.tipoActividad, alignment: 'center' },
                    { text: accion.descripcion, alignment: 'center' },
                    { text: accion.cantidadActividades, alignment: 'center' },
                    { text: moment(accion.fechaActividad).format('DD/MM/YYYY'), alignment: 'center' },
                ]
                tablaAccionesReduc.table.body.push(row);
            })

            return tablaAccionesReduc;
        }

        tipoSistemaTratamiento(plantaTratamiento) {
            let sistema;
            let tablaSistema = {
                table: {
                    widths: [],
                    body: []
                }
            }
            if (plantaTratamiento != undefined && plantaTratamiento != null) {
                switch (plantaTratamiento.sistema) {
                    case 1:
                        sistema = 'Planta de Tratamiento';
                        break;
                    case 2:
                        sistema = 'Tanque séptico y drenaje';
                        break;
                    case 3:
                        sistema = 'Alcantarillado sanitario';
                        break;
                }
                if (plantaTratamiento.sistema === 1) {
                    tablaSistema.table.widths = ['35%', '50%', '15%']
                    let rowEnc = [
                        { text: 'Tipo de Sistema', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Descripción del Sistema', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Sistema Propio', alignment: 'center', bold: true, fillColor: '#8db3e2' }
                    ]
                    tablaSistema.table.body.push(rowEnc);
                    let row = [
                        { text: sistema, alignment: 'center' },
                        { text: plantaTratamiento.descripcion, alignment: 'center' },
                        { text: plantaTratamiento.propia ? 'Si' : 'No', alignment: 'center', bold: true }
                    ]
                    tablaSistema.table.body.push(row);
                } else {
                    tablaSistema.table.widths = ['40%', '60%']
                    let rowEnc = [
                        { text: 'Tipo de Sistema', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        { text: 'Descripción del Sistema', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                    ]
                    tablaSistema.table.body.push(rowEnc);
                    let row = [
                        { text: sistema, alignment: 'center' },
                        { text: plantaTratamiento.descripcion, alignment: 'center' },
                    ]
                    tablaSistema.table.body.push(row);
                }
            } else {
                tablaSistema.table.widths = ['35%', '50%', '15%']
                let rowEnc = [
                    { text: 'Tipo de Sistema', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                    { text: 'Descripción del Sistema', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                    { text: 'Sistema Propio', alignment: 'center', bold: true, fillColor: '#8db3e2' }
                ]
                tablaSistema.table.body.push(rowEnc);
                let row = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center', bold: true }
                ]
                tablaSistema.table.body.push(row);
            }
            return tablaSistema;
        }

        tasaGeneracionRes(residuos, valorizable, anio, anioAnterior) {
            let titulo = valorizable ? 'Tipo de residuo valorizable' : 'Tipo de residuo No valorizable';
            let tasaGenRes = {
                table: {
                    widths: ['35%', '15%', '15%', '15%', '20%'],
                    body: [
                        [
                            { text: 'DATOS ESTRICTAMENTE REQUERIDOS', alignment: 'center', bold: true, colSpan: 4, fillColor: '#8db3e2' },
                            '', '', '',
                            { text: 'EQUIVALENCIAS', alignment: 'center', bold: true, rowSpan: 2, fillColor: '#c2d69b' },
                        ],
                        [
                            { text: titulo, alignment: 'center', bold: true, rowSpan: 2, fillColor: '#8db3e2' },
                            { text: 'Generación Total (Kg)', alignment: 'center', bold: true, colSpan: 3, fillColor: '#8db3e2' },
                            '', ''
                        ],
                        [
                            '',
                            { text: 'Anterior ' + anioAnterior, alignment: 'center', bold: true, fillColor: '#fac090' },
                            { text: 'Actual ' + anio, alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Reducción', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Contenedor industrial', alignment: 'center', bold: true, fillColor: '#c2d69b' },
                        ]
                    ]
                }
            }
            if (residuos != undefined && residuos.length > 0) {
                residuos.forEach(res => {
                    let row = [
                        { text: res.nombre, alignment: 'center' },
                        { text: this.numberFormat(res.consumoAnterior), alignment: 'center' },
                        { text: this.numberFormat(res.consumoActual), alignment: 'center' },
                        { text: this.numberFormat(res.ahorroConsumo), alignment: 'center' },
                        { text: this.numberFormat(res.contenedorIndustrial), alignment: 'center' },
                    ]
                    tasaGenRes.table.body.push(row);
                })
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                ]
                tasaGenRes.table.body.push(rowEmp);
            }

            return tasaGenRes;
        }

        residuosEspecialesTraz(resiEspeciales) {
            let resEspeciales = {
                table: {
                    widths: ['25%', '75%'],
                    body: [
                        [
                            { text: 'Nombre del residuo', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Descripción de la trazabilidad del residuo', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            if (resiEspeciales != undefined && resiEspeciales.length > 0) {
                resiEspeciales.forEach(res => {
                    let rowRes = [
                        { text: res.nombreResiduo, alignment: 'center' },
                        { text: res.descripcionTrazabilidad, alignment: 'center' }
                    ]
                    resEspeciales.table.body.push(rowRes);
                })
            } else {
                let row = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' }
                ]
                resEspeciales.table.body.push(row);
            }
            return resEspeciales;
        }

        residuosEspecialesTraz22(resiEspeciales, subtitulo) {
            let count = 1;
            let resEspeciales = {
                table: {
                    widths: ['5%', '25%', '35%', '35%'],
                    body: [
                        [
                            { text: 'N°', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Nombre del residuo', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: subtitulo, alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Disposición final (nombre de la unidad de cumplimiento o proveedor del servicio)', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            if (resiEspeciales != undefined && resiEspeciales.length > 0) {
                resiEspeciales.forEach(res => {
                    let rowRes = [
                        { text: count, alignment: 'center' },
                        { text: res.nombreResiduo, alignment: 'center' },
                        { text: res.descripcionTrazabilidad, alignment: 'justify' },
                        { text: res.disposicionFinal, alignment: 'justify' },
                    ]
                    resEspeciales.table.body.push(rowRes);
                    count += 1;
                })
            } else {
                let row = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },

                ]
                resEspeciales.table.body.push(row);
            }
            return resEspeciales;
        }

        inventarioContam(inventario, anio, anioAnterior) {
            let inventarioCont = {
                table: {
                    body: [
                        [
                            { text: 'DATOS ESTRICTAMENTE REQUERIDOS', alignment: 'center', bold: true, colSpan: 5, fillColor: '#8db3e2' },
                            '', '', '', ''
                        ],
                        [
                            { text: 'Producto/Actividad', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Contaminante atmosférico que contiene', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Presentación', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Consumo Anterior ' + anioAnterior, alignment: 'center', bold: true, fillColor: '#fac090' },
                            { text: 'Consumo Actual ' + anio, alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            if (inventario != undefined && inventario.length > 0) {
                inventario.forEach(inv => {
                    let row = [
                        { text: inv.producto, alignment: 'center' },
                        { text: inv.contaminante, alignment: 'center' },
                        { text: inv.presentacion, alignment: 'center' },
                        { text: this.numberFormat(inv.consumoAnterior), alignment: 'center' },
                        { text: this.numberFormat(inv.consumoActual), alignment: 'center' },
                    ]
                    inventarioCont.table.body.push(row);
                })
            } else {
                let rowCont = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                ]
                inventarioCont.table.body.push(rowCont);
            }
            return inventarioCont;
        }

        inventarioContam22(inventario, anio, anioAnterior) {
            let inventarioCont = {
                table: {
                    widths: ['50%', '50%'],
                    body: [
                        [
                            { text: 'Equipo que consume gas refrigerante', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Gas refrigerante que consume', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            if (inventario != undefined && inventario.length > 0) {
                inventario.forEach(inv => {
                    let row = [
                        { text: inv.producto, alignment: 'center' },
                        { text: inv.tipoContaminanteNombre, alignment: 'center' },
                    ]
                    inventarioCont.table.body.push(row);
                })
            } else {
                let rowCont = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                ]
                inventarioCont.table.body.push(rowCont);
            }
            return inventarioCont;
        }

        mantenimientoEquipos(equipos) {
            let tablaMantenimiento = {
                table: {
                    widths: ['25%', '25%', '25%', '25%'],
                    body: [
                        [
                            { text: 'DATOS ESTRICTAMENTE REQUERIDOS', alignment: 'center', bold: true, colSpan: 4, fillColor: '#8db3e2' },
                            '', '', ''
                        ],
                        [
                            { text: 'Tipo de equipo', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Tipo de mantenimiento', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Periodicidad', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Responsable*', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            if (equipos != undefined && equipos.length > 0) {
                equipos.forEach(e => {
                    let row = [
                        { text: e.tipoContaminanteNombre, alignment: 'center' },
                        { text: e.tipoMantenimiento, alignment: 'center' },
                        { text: e.periodicidad, alignment: 'center' },
                        { text: e.responsable, alignment: 'center' },
                    ]
                    tablaMantenimiento.table.body.push(row);
                })
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                ]
                tablaMantenimiento.table.body.push(rowEmp);
            }
            return tablaMantenimiento;
        }

        inventCompSost(productos) {
            let inventProd = {
                table: {
                    widths: ['30%', '30%', '40%'],
                    body: [
                        [
                            { text: 'DATOS ESTRICTAMENTE REQUERIDOS', alignment: 'center', bold: true, colSpan: 3, fillColor: '#8db3e2' },
                            '', ''
                        ],
                        [
                            { text: 'Producto', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Uso', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Aporte del producto a la sostenibilidad', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            if (productos != undefined && productos.length > 0) {
                productos.forEach(prod => {
                    let row = [
                        { text: prod.nombre, alignment: 'center' },
                        { text: prod.uso, alignment: 'center' },
                        { text: prod.criterio, alignment: 'left' },
                    ]
                    inventProd.table.body.push(row);
                })
            } else {
                let row = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                ]
                inventProd.table.body.push(row);
            }
            return inventProd;
        }

        inventCompSost22(productos) {
            let count = 1;
            let inventProd = {
                table: {
                    widths: ['5%', '30%', '30%', '35%'],
                    body: [
                        [
                            { text: 'N°', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Producto', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Uso', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Beneficio ambiental/social identificado al usar este producto', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            if (productos != undefined && productos.length > 0) {
                productos.forEach(prod => {
                    let row = [
                        { text: count, alignment: 'center' },
                        { text: prod.nombre, alignment: 'center' },
                        { text: prod.uso, alignment: 'center' },
                        { text: prod.criterio, alignment: 'left' },
                    ]
                    inventProd.table.body.push(row);
                    count += 1;
                })
            } else {
                let row = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                ]
                inventProd.table.body.push(row);
            }
            return inventProd;
        }

        sustitucionProds(prodSustitu) {
            let tabProdSust = {
                table: {
                    widths: ['50%', '50%'],
                    body: [
                        [
                            { text: "Producto a ser Sustituido", alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: "Producto Sostenible", alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            if (prodSustitu != undefined && prodSustitu.length > 0) {
                prodSustitu.forEach(prd => {
                    let row = [
                        { text: prd.nombre, alignment: 'center' },
                        { text: prd.productSost, alignment: 'center' }
                    ]
                    tabProdSust.table.body.push(row);
                })
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' }
                ]
                tabProdSust.table.body.push(rowEmp);
            }
            return tabProdSust;
        }

        eventosClimaticostab(eventos) {
            let tablaEvento = {
                table: {
                    widths: ['50%', '50%'],
                    body: [
                        [
                            { text: 'DATOS ESTRICTAMENTE REQUERIDOS', alignment: 'center', bold: true, colSpan: 2, fillColor: '#8db3e2' },
                            ''
                        ],
                        [
                            { text: 'Evento climático', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Impacto ocasionado', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            if (eventos != undefined && eventos.length > 0) {
                eventos.forEach(ev => {
                    let row = [
                        { text: ev.evento, alignment: 'center' },
                        { text: ev.impacto, alignment: 'center' },
                    ]
                    tablaEvento.table.body.push(row);
                })
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                ]
                tablaEvento.table.body.push(rowEmp);
            }
            return tablaEvento;
        }



        eventosClimaticostab22(eventos) {
            let tablaEvento = {
                table: {
                    widths: ['35%', '35%', '30%'],
                    body: [
                        [
                            { text: 'DATOS ESTRICTAMENTE REQUERIDOS', alignment: 'center', bold: true, colSpan: 3, fillColor: '#8db3e2' },
                            '', ''
                        ],
                        [
                            { text: 'Evento hidrometeorológico', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Impacto directo e indirecto', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Indicar si es a la sede participante o parte interesada', alignment: 'center', bold: true, fillColor: '#8db3e2' },

                        ]
                    ]
                }
            }
            if (eventos != undefined && eventos.length > 0) {
                eventos.forEach(ev => {
                    let row = [
                        { text: ev.evento, alignment: 'center' },
                        { text: ev.impacto, alignment: 'center' },
                        { text: ev.ubicacionImpacto, alignment: 'center' },
                    ]
                    tablaEvento.table.body.push(row);
                })
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                ]
                tablaEvento.table.body.push(rowEmp);
            }
            return tablaEvento;
        }

        accionesEventosClim(acciones) {
            let tabAcciEv = {
                table: {
                    body: [
                        [
                            { text: 'DATOS ESTRICTAMENTE REQUERIDOS', alignment: 'center', bold: true, colSpan: 3, fillColor: '#8db3e2' },
                            '', ''
                        ],
                        [
                            { text: 'Evento climático', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Impacto ocasionado', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Acciones realizadas por la organización para la adaptación ante el cambio climático', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            if (acciones != undefined && acciones.length > 0) {
                acciones.forEach(ac => {
                    let row = [
                        { text: ac.evento, alignment: 'center' },
                        { text: ac.impacto, alignment: 'center' },
                        { text: ac.acciones, alignment: 'center' },
                    ]
                    tabAcciEv.table.body.push(row);
                })
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                ]
                tabAcciEv.table.body.push(rowEmp);
            }
            return tabAcciEv;
        }


        accionesEventosClim22(acciones) {
            let tabAcciEv = {
                table: {
                    body: [
                        [
                            { text: 'Evento hidrometeorológico', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Impacto directo e indirecto', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Acciones realizadas por la organización para solucionar los impactos que ha sufrido', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            if (acciones != undefined && acciones.length > 0) {
                acciones.forEach(ac => {
                    let row = [
                        { text: ac.evento, alignment: 'center' },
                        { text: ac.impacto, alignment: 'center' },
                        { text: ac.acciones, alignment: 'center' },
                    ]
                    tabAcciEv.table.body.push(row);
                })
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                ]
                tabAcciEv.table.body.push(rowEmp);
            }
            return tabAcciEv;
        }

        planDeAccionTab(planes) {
            let tabPlaAccion = {
                table: {
                    widths: ['10%', '15%', '10%', '10%', '10%', '10%', '10%', '10%', '15%'],
                    body: [
                        [
                            { text: 'Medida de adaptación', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Descripción', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Objetivo', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Meta', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Indicador', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Responsable', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Plazo de ejecución', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Presupuesto', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Oportunidad para la organización', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            if (planes != undefined && planes.length > 0) {
                planes.forEach(p => {
                    let row = [
                        { text: p.accionEvento, alignment: 'justify' },
                        { text: p.descripcion, alignment: 'justify' },
                        { text: p.objetivo, alignment: 'justify' },
                        { text: p.meta, alignment: 'justify' },
                        { text: p.indicador, alignment: 'justify' },
                        { text: p.responsable, alignment: 'justify' },
                        { text: p.plazo, alignment: 'justify' },
                        { text: p.presupuesto, alignment: 'justify' },
                        { text: p.oportunidad, alignment: 'justify' },
                    ]
                    tabPlaAccion.table.body.push(row);
                })
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'justify' },
                    { text: 'N/A', alignment: 'justify' },
                    { text: 'N/A', alignment: 'justify' },
                    { text: 'N/A', alignment: 'justify' },
                    { text: 'N/A', alignment: 'justify' },
                    { text: 'N/A', alignment: 'justify' },
                    { text: 'N/A', alignment: 'justify' },
                    { text: 'N/A', alignment: 'justify' },
                    { text: 'N/A', alignment: 'justify' },
                ]
                tabPlaAccion.table.body.push(rowEmp);
            }
            return tabPlaAccion
        }

        planDeAccionTab22(planes) {
            let counter = 1;
            let tabPlaAccion = {
                table: {
                    widths: ['5%', '10%', '10%', '15%', '10%', '10%', '10%', '15%', '15%'],
                    body: [
                        [
                            { text: 'N°', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Evento hidrometeorológico', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Medida de adaptación', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Descripción de la acción', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Objetivo', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Meta', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Indicador', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Plazo de ejecución', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Presupuesto', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            if (planes != undefined && planes.length > 0) {
                planes.forEach(p => {
                    let row = [
                        { text: counter, alignment: 'justify', fillColor: '#8db3e2' },
                        { text: p.nombreEvento, alignment: 'justify' },
                        { text: p.accionEvento, alignment: 'justify' },
                        { text: p.descripcion, alignment: 'justify' },
                        { text: p.objetivo, alignment: 'justify' },
                        { text: p.meta, alignment: 'justify' },
                        { text: p.indicador, alignment: 'justify' },
                        { text: p.plazo, alignment: 'justify' },
                        { text: p.presupuesto, alignment: 'justify' },
                    ]
                    tabPlaAccion.table.body.push(row);
                    counter += 1;
                })
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'justify' },
                    { text: 'N/A', alignment: 'justify' },
                    { text: 'N/A', alignment: 'justify' },
                    { text: 'N/A', alignment: 'justify' },
                    { text: 'N/A', alignment: 'justify' },
                    { text: 'N/A', alignment: 'justify' },
                    { text: 'N/A', alignment: 'justify' },
                    { text: 'N/A', alignment: 'justify' },
                    { text: 'N/A', alignment: 'justify' },
                ]
                tabPlaAccion.table.body.push(rowEmp);
            }
            return tabPlaAccion
        }

        activComponsacion(actividades) {
            let tableAciComp = {
                table: {
                    widths: ['20%', '20%', '30%', '30%'],
                    body: [
                        [
                            { text: 'DATOS ESTRICTAMENTE REQUERIDOS', alignment: 'center', bold: true, colSpan: 4, fillColor: '#8db3e2' },
                            '', '', ''
                        ],
                        [
                            { text: 'Actividad', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Fecha de la actividad', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Logros alcanzados', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Beneficiarios', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            if (actividades != undefined && actividades.length > 0) {
                actividades.forEach(ac => {
                    let row = [
                        { text: ac.actividad, alignment: 'center' },
                        { text: moment(ac.fechaActividad).format('DD/MM/YYYY'), alignment: 'center' },
                        { text: ac.logros, alignment: 'center' },
                        { text: ac.beneficiarios, alignment: 'center' },
                    ]
                    tableAciComp.table.body.push(row);
                })
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                ]
                tableAciComp.table.body.push(rowEmp);
            }
            return tableAciComp;
        }

        activComponsacion22(actividades) {
            let tableAciComp = {
                table: {
                    widths: ['10%', '20%', '10%', '20%', '20%', '20%'],
                    body: [
                        [
                            { text: 'Parámetro asociado', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Actividad', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Fecha de la actividad', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Logros alcanzados', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Beneficiarios', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Cantidad de árboles plantados o donados, en caso de que aplique.', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            if (actividades != undefined && actividades.length > 0) {
                actividades.forEach(ac => {
                    let row = [
                        { text: ac.parametroAsociado.nombre, alignment: 'center' },
                        { text: ac.actividad, alignment: 'center' },
                        { text: moment(ac.fechaActividad).format('DD/MM/YYYY'), alignment: 'center' },
                        { text: ac.logros, alignment: 'center' },
                        { text: ac.beneficiarios, alignment: 'center' },
                        { text: ac.cantArboles, alignment: 'center' },
                    ]
                    tableAciComp.table.body.push(row);
                })
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                ]
                tableAciComp.table.body.push(rowEmp);
            }
            return tableAciComp;
        }

        educAmbiExterno(acciones) {
            let tabEducExter = {
                table: {
                    widths: ['40%', '40%', '20%'],
                    body: [
                        [
                            { text: 'DATOS ESTRICTAMENTE REQUERIDOS', alignment: 'center', bold: true, colSpan: 3, fillColor: '#8db3e2' },
                            '', ''
                        ],
                        [
                            { text: 'Actividad de compensación', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Actividad de educación ambiental', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Cantidad de personas beneficiadas', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            if (acciones != undefined && acciones.length > 0) {
                acciones.forEach(ac => {
                    let row = [
                        { text: ac.actividad, alignment: 'center' },
                        { text: ac.actividadEducacion, alignment: 'center' },
                        { text: ac.cant_beneficiados, alignment: 'center' },
                    ]
                    tabEducExter.table.body.push(row);
                })
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                ]
                tabEducExter.table.body.push(rowEmp);
            }
            return tabEducExter;
        }

        tablaEstrellaDos(programas) {
            let tabEstreDos = {
                table: {
                    widths: ['45%', '45%', '10%'],
                    body: [
                        [
                            { text: 'Nombre del Programa de gestión interno o reconocimiento externo o bien iniciativa para promover la salud integral y la felicidad', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Descripción de la implementación del Programa de gestión interno', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Fecha ejecución', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            if (programas != undefined && programas.length > 0) {
                programas.forEach(prg => {
                    let row = [
                        { text: prg.nombre, alignment: 'center' },
                        { text: prg.descripcion, alignment: 'center' },
                        { text: moment(prg.fechaEjecucion).format('DD/MM/YYYY'), alignment: 'center' },
                    ]
                    tabEstreDos.table.body.push(row);
                })
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                ]
                tabEstreDos.table.body.push(rowEmp);
            }
            return tabEstreDos;
        }

        tablaEstrellaTres(promos) {
            let tabEstreTres = {
                table: {
                    widths: ['25%', '20%', '15%', '40%'],
                    body: [
                        [
                            { text: 'Nombre empresa o personas que se les promovió el PBAE', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Relación con la organización', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Fecha de la actividad', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Descripción de la actividad realizada', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            if (promos != undefined && promos.length > 0) {
                promos.forEach(p => {
                    let row = [
                        { text: p.interesado, alignment: 'center', bold: true },
                        { text: p.categoriaCadena, alignment: 'center', bold: true },
                        { text: moment(p.fechaActividad).format('DD/MM/YYYY'), alignment: 'center', bold: true },
                        { text: p.descripcionActividad, alignment: 'center', bold: true }
                    ]
                    tabEstreTres.table.body.push(row);
                })
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'center', bold: true },
                    { text: 'N/A', alignment: 'center', bold: true },
                    { text: 'N/A', alignment: 'center', bold: true },
                    { text: 'N/A', alignment: 'center', bold: true }
                ]
                tabEstreTres.table.body.push(rowEmp);
            }
            return tabEstreTres;
        }

        tablaEstrellaCuatro(promos) {
            let tabEstreTres = {
                table: {
                    widths: ['25%', '20%', '15%', '40%'],
                    body: [
                        [
                            { text: 'Nombre empresa o personas que se les promovió el PBAE', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Relación con la organización', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Fecha de la actividad', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Descripción de la actividad realizada', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            if (promos != undefined && promos.length > 0) {
                promos.forEach(p => {
                    let row = [
                        { text: p.interesado, alignment: 'center', bold: true },
                        { text: p.categoriaComunidad, alignment: 'center', bold: true },
                        { text: moment(p.fechaActividad).format('DD/MM/YYYY'), alignment: 'center', bold: true },
                        { text: p.descripcionActividad, alignment: 'center', bold: true }
                    ]
                    tabEstreTres.table.body.push(row);
                })
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'center', bold: true },
                    { text: 'N/A', alignment: 'center', bold: true },
                    { text: 'N/A', alignment: 'center', bold: true },
                    { text: 'N/A', alignment: 'center', bold: true }
                ]
                tabEstreTres.table.body.push(rowEmp);
            }
            return tabEstreTres;
        }

        tablaEstrellaCinco(emisiones) {
            let tablaEstCinco = {
                table: {
                    widths: ['25%', '25%', '15%', '35%'],
                    body: [
                        [
                            { text: 'Emisiones CO2', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Nombre de la norma aplicada para su calculo', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Fecha de cuantificación', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Otro detalle a indicar', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            if (emisiones != undefined && emisiones.length > 0) {
                emisiones.forEach(em => {
                    let row = [
                        { text: em.emisionCo2, alignment: 'center' },
                        { text: em.normativaReferencia, alignment: 'center' },
                        { text: moment(em.fechaCuantificacion).format('DD/MM/YYYY'), alignment: 'center' },
                        { text: em.otrosDetalles, alignment: 'center' },
                    ]
                    tablaEstCinco.table.body.push(row);
                })
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                ]
                tablaEstCinco.table.body.push(rowEmp);
            }
            return tablaEstCinco;
        }

        tablaEstrellaSeis(certificaciones) {
            let tabEstrellaSeis = {
                table: {
                    widths: ['50%', '50%'],
                    body: [
                        [
                            { text: 'Fecha de la certificación', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Organismo de validación', alignment: 'center', bold: true, fillColor: '#8db3e2' }
                        ]
                    ]
                }
            }
            if (certificaciones != undefined && certificaciones.length > 0) {
                certificaciones.forEach(cert => {
                    let row = [
                        { text: moment(cert.fechaCuantificacion).format('DD/MM/YYYY'), alignment: 'center' },
                        { text: cert.organismoValidacion, alignment: 'center' }
                    ]
                    tabEstrellaSeis.table.body.push(row);
                })
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' }
                ]
                tabEstrellaSeis.table.body.push(rowEmp);
            }
            return tabEstrellaSeis;
        }

        tablaEstrellaSeis22(certificaciones) {
            let tabEstrellaSeis = {
                table: {
                    widths: ['50%', '50%'],
                    body: [
                        [
                            { text: 'Fecha de la certificación', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Nombre de Reconocimiento', alignment: 'center', bold: true, fillColor: '#8db3e2' }
                        ]
                    ]
                }
            }
            if (certificaciones != undefined && certificaciones.length > 0) {
                certificaciones.forEach(cert => {
                    let row = [
                        { text: moment(cert.fechaCuantificacion).format('DD/MM/YYYY'), alignment: 'center' },
                        { text: cert.normativaReferencia, alignment: 'center' }
                    ]
                    tabEstrellaSeis.table.body.push(row);
                })
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' }
                ]
                tabEstrellaSeis.table.body.push(rowEmp);
            }
            return tabEstrellaSeis;
        }


        tablaEstrellaVerde22(hogares) {
            let counter = 1;
            let tableAciComp = {
                table: {
                    widths: ['5%', '10%', '10%', '10%', '10%', '10%', '15%', '15%', '15%'],
                    body: [
                        [
                            { text: '#', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Nombre del Hogar Sostenible inscrito', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Hogar presentó informe final (SI/NO)', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: '¿Cuántos años han acompañado a este hogar?', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Hogar fue galardonado el año anterior (SI/NO)', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Nota obtenida', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Gradación de estrellas obtenidas', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Fecha en que enviaron el formulario de Galardonados (a Categoría Hogares Sostenibles)', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'La información del Hogar se encuentra en el SIBAE (SI/NO)', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            if (hogares != undefined && hogares.length > 0) {
                hogares.forEach(hogar => {
                    let row = [
                        { text: counter, alignment: 'center', fillColor: '#8db3e2'},
                        { text: hogar.nombre, alignment: 'center' },
                        { text: hogar.informeFinal, alignment: 'center' },
                        { text: hogar.aniosAcomp, alignment: 'center' },
                        { text: hogar.galardonAnterior, alignment: 'center' },
                        { text: hogar.calificacionFinal, alignment: 'center' },
                        { text: hogar.estrellasGanadas, alignment: 'center' },
                        { text: hogar.fechaFormulario, alignment: 'center' },
                        { text: hogar.sibae, alignment: 'center' },
                    ]
                    tableAciComp.table.body.push(row);
                    counter += 1;
                })
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                ]
                tableAciComp.table.body.push(rowEmp);
            }
            return tableAciComp;
        }

        hogaresSost(hogares, anio) {
            let tabHogares = {
                table: {
                    widths: ['100%'],
                    body: [
                        [
                            { text: 'Nombre del Hogar Sostenible Inscrito ' + anio, alignment: 'center', fillColor: '#8db3e2' }
                        ]
                    ]
                }
            }
            if (hogares != undefined && hogares.length > 0) {
                hogares.forEach(h => {
                    let row = [
                        { text: h.nombre, alignment: 'left' }
                    ]
                    tabHogares.table.body.push(row);
                })
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'center' }
                ]
                tabHogares.table.body.push(rowEmp);
            }
            return tabHogares;
        }


        tablaEstrellaCinco22(emisiones) {
            let infoHogSost = {
                table: {
                    widths: ['50%', '50%'],
                    body: []
                }
            }
            if (emisiones != undefined && emisiones.length > 0) {
                emisiones.forEach(em => {
                    let row1 = [
                        { text: 'Año de reporte', alignment: 'left', fillColor: '#8db3e2', bold: true },
                        { text: moment(em.fechaCuantificacion).format('DD/MM/YYYY'), alignment: 'center' },
                    ]
                    infoHogSost.table.body.push(row1);
                    let row2 = [
                        { text: 'Nombre de la norma aplicada para la cuantificación', alignment: 'left', fillColor: '#8db3e2', bold: true },
                        { text: em.normativaReferencia, alignment: 'center' }
                    ]
                    infoHogSost.table.body.push(row2);
                    let headRow = [
                        { text: 'Emisiones reportadas (tCO2e) ', alignment: 'center', colSpan: 2, fillColor: '#8db3e2', bold: true },
                        ''
                    ]
                    infoHogSost.table.body.push(headRow);
                    let row3 = [
                        { text: 'Alcance 1', alignment: 'left', fillColor: '#8db3e2', bold: true },
                        { text: em.primerAlcance, alignment: 'left' }
                    ]
                    infoHogSost.table.body.push(row3);
                    let row4 = [
                        { text: 'Alcance 2', alignment: 'left', fillColor: '#8db3e2', bold: true },
                        { text: em.segundoAlcance, alignment: 'left' }
                    ]
                    infoHogSost.table.body.push(row4);
                    let row5 = [
                        { text: 'Alcance 3', alignment: 'left', fillColor: '#8db3e2', bold: true },
                        { text: em.tercerAlcance, alignment: 'left' }
                    ]
                    infoHogSost.table.body.push(row5);
                    let row6 = [
                        { text: 'Total de emisiones reportadas', alignment: 'left', fillColor: '#8db3e2', bold: true },
                        { text: em.emisionCo2, alignment: 'center' }
                    ]
                    infoHogSost.table.body.push(row6);
                })
            } else {
                let bodyEmp = [
                    [
                        { text: 'Año de reporte', alignment: 'left', fillColor: '#8db3e2', bold: true },
                        { text: 'N/A', alignment: 'center' }
                    ],
                    [
                        { text: 'Nombre de la norma aplicada para la cuantificación', alignment: 'left', fillColor: '#8db3e2', bold: true },
                        { text: 'N/A', alignment: 'center' }
                    ],
                    [
                        { text: 'Alcance 1', alignment: 'left', fillColor: '#8db3e2', bold: true },
                        { text: 'N/A', alignment: 'center' }
                    ],
                    [
                        { text: 'Alcance 2', alignment: 'left', fillColor: '#8db3e2', bold: true },
                        { text: 'N/A', alignment: 'center' }
                    ],
                    [
                        { text: 'Alcance 3', alignment: 'left', fillColor: '#8db3e2', bold: true },
                        { text: 'N/A', alignment: 'center' }
                    ],
                    [
                        { text: 'Total de emisiones reportadas', alignment: 'left', fillColor: '#8db3e2', bold: true },
                        { text: 'N/A', alignment: 'center' }
                    ],
                ]
                infoHogSost.table.body = bodyEmp;
            }
            return infoHogSost;
        }


        infoHogaresSost(infoHogares) {
            let infoHogSost = {
                table: {
                    widths: ['50%', '50%'],
                    body: []
                }
            }
            if (infoHogares != undefined && infoHogares.length > 0) {
                infoHogares.forEach((info, i) => {
                    let headRow = [
                        { text: 'Hogar ' + info.nombre, alignment: 'center', colSpan: 2, fillColor: '#8db3e2' },
                        ''
                    ]
                    infoHogSost.table.body.push(headRow);
                    let row1 = [
                        { text: '1. Nombre comité encargado revisión de informes', alignment: 'left' },
                        { text: info.comiteRevision, alignment: 'left' }
                    ]
                    infoHogSost.table.body.push(row1);
                    let row2 = [
                        { text: '2. Nombre de la persona contacto', alignment: 'left' },
                        { text: info.personaContacto, alignment: 'left' }
                    ]
                    infoHogSost.table.body.push(row2);
                    let row3 = [
                        { text: '3. Teléfono', alignment: 'left' },
                        { text: info.telefono, alignment: 'left' }
                    ]
                    infoHogSost.table.body.push(row3);
                    let row4 = [
                        { text: '4. Correo electrónico', alignment: 'left' },
                        { text: info.correo, alignment: 'left' }
                    ]
                    infoHogSost.table.body.push(row4);
                    let row5 = [
                        { text: '5. Total de informes recibidos', alignment: 'left' },
                        { text: info.personaContacto, alignment: 'left' }
                    ]
                    infoHogSost.table.body.push(row5);
                    let row6 = [
                        { text: '6. Total de informes aprobados', alignment: 'left' },
                        { text: info.personaContacto, alignment: 'left' }
                    ]

                    infoHogSost.table.body.push(row6);
                })
            } else {
                let bodyEmp = [
                    [
                        { text: '1. Nombre comité encargado revisión de informes', alignment: 'left' },
                        { text: 'N/A', alignment: 'center' }
                    ],
                    [
                        { text: '2. Nombre de la persona contacto', alignment: 'left' },
                        { text: 'N/A', alignment: 'center' }
                    ],
                    [
                        { text: '3. Teléfono', alignment: 'left' },
                        { text: 'N/A', alignment: 'center' }
                    ],
                    [
                        { text: '4. Correo electrónico', alignment: 'left' },
                        { text: 'N/A', alignment: 'center' }
                    ],
                    [
                        { text: '5. Total de informes recibidos', alignment: 'left' },
                        { text: 'N/A', alignment: 'center' }
                    ],
                    [
                        { text: '6. Total de informes aprobados', alignment: 'left' },
                        { text: 'N/A', alignment: 'center' }
                    ],
                ]
                infoHogSost.table.body = bodyEmp;
            }
            return infoHogSost;
        }

        detaHogarSost(hogares) {
            let dataHogSost = {
                table: {
                    widths: ['50%', '50%'],
                    body: []
                }
            }
            if (hogares != undefined && hogares.length > 0) {
                hogares.forEach(hogar => {
                    let headerRow1 = [
                        { text: 'Nombre de la familia. ' + hogar.nombre, alignment: 'center', colSpan: 2, fillColor: '#8db3e2' },
                        ''
                    ]
                    dataHogSost.table.body.push(headerRow1);
                    let headerRow2 = [
                        { text: 'Cantidad de estrellas ganadas: ' + hogar.estrellasGanadas, alignment: 'center', colSpan: 2, fillColor: '#8db3e2' },
                        ''
                    ]
                    dataHogSost.table.body.push(headerRow2);
                    let headerRow3 = [
                        { text: 'Parámetro', alignment: 'center', fillColor: '#8db3e2' },
                        { text: 'Nota obtenida', alignment: 'center', fillColor: '#8db3e2' }
                    ]
                    dataHogSost.table.body.push(headerRow3);
                    hogar.notaParametros.forEach(nota => {
                        let row = [
                            { text: nota.parametro, alignment: 'center' },
                            { text: nota.notaObtenida, alignment: 'center' }
                        ]
                        dataHogSost.table.body.push(row);
                    })
                    let rowNotaFinal = [
                        { text: 'Nota final:', alignment: 'center', bold: true },
                        { text: hogar.calificacionFinal, alignment: 'center', bold: true }
                    ]
                    dataHogSost.table.body.push(rowNotaFinal);
                })
            } else {
                let headerRowUno = [
                    { text: 'Nombre de la familia. ', alignment: 'center', colSpan: 2, fillColor: '#8db3e2' },
                    ''
                ]
                dataHogSost.table.body.push(headerRowUno);
                let headerRowDos = [
                    { text: 'Cantidad de estrellas ganadas: ', alignment: 'center', colSpan: 2, fillColor: '#8db3e2' },
                    ''
                ]
                dataHogSost.table.body.push(headerRowDos);
                let headerRowTres = [
                    { text: 'Parámetro', alignment: 'center', colSpan: 2, fillColor: '#8db3e2' },
                    { text: 'Nota obtenida', alignment: 'center', colSpan: 2, fillColor: '#8db3e2' }
                ]
                dataHogSost.table.body.push(headerRowTres);
                let rowEmp = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' }
                ]
                dataHogSost.table.body.push(rowEmp);
            }
            return dataHogSost;
        }

        estellaPlateada(residuos) {
            let dataTable = {
                table: {
                    widths: ['35%', '25%', '25%', '15%'],
                    body: [
                        [
                            { text: 'Nombre Centro acopio asociado a Ecolones', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Tipo residuo entregado', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Kilogramos entregados', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Fecha', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            if (residuos != undefined && residuos.length > 0) {
                residuos.forEach(res => {
                    if (res.centroAcopio != 'N/A') {
                        let row = [
                            { text: res.centroAcopio, alignment: 'center' },
                            { text: res.tipoResiduo, alignment: 'center' },
                            { text: res.kilos, alignment: 'center' },
                            { text: moment(res.fecha).format('DD/MM/YYYY'), alignment: 'center' },
                        ]
                        dataTable.table.body.push(row);
                    }
         
                })
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                ]
                dataTable.table.body.push(rowEmp);
            }
            return dataTable;
        }


        tablaEstrellaNaranja22(certificaciones) {
            let counter = 1;
            let tabEstrellaNaranja = {
                table: {
                    widths: ['10%', '50%', '40%'],
                    body: [
                        [
                            { text: '# Actividad', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Nombre de la actividad', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Se incluyó en el formulario de la Categoría Bienestar Animal (SI/NO)', alignment: 'center', bold: true, fillColor: '#8db3e2' }
                        ]
                    ]
                }
            }
            if (certificaciones != undefined && certificaciones.length > 0) {
                certificaciones.forEach(cert => {
                    let row = [
                        { text: counter, alignment: 'center', fillColor: '#8db3e2' },
                        { text: cert.actividad, alignment: 'center' },
                        { text: cert.incluidaString, alignment: 'center' }
                    ]
                    tabEstrellaNaranja.table.body.push(row);
                    counter += 1;
                })
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' }
                ]
                tabEstrellaNaranja.table.body.push(rowEmp);
            }
            return tabEstrellaNaranja;
        }

        evidenciaJustificacion(evidencia) {
            if (evidencia != null && evidencia != undefined && evidencia != "") {
                let evidenciaReduccion = {
                    columns: [
                        { width: '*', text: '' },
                        { width: 'auto', image: evidencia, fit: [460, 380], alignment: 'center' },
                        { width: '*', text: '' }
                    ]
                }
                return evidenciaReduccion;
            }
            return {}
        }

        justificacionReduccion(justificacion) {
            let justificaciuonReduccion = {
                alignment: 'justify',
                margin: [50, 10, 40, 20],
                text: [
                    { text: 'Justificación Reducción: ', bold: true, italics: true },
                    { text: justificacion, italics: true }
                ]
            }
            return justificaciuonReduccion;
        }

        envidenciaSimple(evidencia) {
            let evidenciaSim;
            if (evidencia != null && evidencia != undefined) {
                evidenciaSim = {
                    columns: [
                        { width: '*', text: '' },
                        { width: 'auto', image: evidencia, fit: [460, 380], alignment: 'center' },
                        { width: '*', text: '' }
                    ]
                }
            } else {
                evidenciaSim = {}
            }
            return evidenciaSim;
        }

        estrellasGanadas(estrellas) {
            let estrellasGan;
            switch (estrellas) {
                case 7:
                    estrellasGan = '6 Estrellas blancas, Estrella Verde';
                    break;
                case 8:
                    estrellasGan = '6 Estrellas blancas, Estrella Verde, Estrella Plateada';
                    break;
                default:
                    estrellasGan = estrellas + ' Estrellas blancas';
                    break;
            }
            return estrellasGan;
        }

        comprasSotenibles(programaCompSost) {
            let descrip = (programaCompSost !== undefined && programaCompSost !== null) ? programaCompSost.descripPrograma : '';
            let dataTable = {
                table: {
                    margin: [0, 0, 0, 20],
                    widths: ['100%'],
                    body: [
                        [
                            { text: 'Descripción del Programa', alignment: 'center', fillColor: '#8db3e2' }
                        ],
                        [
                            { text: descrip, alignment: 'justify' }
                        ]
                    ]
                }
            }
            return dataTable;
        }

        comprasSotenibles22(programaCompSost) {
            let descrip = (programaCompSost !== undefined && programaCompSost !== null) ? programaCompSost.descripPrograma : '';
            let dataTable = {
                table: {
                    margin: [0, 0, 0, 20],
                    widths: ['100%'],
                    body: [
                        [
                            { text: 'Describir los criterios sostenibles que utiliza la organización en la compra de bienes y servicios (en caso de no contar con política o programa para compras sostenibles)', alignment: 'center', fillColor: '#8db3e2' }
                        ],
                        [
                            { text: descrip, alignment: 'justify' }
                        ]
                    ]
                }
            }
            return dataTable;
        }

        criteSotenibles22(evidencias) {
            let sale0 = false;
            let sale1 = false;
            let sale2 = false;
            let sale3 = false;
            let sale4 = false;
        
                let educTable = {
                    table: {
                        widths: ['50%', '50%'],
                        body: []
                    }
                }
                    {
                        let rowEnc6 = [
                            { text: 'Portada y firma de la política y/o programa establecido para compras sostenibles', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                        ]
                        educTable.table.body.push(rowEnc6);
                        if (evidencias != undefined && evidencias.length > 0 && evidencias != null) {
                            evidencias.forEach(doc => {
                                let row6 = [
                                    { image: doc, alignment: 'center', fit: [460, 380], colSpan: 2 }, '',
                                ]
                                educTable.table.body.push(row6);
                            })
                        }
                        else {
                            let row6 = [
                                { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                            ]
                            educTable.table.body.push(row6);
                        }
    
                    }
    
                    sale0 = true;
                    sale1 = false;
                    sale2 = false;
                    sale3 = false;
                    sale4 = false;

            return educTable;
        }

        evidComprasSostenibles(programaCompSostEvid) {
            let evid = (programaCompSostEvid !== undefined && programaCompSostEvid !== null) ? programaCompSostEvid : diccionario.imageNotFound;
            let cols = {
                columns: [
                    { width: '*', text: '' },
                    { width: 'auto', image: evid, fit: [350, 500], alignment: 'center' },
                    { width: '*', text: '' }
                ]
            }
            return cols;
        }
        numberFormat(number) {
            return number.toLocaleString('es-CR', { minimumFractionDigits: 2 });
        }
        tipoCombustibles(datos, instalacion, anio, anioAnterior) {
            if (instalacion.tieneCombustibles) {
                return [
                    {
                        text: '2.1. Caso 1 Comités locales que, SI cuentan con flotilla vehicular, maquinaria y/o equipo propio que consumen combustibles fósiles.', alignment: 'left', bold: true, margin: [50, 20, 40, 0],
                    },
                    {
                        text: '2.1.1. Inventario de la flota vehicular, maquinaria y/o equipos que consumen combustible fósil bajo control de la sede participante.', alignment: 'left', bold: true, margin: [50, 20, 40, 0]
                    },
                    {
                        text: 'Cuadro N°1', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
                    },
                    {
                        text: `Inventario de la flota vehicular, maquinaria y/o equipo que consumen combustible Fósil ${anio}`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
                    },
                    this.combustiblesTablaCasoUno(datos.flotaCasoUno),
                    {
                        ul: [
                            { text: '', margin: [70, 10, 60, 0], },
                        ]
                    },
                    this.combustiblesJustificacion(datos.justCombusInve, '#8db3e2', 'Justificación por ausencia de datos '),
                    this.tablaEvidencias(datos.docsJustInventarioComb),
                    {
                        pageBreak: 'before',
                        text: '2.1.2 Determinación del consumo por tipo de combustible fósil.', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
                    },
                    {
                        text: 'Cuadro N°2', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
                    },
                    {
                        text: `Consumo mensual de combustibles fósiles del año anterior al de participación (${anioAnterior})`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
                    },
                    this.tablaConsumo2022(datos.consumoCombAnterior, this.constantes.indicadorCombustibles, anioAnterior, anio, '#fac090'),
                    {
                        ul: [
                            { text: '', margin: [70, 10, 60, 0], },
                        ]
                    },
                    this.combustiblesJustificacion(datos.justCombConsAnte, '#fac090', 'Justificación por ausencia de datos '),
                    {
                        pageBreak: 'before',
                        text: 'Cuadro N°3', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
                    },
                    {
                        text: `Consumo mensual de combustibles fósiles del año de participación (${anio})`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
                    },
            
                    this.tablaConsumo2022(datos.consumoCombutibles, this.constantes.indicadorCombustibles, anio, anio, '#8db3e2'),
                    {
                        ul: [
                            { text: '', margin: [70, 10, 60, 0], },
                        ]
                    },
                    this.combustiblesJustificacion(datos.justCombusCons, '#8db3e2', 'Justificación por ausencia de datos '),
                    this.tablaEvidencias(datos.docsJustConsumoComb),
                    {
                        pageBreak: 'before',
                        text: '2.1.3 Reducción del consumo de combustible fósil mayor a un 1% respecto al año anterior.', alignment: 'left', bold: true, margin: [50, 20, 40, 20],
                    },
                    {
                        text: 'Cuadro N°4', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
                    },
                    {
                        text: `Comparación de consumos de combustible fósil ${anioAnterior} respecto al ${anio}`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
                    },
                    this.comparacionConsumoTable(datos.reducCombust, this.constantes.indicadorCombustibles, anio, anioAnterior, instalacion),
                    {
                        text: 'Siglas, abreviaturas y símbolos a utilizar:', alignment: 'left', bold: false, decoration: 'underline', italics: true, margin: [50, 20, 40, 0]
                    },
                    {
                        text: '-: Resta', alignment: 'left', italics: true, margin: [50, 0, 40, 0]
                    },
                    {
                        text: '*: Multiplicación', alignment: 'left', italics: true, margin: [50, 0, 40, 20]
                    },
                    {
                        ul: [
                            { text: '', margin: [70, 10, 60, 0], },
                        ]
                    },
                    this.justificacionReduccion2022(datos.justCombus, '#8db3e2', 'Justificación en caso de aumento del consumo de combustibles fósiles, ausencia de datos:'),
                    this.tablaEvidencias(datos.docsJustReduccionComb),
                    {
                        pageBreak: 'before',
                        text: '2.1.4 Educación ambiental', alignment: 'left', bold: true, margin: [50, 20, 40, 20],
                    },
                    {
                        text: 'Cuadro N°5', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
                    },
                    {
                        text: `Educación Ambiental ${anio}`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
                    },
                    this.educacionAmbiental2022(datos.educaCombustibles),
                    {
                        pageBreak: 'before',
                        text: '2.1.5 Acciones de reducción y control', alignment: 'left', bold: true, margin: [50, 20, 40, 20],
                    },
                    {
                        text: 'Cuadro N°6', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
                    },
                    {
                        text: `Acción de reducción y control ${anio}`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
                    },
                    this.tablaAccionesReduccion2022(datos.accionReducComb),
                ]
            } else {
                return [
                    {
                        text: '2.2. Caso 2 Comités locales que NO consumen combustibles fósiles', alignment: 'left', bold: true, margin: [50, 20, 40, 0],
                    },
                    {
                        text: '2.2.1  Actividades de Educación Ambiental y/o acciones para reducción y control', alignment: 'left', bold: true, margin: [50, 20, 40, 0],
                    },
                    {
                        text: 'Cuadro N°7', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
                    },
                    this.educacionAmbiental2022(datos.educaCombustibles),
                    {
                        text: '\n',
                    },
                    this.tablaAccionesReduccion2022(datos.accionReducComb),
                ]
            }
            
        }

        sistemaTratamientoAguas(datos) {
            let sistemasTratamieto = (datos.tipoSistemaTrat != null && datos.tipoSistemaTrat != undefined) ? datos.tipoSistemaTrat.sistema : 'N/A';
            let justificacion = (datos.tipoSistemaTrat != null && datos.tipoSistemaTrat != undefined) ? datos.tipoSistemaTrat.justificacion : '';
            let plantaTratamienton = [
                {
                    text: '4.2.1 Planta de Tratamiento de aguas residuales:', alignment: 'left', bold: true, margin: [50, 0, 40, 20]
                },
                this.permisoVertido(datos.permisoVertido),
                this.reporteOperacional(datos.reporteOperacional),
                this.reporteDatos(datos.reporteDatos),
                this.comprobantePago(datos.comprobantePago),
                {
                    text: '', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
                },
                this.justificacionAguasRes022(datos.justResiCons, datos.tipoSistemaTrat, this.constantes.PLANTA_TRATAMIENTO,'#8db3e2', 'Justificación por ausencia de datos y evidencias de la gestión realizada:',datos.docsJustSistemaTratami),
            ]
            let tanqueSeptico = [
                {
                    text: '4.2.2 Tanques sépticos y drenajes', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
                },
                this.diagnostico(datos.tipoSistemaTrat),
                {
                    text: '', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
                },
                this.permisoProveedor(datos.permisoProveedor),
                this.permisoSanitario(datos.permisoSanitario),
                this.constanciaProveedor(datos.constanciaProveedor),
        
                {
                    text: '', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
                },
                this.justificacionAguasRes022(datos.justResiCons, datos.tipoSistemaTrat, this.constantes.TANQUE_SEPTICO,'#8db3e2', 'Justificación por ausencia de datos y evidencias de la gestión realizada:',datos.docsJustSistemaTratami),
            ]
            let alcantarilladoSanitario = [
                {
                    text: '4.2.3 Alcantarillado Sanitario', alignment: 'left', bold: true, margin: [50, 0, 40, 20]
                },
                this.constanciaDescarga(datos.constanciaDescarga),
                this.justificacion(justificacion, 'Justificación en caso de que el documento emitido por el ente administrador no esté a nombre de la sede:'),
            ]
            switch(sistemasTratamieto) {
                case this.constantes.PLANTA_TRATAMIENTO:
                    return plantaTratamienton;
                case this.constantes.TANQUE_SEPTICO:
                    return tanqueSeptico;
                default:
                    return alcantarilladoSanitario;
            }
        }

        educacionAmbiental2023(accionesEducacion) {
            if(accionesEducacion.length > 0) {
                let eduData = [];
                accionesEducacion.forEach((accion, index) => {
                    let numActividad = index + 1;
                    let modalidad = accion.modalidad != null ? accion.modalidad.nombre : 'N/A';
                    let participantes = accion.listaParticipante != null ? accion.listaParticipante : 'N/A';
                    let educTable = {
                        style: 'table',
                        table: {
                            widths: ['50%', '50%'],
                            body: [
                                [
                                    { text: `Actividad N°${numActividad}`, alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                                ],
                                [
                                    { text: 'Nombre de la actividad', alignment: 'left', fillColor: '#8db3e2', bold: true },
                                    { text: 'Fecha de la actividad', alignment: 'left', fillColor: '#8db3e2', bold: true }
                                ],
                                [
                                    { text: accion.tipoActividad, alignment: 'center' },
                                    { text: moment(accion.fechaActividad).format('DD/MM/YYYY'), alignment: 'center' }
                                ],
                                [
                                    { text: 'Descripción y Temas tratados', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                                ],
                                [
                                    { text: 'Descripción: ' + accion.descripcion + '\n Temas tratados: ' + accion.temasTratados, alignment: 'justify', colSpan: 2 }, ''
                                ],
                                [
                                    { text: 'Público participante ', alignment: 'left', fillColor: '#8db3e2', bold: true },
                                    { text: 'Modalidad de la actividad ', alignment: 'left', fillColor: '#8db3e2', bold: true }
                                ],
                                [
                                    { text: participantes, alignment: 'center' },
                                    { text: modalidad, alignment: 'center' }
                                ],
                                [
                                    { text: 'Evidencias mínimas para presentar', alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                                ],
                                [
                                    { text: 'Fotografías de la actividad', alignment: 'left', fillColor: '#8db3e2', colSpan: 2, bold: true }, '',
                                ],
                                [
                                    this.evidenciaCentrada(accion.docActividad), '',
                                ],
                                [
                                    { text: 'Listas de asistencia o Certificaciones de participación', alignment: 'left', fillColor: '#8db3e2', colSpan: 2, bold: true }, '',
                                ],
                                [
                                    this.evidenciaCentrada(accion.docAsistencia), '',
                                ],
                                [
                                    { text: 'Imágenes (específicas del tema tratado asociado a este parámetro) con el contenido de la presentación utilizada en la capacitación o charla', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                                ],
                            ],                    
                        },
                    }
                    
                    accion.docEvidencia.forEach(doc => {
                    let row6 = [
                            { image: doc, alignment: 'center', fit: [460, 380], colSpan: 2 }, '',
                    ]
                    educTable.table.body.push(row6);
                    })
                    eduData.push(educTable);
                })
        
                return eduData;
            } else {
                return this.tablaEducacionAmbVacia()
            }
        }

        tipoCombustibles2023(datos, instalacion, anio, anioAnterior) {
            if (instalacion.tieneCombustibles) {
                return [
                    {
                        text: '2.1. Caso 1 Comités locales que, SI cuentan con flotilla vehicular, maquinaria y/o equipo propio que consumen combustibles fósiles.', alignment: 'left', bold: true, margin: [50, 20, 40, 0],
                    },
                    {
                        text: '2.1.1. Inventario de la flota vehicular, maquinaria y/o equipos que consumen combustible fósil bajo control de la sede participante.', alignment: 'left', bold: true, margin: [50, 20, 40, 0]
                    },
                    {
                        text: 'Cuadro N°1', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
                    },
                    {
                        text: `Inventario de la flota vehicular, maquinaria y/o equipo que consumen combustible Fósil ${anio}`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
                    },
                    this.inventarioCombustibles2023(datos.flotaCasoUno),
                    {
                        ul: [
                            { text: '', margin: [70, 10, 60, 0], },
                        ]
                    },
                    this.combustiblesJustificacion(datos.justCombusInve, '#8db3e2', 'Justificación por ausencia de datos '),
                    this.tablaEvidencias(datos.docsJustInventarioComb),
                    {
                        pageBreak: 'before',
                        text: '2.1.2 Determinación del consumo por tipo de combustible fósil.', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
                    },
                    {
                        text: 'Cuadro N°2', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
                    },
                    {
                        text: `Consumo mensual de combustibles fósiles del año anterior al de participación (${anioAnterior})`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
                    },
                    this.tablaConsumo2022(datos.consumoCombAnterior, this.constantes.indicadorCombustibles, anioAnterior, anio, '#fac090'),
                    {
                        ul: [
                            { text: '', margin: [70, 10, 60, 0], },
                        ]
                    },
                    this.combustiblesJustificacion(datos.justCombConsAnte, '#fac090', 'Justificación por ausencia de datos '),
                    {
                        pageBreak: 'before',
                        text: 'Cuadro N°3', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
                    },
                    {
                        text: `Consumo mensual de combustibles fósiles del año de participación (${anio})`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
                    },
            
                    this.tablaConsumo2022(datos.consumoCombutibles, this.constantes.indicadorCombustibles, anio, anio, '#8db3e2'),
                    {
                        ul: [
                            { text: '', margin: [70, 10, 60, 0], },
                        ]
                    },
                    this.combustiblesJustificacion(datos.justCombusCons, '#8db3e2', 'Justificación por ausencia de datos '),
                    this.tablaEvidencias(datos.docsJustConsumoComb),
                    {
                        pageBreak: 'before',
                        text: '2.1.3 Reducción del consumo de combustible fósil mayor a un 1% respecto al año anterior.', alignment: 'left', bold: true, margin: [50, 20, 40, 20],
                    },
                    {
                        text: 'Cuadro N°4', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
                    },
                    {
                        text: `Comparación de consumos de combustible fósil ${anioAnterior} respecto al ${anio}`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
                    },
                    this.comparacionConsumoTable(datos.reducCombust, this.constantes.indicadorCombustibles, anio, anioAnterior, instalacion),
                    {
                        text: 'Siglas, abreviaturas y símbolos a utilizar:', alignment: 'left', bold: false, decoration: 'underline', italics: true, margin: [50, 20, 40, 0]
                    },
                    {
                        text: '-: Resta', alignment: 'left', italics: true, margin: [50, 0, 40, 0]
                    },
                    {
                        text: '*: Multiplicación', alignment: 'left', italics: true, margin: [50, 0, 40, 20]
                    },
                    {
                        ul: [
                            { text: '', margin: [70, 10, 60, 0], },
                        ]
                    },
                    this.justificacionReduccion2022(datos.justCombus, '#8db3e2', 'Justificación en caso de aumento del consumo de combustibles fósiles, ausencia de datos:'),
                    this.tablaEvidencias(datos.docsJustReduccionComb),
                    {
                        pageBreak: 'before',
                        text: '2.1.4 Educación ambiental', alignment: 'left', bold: true, margin: [50, 20, 40, 20],
                    },
                    {
                        text: 'Cuadro N°5', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
                    },
                    {
                        text: `Educación Ambiental ${anio}`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
                    },
                    this.educacionAmbiental2023(datos.educaCombustibles),
                    {
                        pageBreak: 'before',
                        text: '2.1.5 Acciones de reducción y control', alignment: 'left', bold: true, margin: [50, 20, 40, 20],
                    },
                    {
                        text: 'Cuadro N°6', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
                    },
                    {
                        text: `Acción de reducción y control ${anio}`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
                    },
                    this.tablaAccionesReduccion2022(datos.accionReducComb),
                ]
            } else {
                return [
                    {
                        text: '2.2. Caso 2 Comités locales que NO cuentan con flotilla vehicular, maquinaria y/o equipo que consumen combustibles fósiles', alignment: 'left', bold: true, margin: [50, 20, 40, 0],
                    },
                    {
                        text: '2.2.1  Actividades de Educación Ambiental y/o acciones para reducción y control', alignment: 'left', bold: true, margin: [50, 20, 40, 0],
                    },
                    {
                        text: 'Cuadro N°7', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
                    },
                    this.educacionAmbiental2023(datos.educaCombustibles),
                    {
                        text: '\n',
                    },
                    this.tablaAccionesReduccion2022(datos.accionReducComb),
                ]
            }
            
        }

        inventarioCombustibles2023(flotaCasoUno) {
            let combustibles = {
                table: {
                    widths: ['20%', '20%', '20%', '20%', '20%'],
                    body: [
                        [
                            { text: 'Número de activo o Placa', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Tipo de equipo', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Marca', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Año', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Tipo de combustible fósil', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        ],
                    ]
                }
            }
            if (flotaCasoUno != undefined && flotaCasoUno != null) {
                flotaCasoUno.forEach(vehiculo => {
                    let row = [
                        { text: vehiculo.numPlacaActivo },
                        { text: vehiculo.tipoConsumidor },
                        { text: vehiculo.marca },
                        { text: vehiculo.anio },
                        { text: vehiculo.tipoCombustible },
                    ]
                    combustibles.table.body.push(row);
                })
            } else {
                let row = [
                    { text: 'N/A' },
                    { text: 'N/A' },
                    { text: 'N/A' },
                    { text: 'N/A' },
                    { text: 'N/A' },
                    { text: 'N/A' },
                ]
                combustibles.table.body.push(row);
            }
            return combustibles;
        }

        tablaTipoStar2023(plantaTratamiento) {
            let tablaSistema = {
                table: {
                    widths: [],
                    body: []
                }
            }
            {
                tablaSistema.table.widths = ['100%']
                let rowEnc1 = [
                    { text: 'Nombre del sistema donde se depositan las aguas residuales de la sede participante marque con una X', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 1 },
                ]
                tablaSistema.table.body.push(rowEnc1);
                if (plantaTratamiento != undefined && plantaTratamiento != null) {
                    let row1 = [
                        { 
                            text: [
                                { 
                                    text: [
                                        { text: `Planta de Tratamiento: `, alignment: 'left' },
                                        { text: `${ this.starMarcado(plantaTratamiento, this.constantes.PLANTA_TRATAMIENTO) } \n`, alignment: 'left', decoration: 'underline' },
                                        
                                    ] 
                                },
                                { 
                                    text: [
                                        { text: `Tanque séptico y drenaje: `, alignment: 'left' },
                                        { text: `${ this.starMarcado(plantaTratamiento, this.constantes.TANQUE_SEPTICO) } \n`, alignment: 'left', decoration: 'underline' },
                                    ] 
                                },
                                { 
                                    text: [
                                        { text: `Alcantarillado sanitario: `, alignment: 'left' },
                                        { text: `${ this.starMarcado(plantaTratamiento, this.constantes.ALCANTARILLADO_SANITARIO) } \n`, alignment: 'left', decoration: 'underline' },
                                    ] 
                                },
                            ]
                        }
                    ]

                    tablaSistema.table.body.push(row1);
                } else {
                    let row1 = [
                        { text: 'N/A', alignment: 'center', colSpan: 1 },
                    ]
                    tablaSistema.table.body.push(row1);
                }
            }
            {
                tablaSistema.table.widths = ['100%']
                let rowEnc2 = [
                    { text: 'Describa el sistema donde se depositan las aguas residuales generadas por la sede participante', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 1 },
                ]
                tablaSistema.table.body.push(rowEnc2);

                if (plantaTratamiento != undefined && plantaTratamiento != null) {
                    let row2 = [
                        { text: plantaTratamiento.descripcion, alignment: 'left', colSpan: 1 },
                    ]
                    tablaSistema.table.body.push(row2);

                } else {
                    let row2 = [
                        { text: 'N/A', alignment: 'center', colSpan: 1 },
                    ]
                    tablaSistema.table.body.push(row2);
                }
            }
            return tablaSistema;
        }

        starMarcado(plantaTratamiento, sistema) {
            if (plantaTratamiento.sistema === sistema) return '  X '
            if (plantaTratamiento.sistema === sistema) return '  X '
            if (plantaTratamiento.sistema === sistema) return '  X '
            else return '    '

        }

        evidcroquis2023(evidencia) {
            let tablaSistema = {
                table: {
                    widths: [],
                    body: []
                }
            }
            {
                tablaSistema.table.widths = ['100%']
                let rowEnc3 = [
                    { text: 'Croquis de la ubicación del sistema donde se depositan las aguas residuales de la sede participante', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 1 },
                ]
                tablaSistema.table.body.push(rowEnc3);

                if (evidencia != null && evidencia != undefined) {
                    let row3 = [
                        { image: evidencia, alignment: 'center', fit: [460, 380] },
                    ]
                    tablaSistema.table.body.push(row3);
                } else {
                    let row3 = [
                        { text: 'N/A', alignment: 'center', colSpan: 1 },
                    ]
                    tablaSistema.table.body.push(row3);
                }
            }
            return tablaSistema;
        }

        evidenciaCentrada(evidencia) {
            if(evidencia != null) {
                return { image: evidencia, alignment: 'center', fit: [460, 380], colSpan: 2 }
            } else {
                return { text: 'N/A', alignment: 'center', colSpan: 2 }
            }
        }

        gasesRefCasoSinInv(inventario, educacionAmbientalCaso2, accionesReduccionCaso2) {
            if(!inventario.length || inventario == null || inventario == undefined) {
                return [
                    {
                        text: 'Cuadro N°35', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
                    },
                    {
                        text: 'Educación Ambiental o Acción de Reducción y control (en caso de no tener equipos que consumen gases refrigerantes)', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
                    },
                    this.noShowTable(educacionAmbientalCaso2, 'educacion'),
                    {
                        text: '\n',
                    },
                    this.noShowTable(accionesReduccionCaso2, 'reduccion'),
                ]
            }
        }

        noShowTable(datos, tipo) {
            if(datos.length > 0) {
                if(tipo === 'educacion') {
                    return this.educacionAmbiental2023(datos)
                } else {
                    return this.tablaAccionesReduccion2022(datos)
                }
            } 
            return []
        }

        tablaAccionesReduccionVacia() {
            let accionData = [
                {
                    style: 'table',
                    table: {
                        widths: ['50%', '50%'],
                        body: [
                            [
                                { text: `Acción N°1`, alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                            ],
                            [
                                { text: 'Nombre de la acción realizada', alignment: 'left', fillColor: '#8db3e2', bold: true },
                                { text: 'Fecha de la acción', alignment: 'left', fillColor: '#8db3e2', bold: true },
                            ],
                            [
                                { text: 'N/A', alignment: 'center' },
                                { text: 'N/A', alignment: 'center' },
                            ],
                            [
                                { text: 'Descripción', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                            ],
                            [
                                { text: 'N/A', alignment: 'justify', colSpan: 2 }, ''
                            ],
                            [
                                { text: 'Evidencias', alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                            ],
                            [
                                { text: 'Fotografías de las acciones', alignment: 'left', colSpan: 2, fillColor: '#8db3e2', bold: true }, ''
                            ],
                            [
                                { text: 'N/A', alignment: 'center', fit: [460, 380], colSpan: 2 }, ''
                            ]
                        ]
                    }
                }
            ]
            return accionData;
        }

        tablaEducacionAmbVacia() {
            let eduData = [
                {
                    style: 'table',
                    table: {
                        widths: ['50%', '50%'],
                        body: [
                            [
                                { text: `Actividad N°1`, alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                            ],
                            [
                                { text: 'Nombre de la actividad', alignment: 'left', fillColor: '#8db3e2', bold: true },
                                { text: 'Fecha de la actividad', alignment: 'left', fillColor: '#8db3e2', bold: true }
                            ],
                            [
                                { text: 'N/A', alignment: 'center' },
                                { text: 'N/A', alignment: 'center' }
                            ],
                            [
                                { text: 'Descripción y Temas tratados', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                            ],
                            [
                                { text: 'Descripción: ' + 'N/A' + '\n Temas tratados: ' + 'N/A', alignment: 'justify', colSpan: 2 }, ''
                            ],
                            [
                                { text: 'Público participante ', alignment: 'left', fillColor: '#8db3e2', bold: true },
                                { text: 'Modalidad de la actividad ', alignment: 'left', fillColor: '#8db3e2', bold: true }
                            ],
                            [
                                { text: 'N/A', alignment: 'center' },
                                { text: 'N/A', alignment: 'center' }
                            ],
                            [
                                { text: 'Evidencias mínimas para presentar', alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                            ],
                            [
                                { text: 'Fotografías de la actividad', alignment: 'left', fillColor: '#8db3e2', colSpan: 2, bold: true }, '',
                            ],
                            [
                                this.evidenciaCentrada(null), '',
                            ],
                            [
                                { text: 'Listas de asistencia o Certificaciones de participación', alignment: 'left', fillColor: '#8db3e2', colSpan: 2, bold: true }, '',
                            ],
                            [
                                this.evidenciaCentrada(null), '',
                            ],
                            [
                                { text: 'Imágenes (específicas del tema tratado asociado a este parámetro) con el contenido de la presentación utilizada en la capacitación o charla', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                            ],
                            [
                                { text: 'N/A', alignment: 'center', fit: [460, 380], colSpan: 2 }, '',
                            ]
                        ],                    
                    },
                }
            ]
    
            return eduData;
        }

        tablaAmenazaClimatica(amenazas) {
            let counter = 1;
            let tabEstrellaNaranja = {
                table: {
                    widths: ['20%', '65%', '15%'],
                    body: [
                        [
                            { text: 'Amenaza climática', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Definición', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Nivel de exposición ', alignment: 'center', bold: true, fillColor: '#8db3e2' }
                        ]
                    ]
                }
            }
            if (amenazas != undefined && amenazas.length > 0) {
                amenazas.forEach(am => {
                    let row = [
                        { text: am.amenazaNombre, alignment: 'center' },
                        { text: am.amenazaDefinicion, alignment: 'left' },
                        { text: am.exposicionNombre, alignment: 'center' }
                    ]
                    tabEstrellaNaranja.table.body.push(row);
                    counter += 1;
                })
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' }
                ]
                tabEstrellaNaranja.table.body.push(rowEmp);
            }
            return tabEstrellaNaranja;
        }

        tablaEscalaValoracion(escalas) {
            let tabla = {
                table: {
                    style: 'table',
                    widths: ['30%', '70%'],
                    body: [
                        [
                            { text: 'Nivel de exposición', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Descripción', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            if (escalas != undefined && escalas.length > 0) {
                escalas.forEach(es => {
                    let row = [
                        { text: es.nombre, alignment: 'center' },
                        { text: es.descripcion, alignment: 'left' },
                    ]
                    tabla.table.body.push(row);
                })
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' }
                ]
                tabla.table.body.push(rowEmp);
            }
            return tabla;
        }

        tablaImpactos(impactos) {
            let tabla = {
                table: {
                    style: 'table',
                    widths: ['70%', '30%'],
                    body: [
                        [
                            { text: 'Impacto', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Selección de los impactos ', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            if (impactos != undefined && impactos != null) {
                const map = new Map(Object.entries(impactos));
                map.forEach((items, impacto) => {
                    let row = [
                        { text: `A nivel de ${impacto}`, alignment: 'center', bold: true, fillColor: '#8db3e2', colSpan: 2 }, '',
                    ]
                    tabla.table.body.push(row);
                    items.forEach(im => {
                        let impacto = ((im.seleccionado != null && im.seleccionado) && im.idImpacto == 24) ? im.otro : im.impacto
                        let select = (im.seleccionado != null && im.seleccionado) ? 'X' : ''
                        let row = [
                            { text: impacto, alignment: 'left' },
                            { text: select, alignment: 'center' },
                        ]
                        tabla.table.body.push(row);
                    })
                })
                
            } else {
                let rowEmp = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                ]
                tabla.table.body.push(rowEmp);
            }
            return tabla;
        }

        evideHistEstrellaPlat(evidencias, titulo) {
            
            let tablaEvidenciaE = {
                table: {
                    widths: [],
                    body: []
                }
            }
            tablaEvidenciaE.table.widths = ['50%', '50%']
            let rowEnc6 = [
                { text: titulo, alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
            ]
            tablaEvidenciaE.table.body.push(rowEnc6);
            if (evidencias != undefined && evidencias.length > 0 && evidencias != null) {
                evidencias.forEach((e) => {
                    let row6 = [
                        { image: e, alignment: 'center', fit: [460, 380], colSpan: 2 }, ''
                    ]
                    tablaEvidenciaE.table.body.push(row6);
                })
            } else {
                let row6 = [
                    { text: 'N/A', alignment: 'center', colSpan: 2 }, ''
                ]
                tablaEvidenciaE.table.body.push(row6);
            }
            return tablaEvidenciaE;
        }

        informeLogo(image) {
            let sinImagen = {
                rowSpan: 6,
                text: 'Instalación tipo Cluster sin logo',
                alignment: 'center',
            }
            let logo = {
                rowSpan: 6,
                image: image,
                alignment: 'center',
                fit: [100, 100]
            }
            if(image != null && image != undefined) {
                return  logo
            } else {
                return  sinImagen
            }
        }

        justificacion(justificacion, titulo) {
            let justificacionTable = {
                table: {
                    widths: ['100%'],
                    body: [
                        [
                            { text: titulo, alignment: 'left', bold: true, fillColor: '#8db3e2' },
    
                        ],
                    ]
                }
            }
            if (justificacion != undefined && justificacion != null) {
                let row = [
                    { text: justificacion, alignment: 'justify' }
                ]
                justificacionTable.table.body.push(row);
    
            } else {
                let row = [
                    { text: 'N/A', alignment: 'center' },
                ]
                justificacionTable.table.body.push(row);
            }
            
            return justificacionTable;
        }

        informeGalardon(image) {
            let sinImagen = {
                text: 'N/A',
                alignment: 'center',
            }
            let logo = {
                image: image,
                alignment: 'center',
                fit: [100, 100]
            }
            if(image != null && image != undefined) {
                return  logo
            } else {
                return  sinImagen
            }
        }

        accionesAdaptacion2024(accionesAdaptacion) {
            if(accionesAdaptacion.length > 0) {
                let data = [];
                accionesAdaptacion.forEach((accion, index) => {
                    let numActividad = index + 1;
                    let tabla = {
                        style: 'table',
                        table: {
                            widths: ['50%', '50%'],
                            body: [
                                [
                                    { text: `Actividad N°${numActividad}`, alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                                ],
                                [
                                    { text: `Nombre de la acción de adaptación`, alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                                ],
                                [
                                    { text: accion.nombre, alignment: 'justify', colSpan: 2 }, ''
                                ],
                                [
                                    { text: 'Amenazas climáticas a las que la acción ayuda a reducir y/o prevenir su impacto', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                                ],
                                [
                                    { text: accion.amenazas, alignment: 'justify', colSpan: 2 }, ''
                                ],
                                [
                                    { text: 'Descripción de la acción:', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                                ],
                                [
                                    { text: accion.descripcion, alignment: 'justify', colSpan: 2 }, ''
                                ],
                                [
                                    { text: 'Evidencias de las acciones realizadas para implementar la acción', alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                                ],
                            ],                    
                        },
                    }
                    
                    accion.evidencias.forEach(doc => {
                    let row6 = [
                        { image: doc, alignment: 'center', fit: [460, 380], colSpan: 2 }, '',
                    ]
                    tabla.table.body.push(row6);
                    })
                    data.push(tabla);
                })
        
                return data;
            } else {
                return [
                    {
                        style: 'table',
                        table: {
                            widths: ['50%', '50%'],
                            body: [
                                [
                                    { text: `Actividad N°1`, alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                                ],
                                [
                                    { text: `Nombre de la acción de adaptación`, alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                                ],
                                [
                                    { text: 'N/A', alignment: 'justify', colSpan: 2 }, ''
                                ],
                                [
                                    { text: 'Amenazas climáticas a las que la acción ayuda a reducir y/o prevenir su impacto', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                                ],
                                [
                                    { text: 'N/A', alignment: 'justify', colSpan: 2 }, ''
                                ],
                                [
                                    { text: 'Descripción de la acción:', alignment: 'left', fillColor: '#8db3e2', bold: true, colSpan: 2 }, ''
                                ],
                                [
                                    { text: 'N/A', alignment: 'justify', colSpan: 2 }, ''
                                ],
                                [
                                    { text: 'Evidencias de las acciones realizadas para implementar la acción', alignment: 'left', bold: true, colSpan: 2, fillColor: '#8db3e2' }, ''
                                ],
                                [
                                    { text: 'N/A', alignment: 'justify', colSpan: 2 }, ''
                                ],
                            ],                    
                        },
                    }
                ]
            }
        }

        tablaTipoFuente(tipos, color) {
            let tablaComite = {
                table: {
                    widths: ['33%', '34%', '33%'],
                    body: [
                        [
                            { text: 'Nombre de la fuente', alignment: 'center', bold: true, fillColor: color },
                            { text: 'Tipo de fuente Superficial o subterránea', alignment: 'center', bold: true, fillColor: color },
                            { text: 'Ubicación', alignment: 'center', bold: true, fillColor: color }
                        ],
                    ]
                }
            }
            if(tipos.length) {
                tipos.forEach(fuente => {
                    let tipoFuente = fuente.tipoFuente == 0 ? 'Superficial' : 'Subterránea'
                    let row = [
                        { text: fuente.medidor, alignment: 'center' },
                        { text: tipoFuente, alignment: 'center' },
                        { text: fuente.ubicacion, alignment: 'left' }
                    ]
                    tablaComite.table.body.push(row);
                })
            } else {
                let rowEmpty = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' }
                ]
                tablaComite.table.body.push(rowEmpty);
            }
            return tablaComite;
        }

        tablaCalidadFuente(tipos, color) {
            let tablaComite = {
                table: {
                    widths: ['30%', '70%'],
                    body: [
                        [
                            { text: 'Nombre de la fuente', alignment: 'center', bold: true, fillColor: color },
                            { text: 'Uso', alignment: 'center', bold: true, fillColor: color }
                        ],
                    ]
                }
            }
            if(tipos.length) {
                tipos.forEach(fuente => {
                    let row = [
                        { text: fuente.medidor, alignment: 'center' },
                        { text: fuente.uso, alignment: 'left' }
                    ]
                    tablaComite.table.body.push(row);
                })
            } else {
                let rowEmpty = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' }
                ]
                tablaComite.table.body.push(rowEmpty);
            }
            return tablaComite;
        }

        reduccionFuenteAgua(datos, color) {
            let tablaReduCons = {
                table: {
                    style: 'table',
                    unbreakable: true,
                    widths: ['25%','25%','25%','25%'],
                    body: [
                        [
                            { text: 'Reducción del consumo de agua', alignment: 'center', bold: true, colSpan: 4, fillColor: color },
                            '', '', '',
                        ],
                        [
                            { text: 'Tipo', alignment: 'center', fillColor: color },
                            { text: 'Consumo anterior\n(m3)', alignment: 'center', fillColor: color },
                            { text: 'Consumo actual\n(m3)', alignment: 'center', fillColor: color },
                            { text: 'Ahorro de agua\n(m3)', alignment: 'center', fillColor: color },
                        ]
                    ]
                }
            }
            if (datos != undefined && datos.length) {
                datos.forEach(reduc => {
                    let row = [
                        { text: reduc.nombre, alignment: 'center' },
                        { text: this.numberFormat(reduc.consumoAnterior), alignment: 'center' },
                        { text: this.numberFormat(reduc.consumoActual), alignment: 'center' },
                        { text: this.numberFormat(reduc.ahorroConsumo), alignment: 'center' }
                    ]
                    tablaReduCons.table.body.push(row);
                })
            } else {
                let row = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' }
                ]
                tablaReduCons.table.body.push(row);
            }
            return tablaReduCons;
        }

        buenasPracticas(accionesEducacion, color) {
            if(accionesEducacion.length) {
                let eduData = [];
                accionesEducacion.forEach((accion, index) => {
                    let numActividad = index + 1;
                    let educTable = {
                        style: 'table',
                        table: {
                            widths: ['50%', '50%'],
                            body: [
                                [
                                    { text: `Actividad N°${numActividad}`, alignment: 'left', bold: true, colSpan: 2, fillColor: color }, ''
                                ],
                                [
                                    { text: 'Tipo de Práctica', alignment: 'left', fillColor: color, bold: true },
                                    { text: 'Fecha de la actividad', alignment: 'left', fillColor: color, bold: true }
                                ],
                                [
                                    { text: accion.tipoActividad, alignment: 'center' },
                                    { text: moment(accion.fechaActividad).format('DD/MM/YYYY'), alignment: 'center' }
                                ],
                                [
                                    { text: 'Descripción', alignment: 'left', fillColor: color, bold: true, colSpan: 2 }, ''
                                ],
                                [
                                    { text: `${accion.descripcion} ${accion.temasTratados}`, alignment: 'justify', colSpan: 2 }, ''
                                ],
                                [
                                    { text: 'Fotografías de la Práctica', alignment: 'left', fillColor: color, colSpan: 2, bold: true }, '',
                                ],
                            ],                    
                        },
                    }
                    if(accion.docEvidencia != null && accion.docEvidencia != undefined) {
                        accion.docEvidencia.forEach(doc => {
                            let row6 = [
                                { image: doc, alignment: 'center', fit: [460, 380], colSpan: 2 }, '',
                            ]
                            educTable.table.body.push(row6);
                        })
                    }
                    if(accion.docEvidenciaR != undefined) {
                        accion.docEvidenciaR.forEach(doc => {
                            let row6 = [
                                { image: doc, alignment: 'center', fit: [460, 380], colSpan: 2 }, '',
                            ]
                            educTable.table.body.push(row6);
                        })
                    }
                    eduData.push(educTable);
                })
        
                return eduData;
            } else {
                let emptyTable = {
                    style: 'table',
                    table: {
                        widths: ['50%', '50%'],
                        body: [
                            [
                                { text: `Actividad N°`, alignment: 'left', bold: true, colSpan: 2, fillColor: color }, ''
                            ],
                            [
                                { text: 'Tipo de Práctica', alignment: 'left', fillColor: color, bold: true },
                                { text: 'Fecha de la actividad', alignment: 'left', fillColor: color, bold: true }
                            ],
                            [
                                { text: 'N/A', alignment: 'center' },
                                { text: 'N/A', alignment: 'center' }
                            ],
                            [
                                { text: 'Descripción', alignment: 'left', fillColor: color, bold: true, colSpan: 2 }, ''
                            ],
                            [
                                { text: `N/A`, alignment: 'justify', colSpan: 2 }, ''
                            ],
                            [
                                { text: 'Fotografías de la Práctica', alignment: 'left', fillColor: color, colSpan: 2, bold: true }, '',
                            ],
                            [
                                { text: `N/A`, alignment: 'justify', colSpan: 2 }, ''
                            ],
                        ],                    
                    },
                }
                return emptyTable
            }
        }

        caractUsoSuelo(caracterizacion, color) {
            if(caracterizacion.length) {
                let eduData = [];
                caracterizacion.forEach((accion) => {
                    let educTable = {
                        style: 'table',
                        table: {
                            widths: ['100%'],
                            body: [],                    
                        },
                    }
                    educTable.table.body.push(
                        [
                            { text: 'Croquis', alignment: 'left', fillColor: color, bold: true, colSpan: 1 }
                        ],
                    );
                    educTable.table.body.push(
                        [
                            { text: accion.descripCroquis, alignment: 'justify', colSpan: 1 }
                        ],
                    );
                    educTable.table.body.push(
                        [
                            { text: 'Evidencias Croquis', alignment: 'left', fillColor: color, bold: true, colSpan: 1 }
                        ],
                    );
                    if(accion.evdCroquis.length) {
                        accion.evdCroquis.forEach(doc => {
                            educTable.table.body.push([{ image: doc, alignment: 'center', fit: [460, 380] }]);
                        })
                    } else {
                        educTable.table.body.push({ text: 'N/A', alignment: 'center', colSpan: 1 });
                    }
                    //uso suelo
                    educTable.table.body.push(
                        [
                            { text: 'Descripción Uso Suelo', alignment: 'left', fillColor: color, bold: true, colSpan: 1 }
                        ],
                    );
                    educTable.table.body.push(
                        [
                            { text: accion.descripCroquis, alignment: 'justify', colSpan: 1 }
                        ],
                    );
                    educTable.table.body.push(
                        [
                            { text: 'Evidencias Uso Suelo', alignment: 'left', fillColor: color, bold: true, colSpan: 1 }
                        ],
                    );
                    if(accion.evidUso.length) {
                        accion.evidUso.forEach(doc => {
                            educTable.table.body.push([{ image: doc, alignment: 'center', fit: [460, 380] }]);
                        })
                    } else {
                        educTable.table.body.push({ text: 'N/A', alignment: 'center', colSpan: 1 });
                    }
                    //topografia
                    educTable.table.body.push(
                        [
                            { text: 'Descripción Topografía', alignment: 'left', fillColor: color, bold: true, colSpan: 1 }
                        ],
                    );
                    educTable.table.body.push(
                        [
                            { text: accion.descripTopografia, alignment: 'justify', colSpan: 1 }
                        ],
                    );
                    educTable.table.body.push(
                        [
                            { text: 'Evidencias Topografía', alignment: 'left', fillColor: color, bold: true, colSpan: 1 }
                        ],
                    );
                    if(accion.evidTopog.length) {
                        accion.evidTopog.forEach(doc => {
                            educTable.table.body.push([{ image: doc, alignment: 'center', fit: [460, 380] }]);
                        })
                    } else {
                        educTable.table.body.push({ text: 'N/A', alignment: 'center', colSpan: 1 });
                    }
                    eduData.push(educTable)
                })
                return eduData;
            } else {
                let emptyTable = {
                    style: 'table',
                    table: {
                        widths: ['100%'],
                        body: [
                            [
                                { text: 'Croquis', alignment: 'left', fillColor: color, bold: true, colSpan: 1 }
                            ],
                            [
                                { text: 'N/A', alignment: 'center', bold: true, colSpan: 1 }
                            ],
                            [
                                { text: 'Evidencias Croquis', alignment: 'left', fillColor: color, bold: true, colSpan: 1 }
                            ],
                            [
                                { text: 'N/A', alignment: 'center', bold: true, colSpan: 1 }
                            ],
                            [
                                { text: 'Descripción Uso Suelo', alignment: 'left', fillColor: color, bold: true, colSpan: 1 }
                            ],
                            [
                                { text: 'N/A', alignment: 'center', bold: true, colSpan: 1 }
                            ],
                            [
                                { text: 'Evidencias Uso Suelo', alignment: 'left', fillColor: color, bold: true, colSpan: 1 }
                            ],
                            [
                                { text: 'N/A', alignment: 'center', bold: true, colSpan: 1 }
                            ],
                            [
                                { text: 'Descripción Topografía', alignment: 'left', fillColor: color, bold: true, colSpan: 1 }
                            ],
                            [
                                { text: 'N/A', alignment: 'center', bold: true, colSpan: 1 }
                            ],
                            [
                                { text: 'Evidencias Topografía', alignment: 'left', fillColor: color, bold: true, colSpan: 1 }
                            ],
                            [
                                { text: 'N/A', alignment: 'center', bold: true, colSpan: 1 }
                            ],
                        ],                    
                    },
                }
                return emptyTable
            }
        }

        listDescripcEvidencias(listado, titulo, color) {
            if(listado.length) {
                let listData = [];
                listado.forEach((accion) => {
                    let mainTable = {
                        style: 'table',
                        table: {
                            widths: ['100%'],
                            body: [],                    
                        },
                    }
                    //descripcion
                    mainTable.table.body.push(
                        [
                            { text: titulo, alignment: 'left', fillColor: color, bold: true, colSpan: 1 }
                        ],
                    );
                    mainTable.table.body.push(
                        [
                            { text: accion.descripcion, alignment: 'justify', colSpan: 1 }
                        ],
                    );
                    ///evidencias
                    mainTable.table.body.push(
                        [
                            { text: 'Evidencias', alignment: 'left', fillColor: color, bold: true, colSpan: 1 }
                        ]
                    );
                    if(accion.evidencias.length) {
                        accion.evidencias.forEach(doc => {
                            mainTable.table.body.push([{ image: doc, alignment: 'center', fit: [460, 380] }]);
                        })
                    } else {
                        mainTable.table.body.push({ text: 'N/A', alignment: 'center', colSpan: 1 });
                    }
                    
                    listData.push(mainTable)
                })
                return listData;
            } else {
                let emptyTable = {
                    style: 'table',
                    table: {
                        widths: ['100%'],
                        body: [
                            [
                                { text: titulo, alignment: 'left', fillColor: color, bold: true, colSpan: 1 }
                            ],
                            [
                                { text: 'N/A', alignment: 'center', bold: true, colSpan: 1 }
                            ],
                            [
                                { text: 'Evidencias', alignment: 'left', fillColor: color, bold: true, colSpan: 1 }
                            ],
                            [
                                { text: 'N/A', alignment: 'center', bold: true, colSpan: 1 }
                            ]
                        ],                    
                    },
                }
                return emptyTable
            }
        }

        inventarioInsumosAgrop(datos, color) {
            let tablaInsumos = {
                table: {
                    style: 'table',
                    unbreakable: true,
                    widths: ['20%','20%','20%','30%', '10%'],
                    body: [
                        [
                            { text: 'Nombre del producto', alignment: 'center', fillColor: color },
                            { text: 'Ingrediente activo ', alignment: 'center', fillColor: color },
                            { text: 'Toxicidad', alignment: 'center', fillColor: color },
                            { text: 'Uso', alignment: 'center', fillColor: color },
                            { text: 'Cantidad\nutilizada', alignment: 'center', fillColor: color },
                        ]
                    ]
                }
            }
            if (datos != undefined && datos.length) {
                datos.forEach(insumo => {
                    let row = [
                        { text: insumo.nombre, alignment: 'center' },
                        { text: insumo.ingredienteActivo, alignment: 'center' },
                        { text: insumo.toxicidad, alignment: 'center' },
                        { text: insumo.uso, alignment: 'center' },
                        { text: insumo.cantidad, alignment: 'center' },
                    ]
                    tablaInsumos.table.body.push(row);
                })
            } else {
                let row = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' }
                ]
                tablaInsumos.table.body.push(row);
            }
            return tablaInsumos;
        }

        gestionaAguasRes(datos, color) {
            let tabla = {
                table: {
                    style: 'table',
                    unbreakable: true,
                    widths: ['30%','10%','60%'],
                    body: [
                        [
                            { text: 'Gestión de las aguas residuales', alignment: 'center', fillColor: color, colSpan: 3 },
                            '',''
                        ],
                        [
                            { text: 'Tipo de tratamiento', alignment: 'center', fillColor: color },
                            { text: 'Sí/No aplica', alignment: 'center', fillColor: color },
                            { text: 'Medidas de operación y mantenimiento implementadas', alignment: 'center', fillColor: color }
                        ]
                    ]
                }
            }
            if (datos != undefined && datos.length) {
                datos.forEach(gest => {
                    let row = [
                        { text: gest.tipo, alignment: 'center' },
                        { text: gest.propia ? 'SI' : 'NO', alignment: 'center' },
                        { text: gest.descripcion, alignment: 'left' }
                    ]
                    tabla.table.body.push(row);
                })
            } else {
                let row = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' }
                ]
                tabla.table.body.push(row);
            }
            return tabla;
        }

        disposicionResiduos(disposicion, color) {
            if(disposicion.length > 0) {
                let eduData = [];
                disposicion.forEach((disp, index) => {
                    let educTable = {
                        style: 'table',
                        table: {
                            widths: ['50%', '50%'],
                            body: [
                                [
                                    { text: `Tipo de Residuo`, alignment: 'left', bold: true, colSpan: 2, fillColor: color }, ''
                                ],
                                [
                                    { text: disp.residuo, colSpan: 2, alignment: 'justify' },
                                ],
                                [
                                    { text: 'Fase del proceso del que proviene', alignment: 'left', fillColor: color, colSpan: 2, bold: true }, ''
                                ],
                                [
                                    { text: disp.faseProceso, colSpan: 2, alignment: 'justify' },
                                ],
                                [
                                    { text: 'Disposición Final', alignment: 'left', fillColor: color, bold: true, colSpan: 2 }, ''
                                ],
                                [
                                    { text: disp.disposicion, alignment: 'justify', colSpan: 2 }, ''
                                ],
                                [
                                    { text: 'Fotografías de la Disposición', alignment: 'left', fillColor: color, colSpan: 2, bold: true }, '',
                                ],
                            ],                    
                        },
                    }
                    if(disp.evidDisposicion.length) {
                        disp.evidDisposicion.forEach(doc => {
                            let row6 = [
                                { image: doc, alignment: 'center', fit: [460, 380], colSpan: 2 }, '',
                            ]
                            educTable.table.body.push(row6);
                        })
                    } else {
                        let rowEmpty = [
                            { text: 'N/A', alignment: 'center', colSpan: 2 }
                        ]
                        educTable.table.body.push(rowEmpty);
                    }
                    
                    eduData.push(educTable);
                })
        
                return eduData;
            } else {
                let emptyTable = {
                    style: 'table',
                    table: {
                        widths: ['50%', '50%'],
                        body: [
                            [
                                { text: `Tipo de Residuo`, alignment: 'left', bold: true, colSpan: 2, fillColor: color }, ''
                            ],
                            [
                                { text: 'N/A', alignment: 'center', colSpan: 2 }
                            ],
                            [
                                { text: 'Fase del proceso del que proviene', alignment: 'left', fillColor: color, colSpan: 2, bold: true }, ''
                            ],
                            [
                                { text: 'N/A', alignment: 'center', colSpan: 2 }
                            ],
                            [
                                { text: 'Disposición Final', alignment: 'left', fillColor: color, bold: true, colSpan: 2 }, ''
                            ],
                            [
                                { text: `N/A`, alignment: 'justify', colSpan: 2 }, ''
                            ],
                            [
                                { text: 'Fotografías de la Disposición', alignment: 'left', fillColor: color, colSpan: 2, bold: true }, '',
                            ],
                            [
                                { text: `N/A`, alignment: 'justify', colSpan: 2 }, ''
                            ],
                        ],                    
                    },
                }
                return emptyTable
            }
        }

        planResiduos(datos, color) {
            let tabla = {
                table: {
                    style: 'table',
                    unbreakable: true,
                    widths: ['15%','30%','20%', '20%', "15%"],
                    body: [
                        [
                            { text: 'Tipo de residuo ', alignment: 'center', fillColor: color },
                            { text: 'Acciones a realizar ', alignment: 'center', fillColor: color },
                            { text: 'Meta', alignment: 'center', fillColor: color },
                            { text: 'Periodo de ejecución', alignment: 'center', fillColor: color },
                            { text: 'Responsable', alignment: 'center', fillColor: color },
                        ]
                    ]
                }
            }
            if (datos != undefined && datos.length) {
                datos.forEach(gest => {
                    let row = [
                        { text: gest.opcionalTres, alignment: 'center' },
                        { text: gest.descripcion, alignment: 'left' },
                        { text: gest.temasTratados, alignment: 'left' },
                        { text: gest.opcionalUno, alignment: 'left' },
                        { text: gest.opcionalDos, alignment: 'center' }
                    ]
                    tabla.table.body.push(row);
                })
            } else {
                let row = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' }
                ]
                tabla.table.body.push(row);
            }
            return tabla;
        }

        inventarioCombustiblesAgrop(flotaCasoUno, color) {
            let combustibles = {
                table: {
                    widths: ['20%', '20%', '15%', '15%', '15%', '15%'],
                    body: [
                        [
                            { text: 'Equipo o maquinaria ', alignment: 'center', bold: true, fillColor: color },
                            { text: 'Marca', alignment: 'center', bold: true, fillColor: color },
                            { text: 'Modelo', alignment: 'center', bold: true, fillColor: color },
                            { text: 'Año', alignment: 'center', bold: true, fillColor: color },
                            { text: 'Fecha de adquisición', alignment: 'center', bold: true, fillColor: color },
                            { text: 'Tipo de combustible fósil', alignment: 'center', bold: true, fillColor: color },
                        ],
                    ]
                }
            }
            if (flotaCasoUno != undefined && flotaCasoUno != null) {
                flotaCasoUno.forEach(vehiculo => {
                    let row = [
                        { text: vehiculo.tipoConsumidor, alignment: 'center' },
                        { text: vehiculo.marca, alignment: 'center' },
                        { text: vehiculo.modelo, alignment: 'center' },
                        { text: vehiculo.anio, alignment: 'center' },
                        { text: vehiculo.adquisicion, alignment: 'center' },
                        { text: vehiculo.responsableUso, alignment: 'center' },
                    ]
                    combustibles.table.body.push(row);
                })
            } else {
                let row = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                ]
                combustibles.table.body.push(row);
            }
            return combustibles;
        }

        accionesProyeccionEmp(actividades, color) {
            let activities = {
                table: {
                    widths: ['20%', '20%', '15%', '15%', '30%'],
                    body: [
                        [
                            { text: 'Tema', alignment: 'center', bold: true, fillColor: color },
                            { text: 'Tipo de actividad', alignment: 'center', bold: true, fillColor: color },
                            { text: 'Fecha', alignment: 'center', bold: true, fillColor: color },
                            { text: 'Cantidad de participantes', alignment: 'center', bold: true, fillColor: color },
                            { text: 'Descripción de la actividad', alignment: 'center', bold: true, fillColor: color }
                        ],
                    ]
                }
            }
            if (actividades != undefined && actividades != null) {
                actividades.forEach(actividad => {
                    let row = [
                        { text: actividad.temasTratados, alignment: 'center' },
                        { text: actividad.tipoActividad, alignment: 'center' },
                        { text: moment(actividad.fechaActividad).format('DD/MM/YYYY'), alignment: 'center' },
                        { text: actividad.cantidadPersonas, alignment: 'center' },
                        { text: actividad.descripcion, alignment: 'left' }
                    ]
                    activities.table.body.push(row);
                })
            } else {
                let row = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' }
                ]
                activities.table.body.push(row);
            }
            return activities;
        }

        accionesProyeccionEmpEvid(accionesEducacion, color) {
            if(accionesEducacion.length > 0) {
                let eduData = [];
                accionesEducacion.forEach((accion, index) => {
                    let numActividad = index + 1;
                    let educTable = {
                        style: 'table',
                        table: {
                            widths: ['50%', '50%'],
                            body: [
                                [
                                    { text: `Actividad N°${numActividad}`, alignment: 'left', bold: true, colSpan: 2, fillColor: color }, ''
                                ],
                                [
                                    { text: 'Tipo de Actividad', alignment: 'left', fillColor: color, bold: true, colSpan: 2 }, ''
                                ],
                                [
                                    { text: `${accion.tipoActividad} ${accion.temasTratados}`, alignment: 'justify', colSpan: 2 }, ''
                                ],
                                [
                                    { text: 'Cantidad de participantes', alignment: 'left', fillColor: color, bold: true },
                                    { text: 'Fecha de la actividad', alignment: 'left', fillColor: color, bold: true }
                                ],
                                [
                                    { text: accion.cantidadPersonas, alignment: 'center' },
                                    { text: moment(accion.fechaActividad).format('DD/MM/YYYY'), alignment: 'center' }
                                ],
                                [
                                    { text: 'Tema', alignment: 'left', fillColor: color, bold: true, colSpan: 2 }, ''
                                ],
                                [
                                    { text: `${accion.temasTratados}`, alignment: 'justify', colSpan: 2 }, ''
                                ],
                                [
                                    { text: 'Descripción de la actividad', alignment: 'left', fillColor: color, bold: true, colSpan: 2 }, ''
                                ],
                                [
                                    { text: `${accion.descripcion}`, alignment: 'justify', colSpan: 2 }, ''
                                ],
                                [
                                    { text: 'Fotografías de la Práctica', alignment: 'left', fillColor: color, colSpan: 2, bold: true }, '',
                                ],
                            ],                    
                        },
                    }
                    if(accion.docEvidencia != null && accion.docEvidencia != undefined) {
                        accion.docEvidencia.forEach(doc => {
                            let row6 = [
                                { image: doc, alignment: 'center', fit: [460, 380], colSpan: 2 }, '',
                            ]
                            educTable.table.body.push(row6);
                        })
                    }
                    if(accion.docEvidenciaR != undefined) {
                        accion.docEvidenciaR.forEach(doc => {
                            let row6 = [
                                { image: doc, alignment: 'center', fit: [460, 380], colSpan: 2 }, '',
                            ]
                            educTable.table.body.push(row6);
                        })
                    }
                    eduData.push(educTable);
                })
        
                return eduData;
            } else {
                let emptyTable = {
                    style: 'table',
                    table: {
                        widths: ['50%', '50%'],
                        body: [
                            [
                                { text: `Actividad N°`, alignment: 'left', bold: true, colSpan: 2, fillColor: color }, ''
                            ],
                            [
                                { text: 'Tipo de actividad', alignment: 'left', fillColor: color, bold: true, colSpan: 2 }, ''
                            ],
                            [
                                { text: `N/A`, alignment: 'justify', colSpan: 2 }, ''
                            ],
                            [
                                { text: 'Cantidad de participantes', alignment: 'left', fillColor: color, bold: true },
                                { text: 'Fecha de la actividad', alignment: 'left', fillColor: color, bold: true }
                            ],
                            [
                                { text: 'N/A', alignment: 'center' },
                                { text: 'N/A', alignment: 'center' }
                            ],
                            [
                                { text: 'Tema', alignment: 'left', fillColor: color, bold: true, colSpan: 2 }, ''
                            ],
                            [
                                { text: `N/A`, alignment: 'justify', colSpan: 2 }, ''
                            ],
                            [
                                { text: 'Descripción de la actividad', alignment: 'left', fillColor: color, bold: true, colSpan: 2 }, ''
                            ],
                            [
                                { text: `N/A`, alignment: 'justify', colSpan: 2 }, ''
                            ],
                            [
                                { text: 'Fotografías de la Práctica', alignment: 'left', fillColor: color, colSpan: 2, bold: true }, '',
                            ],
                            [
                                { text: `N/A`, alignment: 'justify', colSpan: 2 }, ''
                            ],
                        ],                    
                    },
                }
                return emptyTable
            }
        }

        adaptacionAgrope(datos, color) {
            let tabla = {
                table: {
                    style: 'table',
                    widths: ['30%', '50%', "20%"],
                    body: [
                        [
                            { text: 'Evento Climatico', alignment: 'center', fillColor: color },
                            { text: 'Impacto Ocacionado', alignment: 'center', fillColor: color },
                            { text: 'Año del Evento', alignment: 'center', fillColor: color }
                        ]
                    ]
                }
            }
            if (datos != undefined && datos.length) {
                datos.forEach(evento => {
                    let row = [
                        { text: evento.descripcion, alignment: 'center' },
                        { text: evento.amenazas, alignment: 'left' },
                        { text: evento.anioEvento, alignment: 'center' }
                    ]
                    tabla.table.body.push(row);
                })
            } else {
                let row = [
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' },
                    { text: 'N/A', alignment: 'center' }
                ]
                tabla.table.body.push(row);
            }
            return tabla;
        }

        practicasAdaptacion(practicas, color) {
            if(practicas.length > 0) {
                let eduData = [];
                practicas.forEach((accion, index) => {
                    let numActividad = index + 1;
                    let educTable = {
                        style: 'table',
                        table: {
                            widths: ['50%', '50%'],
                            body: [
                                [
                                    { text: `Práctica N°${numActividad}`, alignment: 'left', bold: true, colSpan: 2, fillColor: color }, ''
                                ],
                                [
                                    { text: 'Evento Climatico', alignment: 'left', fillColor: color, bold: true, colSpan: 2 }, ''
                                ],
                                [
                                    { text: `${accion.descripcion}`, alignment: 'justify', colSpan: 2 }, ''
                                ],
                                [
                                    { text: 'Impacto Ocacionado', alignment: 'left', fillColor: color, bold: true, colSpan: 2 }, ''
                                ],
                                [
                                    { text: `${accion.amenazas}`, alignment: 'justify', colSpan: 2 }, ''
                                ],
                                [
                                    { text: 'Prácticas para la adaptación de la finca al cambio climático', alignment: 'left', fillColor: color, bold: true, colSpan: 2 }, ''
                                ],
                                [
                                    { text: `${accion.nombre}`, alignment: 'justify', colSpan: 2 }, ''
                                ],
                                [
                                    { text: 'Fotografías de la Práctica', alignment: 'left', fillColor: color, colSpan: 2, bold: true }, '',
                                ],
                            ],                    
                        },
                    }
                    if(accion.evidencias != null && accion.evidencias != undefined) {
                        accion.evidencias.forEach(doc => {
                            let row6 = [
                                { image: doc, alignment: 'center', fit: [460, 380], colSpan: 2 }, '',
                            ]
                            educTable.table.body.push(row6);
                        })
                    }
                    eduData.push(educTable);
                })
        
                return eduData;
            } else {
                let emptyTable = {
                    style: 'table',
                    table: {
                        widths: ['50%', '50%'],
                        body: [
                            [
                                { text: `Práctica N°`, alignment: 'left', bold: true, colSpan: 2, fillColor: color }, ''
                            ],
                            [
                                { text: 'Evento Climatico', alignment: 'left', fillColor: color, bold: true, colSpan: 2 }, ''
                            ],
                            [
                                { text: `N/A`, alignment: 'justify', colSpan: 2 }, ''
                            ],
                            [
                                { text: 'Impacto Ocacionado', alignment: 'left', fillColor: color, bold: true, colSpan: 2 }, ''
                            ],
                            [
                                { text: `N/A`, alignment: 'justify', colSpan: 2 }, ''
                            ],
                            [
                                { text: 'Prácticas para la adaptación de la finca al cambio climático', alignment: 'left', fillColor: color, bold: true, colSpan: 2 }, ''
                            ],
                            [
                                { text: `N/A`, alignment: 'justify', colSpan: 2 }, ''
                            ],
                            [
                                { text: 'Fotografías de la Práctica', alignment: 'left', fillColor: color, colSpan: 2, bold: true }, '',
                            ],
                            [
                                { text: `N/A`, alignment: 'justify', colSpan: 2 }, ''
                            ],
                        ],                    
                    },
                }
                return emptyTable
            }
        }

        tablaCoordinador(coordinador, instalacion) {
            let oobTabla = {
                table: {
                    widths: ['25%', '25%', '25%', '25%'],
                    body: [
                        [
                            { text: 'Nombre de la persona', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Puesto dentro de la organización', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Correo electrónico', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                            { text: 'Teléfono', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                        ]
                    ]
                }
            }
            if(coordinador != null && coordinador != undefined) {
                let row = [
                    { text: coordinador.nombre + ' ' + coordinador.apellido, alignment: 'left'},
                    { text: coordinador.puestoTrabajo, alignment: 'left'},
                    { text: coordinador.correo, alignment: 'center'},
                    { text: instalacion.telefono, alignment: 'center'},
                ]
                oobTabla.table.body.push(row)
            } else {
                let rowEmpty = [
                    { text: 'N/A', alignment: 'left'},
                    { text: 'N/A', alignment: 'left'},
                    { text: 'N/A', alignment: 'center'},
                    { text: 'N/A', alignment: 'center'},
                ]
                oobTabla.table.body.push(rowEmpty)
            }
            return oobTabla
        }
    }