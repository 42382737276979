<template>
      <v-container fluid>
          <v-row>
              <!--Aqui va el agregar y un filtro por anios, si fuera necesario-->
              <v-col cols="12" sm="6" md="1" align-self="center">
                <AgregarEquipo @reload="getEquipos"></AgregarEquipo>
              </v-col>
              <v-col cols="12" sm="6" md="3" align-self="center">
                <Justificacion titulo="Justificación" tooltipText="Justificación" :reqEvidencias="true" :tipoDocumento="constantes.JUSTIF_FLOTA_VEHICULAR"/>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="12" md="3">
                  <v-text-field    
                    prepend-inner-icon="mdi-magnify"                      
                    label="Buscar"
                    v-model="search"
                  ></v-text-field>
              </v-col>
          </v-row>
          <v-row>
              <v-col cols="12">
                  <v-card>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="equipos"
                            calculate-widths
                            item-key="id"
                            :search="search"
                            :loading="loading"
                            no-data-text="No hay equipos para este periodo"
                            :footer-props="{
                                showFirstLastPage: true,
                                firstIcon:'mdi-page-first',
                                lastIcon: 'mdi-page-last',
                                prevIcon: 'mdi-chevron-left',
                                nextIcon: 'mdi-chevron-right',
                                itemsPerPageText: 'Registros por página',
                                itemsPerPageAllText: 'Todos',
                                showCurrentPage: true,
                                showFirstLastPage: true
                            }"
                        >
                        <template v-slot:item.estadoAnio="{ item }">
                            <v-layout justify-center>
                                <ActivarEquipo @reload="getEquipos" :estado="item.estadoAnio" :id="item.id"/>
                            </v-layout>
                        </template>
                         <template v-slot:item.action="{ item }">
                             <ModificarEquipo  @reload="getEquipos" v-bind:id="item.id"></ModificarEquipo>
                             <DetalleEquipo :equipo="item" />
                         </template>
                        </v-data-table>
                      </v-card-text>
                  </v-card>
              </v-col>
          </v-row>
      </v-container>

</template>

<script>
import axios from "@/axios.js"
import Justificacion from '@/components/utils/JustificacionComponent'
import AgregarEquipo from "@/components/parametros/InventarioConsumidores/AgregarEquipoComponent"
import DetalleEquipo from '@/components/parametros/InventarioConsumidores/DetalleEquipoComponent'
import ModificarEquipo from "@/components/parametros/InventarioConsumidores/ModificarEquipoComponent"
import ActivarEquipo from "@/components/parametros/InventarioConsumidores/ActivarInventarioConsumidoresComponent"

export default {
    components:{
        AgregarEquipo,
        ActivarEquipo,
        ModificarEquipo,
        DetalleEquipo,
        Justificacion
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },
    mounted(){
        this.getEquipos();
    },
    computed:{
        instalacion() {
            return this.$store.getters.sucursal;
        },
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        anioConsulta() {
            return this.$store.getters.anioConsulta;
        },
        constantes() {
            return this.$store.getters.constantes;
        },
    },
    data: ()=>({
        items: [],
        equipos:[],
        loading: false,
        search: null,
        activos: 0,
        unidadesCosto: [
            {nombre: 'COLONES', valor: 1}, 
            {nombre: 'DOLARES', valor: 2}
        ],
        unidadesConsumo: [
            {nombre:'LITROS', valor: 2},              
            {nombre:'GALONES', valor: 3},
        ],
        headers:[
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Estado',
                value: 'estadoAnio',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Tipo de Consumidor',
                value: 'tipoConsumidor',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Num Activo',
                value: 'numActivo',
                align: 'center',
                sortable: true,                
            },
            {
                text: 'Marca',
                value: 'marca',
                align: 'center',
                sortable: true,                
            },
            {
                text: 'Tipo de Combustible',
                value: 'tipoCombustible',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Responsable de su Uso',
                value: 'responsableUso',
                align: 'center',
                sortable: true,                
            },
        ]

    }),
    methods:{

        getEquipos(){
            this.loading = true;
            let id = this.instalacion.id
            axios.get(`/inventarioConsumidores/getEquipos/${id}/${this.anioConsulta}`)
            .then(response=>{
                this.equipos = response.data.equipos;
                this.activos = response.data.activos;
                this.loading = false;
                this.loadingModal(false);
            }).catch(error=>{
                console.log(error);
                this.loading = false;
                this.loadingModal(false);
            })
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        }, 
    },
}
</script>