<template>
    <v-container fluid>
        <v-navigation-drawer color="#dcdcdc" v-model="drawer" app clipped width="300">
            <v-list rounded dense>
                <div v-for="item in items" :key="item.key">
                    <v-list-item v-if="item.tipo == 1 && $can(item.action, item.subject)" dense :to="item.path">
                        <v-list-item-action>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{item.title}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-group v-if="item.tipo == 2  && $can(item.action, item.subject)" :prepend-icon="item.icon" no-action>
                        <template v-slot:activator>
                            <v-list-item-content v-bind:class="{badge: item.title === 'Configuración'}">
                                <v-badge v-if="item.title === 'Configuración' && usuarioRol === 'COORDINADOR_AMBIENTAL'"
                                    :content="sinInscripciones"
                                    :value="sinInscripciones"
                                    color="red"
                                    overlap>
                                    <v-list-item-title>{{item.title}}</v-list-item-title>
                                </v-badge>
                                <v-list-item-title v-else>{{item.title}}</v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list-item v-for="(item, i) in item.subItems" :key="i" link :to="item.path">
                            <v-badge v-if="item.title === 'Datos de Inscripción' && usuarioRol === 'COORDINADOR_AMBIENTAL'"
                                :content="sinInscripciones"
                                :value="sinInscripciones"
                                color="red"
                                overlap>
                                <v-list-item-title class="liItem" v-text="item.title"></v-list-item-title>
                            </v-badge>
                            <v-list-item-title v-else v-text="item.title"></v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                </div>
            </v-list>
            <template v-slot:append>
            <div class="pa-2 text-center">
              <v-divider></v-divider>
              <div>Versi&oacute;n {{ appVersion }}</div>
            </div>
          </template>
        </v-navigation-drawer>
        <v-app-bar app color="#0B3F67" class="font-weight-medium white--text" clipped-left>
            <v-app-bar-nav-icon color="white" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-avatar class="mr-3" height="40" width="105" tile>
                <img src="@/assets/iconos/logoszurqui/blancoT25_logo_zurqui.png" contain alt="zurqui"/> 
            </v-avatar>
            <v-toolbar-title class="subtitle-1 white--text">Bandera Azul Ecológica{{ categoriaName }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <span v-if="$mq != 'sm' && $mq != 'xs'" class="white--text">{{ usuarioNombreRol }}</span>
            <perfil v-if="$mq != 'xs'" :userName="usuarioFullName"/>
            <logout />
        </v-app-bar>
        <v-main>
            <router-view></router-view>
        </v-main>
        <noti />
        <LoadingScreen />
        <Confirmation/>
        <Session-ended/>
    </v-container>
</template>

<script>
import Noti from "@/components/NotificacionComponent";
import LoadingScreen from "@/components/utils/LoadingComponent";
import AnioSelect from "@/components/utils/MenuAnioComponent";
import Logout from "@/components/auth/LogoutComponent";
import Perfil from '@/components/usuarios/PerfilComponent'
import axios from "@/axios.js";
import Confirmation from "@/components/utils/ConfirmationComponent";
import SessionEnded from '@/components/utils/SessionEndedComponent'

    export default {
        components: {
            Noti,
            Logout,
            LoadingScreen,
            AnioSelect,
            Confirmation,
            Perfil,
            SessionEnded
        },
        created() {
            axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
        },
        mounted() {
            this.$ability.update(this.sessionRules);
            if (this.organizacion !== null)
                this.listarInscripciones();
            this.getAniosprticipacion();
        },
        methods: {
            listarInscripciones(){
                let idUsuario = this.$store.getters.usuario.id;
                axios.get("/instalaciones/listarInscripciones/"+this.organizacion.id+"/"+idUsuario)
                .then(response => {
                    this.$store.commit('inscripcion', response.data.sinInscripcion);
                    this.$store.commit('fechaInscripcion', response.data.fechaInscripcion);
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                  }
                })
            },
            getAniosprticipacion() {
                let url = null;
                let id = null;
                switch(this.usuarioRol) {
                    case 'ADMIN':          
                        url = "/organizacion/aniosParticipacionOrgAdmin/";
                        id = "";
                        break;
                    case 'CEO':
                    case 'COORDINADOR_AMBIENTAL':
                        url = "/organizacion/anioPorOrganizacion/";
                        id = this.organizacion.id;
                        break;
                    default:          
                        url = "/instalaciones/getAniosParticipacionUsuario/"
                        id=this.$store.getters.usuario.id;
                        break;
                }      
                axios.get(url + id).then(response => {  
                    this.$store.commit('aniosConsulta', response.data.anios);              
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log("Error al obtener los años de participacion: " + error);
                  }
                })
            },
        },
        computed: {
          categoriaName() {
            return this.$store.getters.categoriaName;
          },  
          sinInscripciones() {
            return this.$store.getters.inscripcion.length;
          }, 
          sucursal() {
            return this.$store.getters.sucursal;  
          },
          organizacion() {
            return this.$store.getters.organizacion;
          },
          usuarioFullName() {
            let usuario = this.$store.getters.usuario;
            return usuario.nombre + ' ' + usuario.apellido;
          },
          usuarioRol() {
            return this.$store.getters.usuario.rol;
          },
          usuarioNombreRol() {
            let usuario = this.$store.getters.usuario;
            return usuario.nombreRol;
          },
          sessionRules() {
            let usuario = this.$store.getters.usuario;
            let rules = [];
            switch(usuario.rol) {
                case 'ADMIN':          
                    rules = this.$store.getters.adminRules;
                    break;
                case 'CEO':
                    rules = this.$store.getters.ceoRules;
                    break;
                case 'COORDINADOR_AMBIENTAL':
                    rules = this.$store.getters.coordiRules;
                    break;
                case 'COMITE_AMBIENTAL':
                    rules = this.$store.getters.comiteRules;
                    break;
                default:          
                    rules = this.$store.getters.gerenteRules;
                    break;
            }
            return rules;
          },
          rol() {
            return this.$store.getters.rolName;
          },
          constantes() {
            return this.$store.getters.constantes;
          },
          appVersion() {
            return this.$store.getters.version
          }
        },
        data: () => ({
            drawer: null,
            items: [
                {title: 'Dashboard', icon: 'mdi-desktop-mac-dashboard', path: '/main/dashboard', tipo: 1, action: 'read', subject: 'dashboard'},
                {title: 'Configuración', icon: 'mdi-wrench', path: '', tipo: 2, action: 'manage', subject: 'coordi', subItems: [
                    {title: 'Datos de Instalación', icon: 'mdi-office-building', path: '/main/instalaciones'},
                    {title: 'Datos de Usuarios', icon: 'mdi-account-group', path: '/main/usuarios'},
                    {title: 'Datos de Inscripción', icon: 'mdi-account-group', path: '/main/inscripcionLegal'},
                    {title: 'Indicadores de Desempeño', icon: 'mdi-account-group', path: '/main/indicadoresDesempenio'},
               ]},
                {title: 'Configuración', icon: 'mdi-wrench', path: '', tipo: 2, action: 'manage', subject: 'admin', subItems: [                    
                    {title: 'Organizaciones', icon: 'mdi-city', path: '/main/organizaciones'},
                    {title: 'Instalaciones', icon: 'mdi-office-building', path: '/main/instalaciones'},
                    {title: 'Usuarios', icon: 'mdi-account-group', path: '/main/usuarios'},
                    {title: 'Datos de Inscripción', icon: 'mdi-account-group', path: '/main/inscripcionLegal'},
                    {title: 'Categorias', icon: 'mdi-clipboard-list', path: '/main/categorias'},
                    {title: 'Asignación Puntos', icon: 'mdi-clipboard-list', path: '/main/asignacionPuntos'},
                    /*{title: 'Eficiencia', icon: '', path: '/main/eficiencia'},
                    {title: 'Sector Productivo', icon: 'mdi-home-city-outline', path: '/main/productivo'},
                    {title: 'Tipo Enfoque', icon: 'mdi-hexagon-multiple-outline', path: '/main/TipoEnfoque'},
                    {title: 'Tipo Instalación', icon: 'mdi-domain', path: '/main/tipoInstalacion'},
                    {title: 'Estructura Legal y Socios', icon: 'mdi-city-variant-outline', path: '/main/estructuraLegal'},
                    {title: 'Politica Operativa', icon: 'mdi-alpha-p-circle-outline', path: '/main/PoliticaOperativa'},*/
                  //Opciones corresponden a 2da Fase de Carbono Neutral
                  //{title: 'Etiquetas Ambientales', icon: 'mdi-tag', path: '/main/etiquetas'},
                  //{title: 'Productos', icon: 'mdi-dolly', path: '/main/productos'},                 
                  //{title: 'Categoría Cadena Suministros', icon: 'mdi-teach', path: '/main/Suministros'},
                  //{title: 'Cadenas de Suministros', icon: 'mdi-arrange-bring-to-front', path: '/main/CadenaSuministros'},
                ]}, 
                {title: 'Catálogos', icon: 'mdi-ballot', path: '', tipo: 2, action: 'manage', subject: 'admin', subItems: [
                    {title: 'Eficiencia', icon: '', path: '/main/eficiencia'},
                    {title: 'Sector Productivo', icon: 'mdi-home-city-outline', path: '/main/productivo'},
                    //{title: 'Tipo Enfoque', icon: 'mdi-hexagon-multiple-outline', path: '/main/TipoEnfoque'},
                    {title: 'Tipo Instalación', icon: 'mdi-domain', path: '/main/tipoInstalacion'},
                    {title: 'Estructura Legal y Socios', icon: 'mdi-city-variant-outline', path: '/main/estructuraLegal'},
                    //{title: 'Politica Operativa', icon: 'mdi-alpha-p-circle-outline', path: '/main/PoliticaOperativa'},
                ]},
                {title: 'Comunicaciones', icon: 'mdi-clipboard-text', path: '', tipo: 2, action: 'update', subject: 'coordi', subItems: [
                    {title: 'Informe Anual PBAE', icon: 'mdi-account-group', path: '/main/informeFinal'},
                ]},
                {title: 'Ayuda', icon: 'mdi-help', path: '/main/Ayuda', tipo: 2, action: 'read', subject: 'info', subItems: [
                    {title: 'Bitácora', icon: 'mdi-notebook', path: '/main/bitacora'},
                    {title: 'Contactos y Fechas', icon: '', path: '/main/Ayuda'},
                    {title: 'Legislaciones Actuales', icon: '', path: '/main/Legislaciones'},
                    {title: 'Manual de usuario', icon: '', path: '/main/manualDeUsuario'},
                ]},                
            ]
        }),
  }
</script>
<style scoped>
.sticky {
  position: fixed;
  z-index: 4;
}
.contenido {
  padding: 93px 0px 0px 0px;
}
.badge{
    overflow: visible;
}
.liItem{
    padding-right: 30px;
}
</style>
