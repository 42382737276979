<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal titulo="Inscripción Programa Bandera Azul"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="3" align-self="center">
                <agregarInscripcion 
                    v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"
                    @reload="listarInscripciones" 
                    @agregarInscripcion="agregarInscripcion" 
                    v-bind:instalaciones="instalaciones"/>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="3" align-self="center">
                <v-text-field
                    prepend-inner-icon="mdi-magnify"
                    label="Buscar"
                    v-model="search"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" align-self="center">
                <v-alert type="error" v-if="sinInscripciones > 0">                            
                    {{ sinInscripciones}} {{' ' + sinInscripciones > 1 ?  'Instalaciones no tienen' : 'Instalación no tiene'}} inscripción 
                    al programa bandera azul asociada. Por favor, ingrese las inscripciones entre las fechas: 
                    <strong>{{fechaInicio + ' al ' + fechaFin}}</strong>
                </v-alert>
            </v-col>
        </v-row>
        <v-row class="mt-0" v-if="rolUsuario === 'ADMIN'">
          <v-col cols="12" sm="6" md="3" class="pt-0">
            <v-select
              :items="organizaciones"
              item-value="id"
              item-text="nombre"
              v-model="actOrg"
              label="Seleccione una Organización"
              @change="listarInscripciones"
            ></v-select>
          </v-col>
        </v-row>
        <v-card>
            <v-card-title primary-title class="center--text">
                Inscripción Programa Bandera Azul
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <InscripcionesTabla :buscar="search" @reload="listarInscripciones" v-bind:headers="headers" v-bind:inscripciones="data" />
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>

    import InscripcionesTabla from "@/components/parametros/InscripcionLegal/InscripcionTableComponent";
    import AgregarInscripcion from "@/components/parametros/InscripcionLegal/AgregarInscripcionComponent";
    import TituloPrincipal from '@/components/utils/TituloPrincipalComponent';
    import NavParameters from '@/components/utils/NavParameterComponent';
    import axios from "@/axios.js";

    export default {
        created() {
            axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;        
        },
        components: {
            InscripcionesTabla,
            AgregarInscripcion,
            TituloPrincipal,
            NavParameters
        },
        computed: {
            sinInscripciones() {
                return this.$store.getters.inscripcion.length;
            }, 
            fechaFin() {
                let fecha = this.$store.getters.fechaInscripcion
                return fecha.diaFin + ' de ' + fecha.nombreMesFin + ' del ' + this.anio;
            },
            fechaInicio() {
                let fecha = this.$store.getters.fechaInscripcion;
                return fecha.diaInicio + ' de ' + fecha.nombreMesInicio + ' del ' + this.anio;
            },
            instalacion() {
                return this.$store.getters.sucursal;
            },
            usuario(){
                return this.$store.getters.usuario;
            },
            organizacionId() {
                return this.$store.getters.organizacion != null ? this.$store.getters.organizacion.id : 0;
            },
            rolUsuario(){
                return this.$store.getters.usuario.rol;
            },
            admOrganizacion: {
              get () {
                return this.actOrg
              },
              set (org) {
                this.actOrg = org
              }
            }
        },
        data: () => ({
            items: [],
            search: null,
            anio: parseInt(new Date().getFullYear()),
            data: [],
            instalaciones: [],
            organizaciones: [],
            actOrg: null,
            headers: [
                {
                    text: "Acciones",
                    value: "action",
                    align: "center",
                },
                {
                    text: "Nombre de Instalación",
                    value: "instalacion.nombre",
                    align: "start",
                },
                {
                    text: 'Codigo de Inscripción',
                    value: 'instalacion.codigoPbae',
                    align: 'center',
                },
                {
                    text: "Año de Participación",
                    value: "anio",
                    align: "center",
                },
                {
                    text: "Documentos",
                    value: "documentos",
                    align: "center",
                },
            ],
            org: {
                text: 'Organización',
                value: 'nombreOrganizacion',
                align: 'center',
                sortable: true,
                filterable: true,
            },
        }),
        mounted () {
          if(this.rolUsuario === 'ADMIN') {
            //this.headers.shift();
            this.headers.push(this.org);
            this.listarOrganizaciones();
          } else {
            this.listarInscripciones();
            this.paginaCargada();
          }
        },
        methods: {
            agregarInscripcion(inscripcion) {
                this.data.push(inscripcion);
            },
            listarInscripciones(){
                let orgaId = this.rolUsuario === 'ADMIN' ? this.actOrg : this.organizacionId;
                axios.get("/instalaciones/listarInscripciones/"+orgaId+'/'+this.usuario.id)
                .then(response => {
                    this.data = response.data.inscripciones;
                    this.instalaciones = response.data.instalaciones;
                    console.log(response.data.sinInscripcion)
                    this.$store.commit('inscripcion', response.data.sinInscripcion);
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                  }
                })
            },
            paginaCargada() {
                this.$store.dispatch('loadingPage', false);
            },
            listarOrganizaciones() {
              this.$store.dispatch('loadingPage', true);
              axios.get("/organizacion/listarOrganizaciones").then(
                response => {
                  this.organizaciones = response.data.organizaciones;
                  this.paginaCargada()
                }
              ).catch(
                error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                  }
                  this.paginaCargada()
                  this.$store.dispatch('noti', {mensaje: 'Ocurrió un error.', color:'error'});
                }
              )
            },
        },
    }
</script>